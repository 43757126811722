import ColumnGroupingTable from "components/normalTable";
import { Box, useTheme, Grid, Button, InputBase, Paper} from "@mui/material";
import SupplierForm from "../forms/supplierForm";
import ResponsiveDialog from "components/responsiveDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSupplierList } from "api/hooks/inventory";
import { requester, } from "api/pesabackend";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const columns = [
      { id: 'id', label: 'No.', minWidth: 100 },
      { id: 'name', label: 'Supplier Name', minWidth: 170 },
      { id: 'mobile', label: 'Contact', minWidth: 100 },
      { id: 'address', label: 'Location', minWidth: 100 },
      
    ];
    
   
    
   

export default function Suppliers ({setIsSuccess}) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {supplierList, isLoadingSupplierList, setNewSupplierData, setSearch} = useSupplierList(1, business_branch_id)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({ name: "", mobile: "", address: "",})
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };

  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/suppliers/get/${e.target.value}`);
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/suppliers/get/${e.target.value}`);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/inventory/suppliers/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewSupplierData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };

    return (
      <Box>
        <h4>Supplier List</h4>
          <Box
            p="1rem"
            m ="1rem"
            borderRadius="0.5rem"
            
          >
            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Supplier" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              </Grid>
              <Grid xs >
                
              </Grid>
              <Grid xs  sx={{ textAlign:'right' }}>
                <Button variant="contained" onClick={handleClickOpen}>Add Supplier</Button>
                <ResponsiveDialog
                dialogButtonName = "Add Supplier"
                dialogHeading = "Add a New Supplier"
                open = {open}
                handleClickOpen = {handleClickOpen}
                handleClose = {handleClose}
                negativeResponse = "Cancel"
                positiveResponse = "Save"
                content = {
                            <Box
                              p="1rem"
                              borderRadius="0.5rem"
                              backgroundColor={theme.palette.background.alt}
                            >
                              <SupplierForm 
                                formType = "create" setOpen={setOpen} setNewSupplierData={setNewSupplierData} setIsSuccess={setIsSuccess} editData={editData}
                              />
                            </Box>
                          }
                >
                  
                </ResponsiveDialog>
              </Grid>
            </Grid>
          </Box> 
          <ColumnGroupingTable
                columnData = {columns}
                tableData = {supplierList}
                deleteButton = {true}
                editButton = {true}
                handleClickEditOpen={handleClickEditOpen}
                handleClickEditClose={handleClickEditClose}
                handleClickDeleteOpen={handleClickDeleteOpen}
                handleClickDeleteClose={handleClickDeleteClose}
                openEdit={openEdit}
                openDelete={openDelete}
                editContent={<SupplierForm formType="edit" id={clickedItem} editData={editData} setOpen={setOpenEdit} setNewSupplierData={setNewSupplierData} setIsSuccess={setIsSuccess}/>}
                deleteContent={<Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  onClick={
                                    handleClickDelete
                                  }
                                  sx={{
                                    m: "1rem 0",
                                    p: "1rem",
                                  }}
                                >
                                  Yes, Delete
                                </Button>}
            >
          </ColumnGroupingTable>
        
      </Box>
    );
};