import { Box, useTheme,  Grid, Button, Paper, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdjustmentsList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import moment from "moment";
import { requester } from "api/pesabackend";
import StockAdujstmentViewForm from "../forms/adjustmentForms/stockAdjustmentViewForm";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";    

export default function StockAdjustments () {
  const theme = useTheme();
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {adjustmentList, isLoadingAdjustmentList, errorAdjustmentList, setFilter} = useAdjustmentsList(1, business_branch_id)
  const[searchContent, setSearchContent] = useState("");
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});


  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const handleClickView = async (e)=>{
    const response = await requester.get(`/inventory/stock_adjustment/get/${e.target.value}`);
    if (response.status === 200) {
      // navigate("/purchaseViewForm", { state: response.data.data });
      setViewData(response.data.data);
      setOpenView(true);
    }
  }

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <h4>Stock Adjustment List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>ADJUSTMENT PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangePeriodFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>

              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                    />
                  </Paper>

                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                    />
                  </Paper>
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>

          <Grid xs sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => navigate("/adjustmentForm")}
            >
              ADD Stock Adjustment
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        {/* <ColumnGroupingTable
            columnData = {columns}
            tableData = {adjustmentList}
            viewButton = {true}
            viewButtonName = "View Details"
            handleClickView={handleClickView}
            
        >
        </ColumnGroupingTable> */}
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>Adjustment Number</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Date Carried Out</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50, textAlign: "center" }}>Items Adjsuted</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50, textAlign: "center" }}>Adjustment Value</StyledTableCell>
                  <StyledTableCell >Carried Out By</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 100 }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {adjustmentList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  adjustmentList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>{moment(row.date_added).format("LLL")}</StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>{row.items_adjusted}</StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>{row.total_adjustment_value}</StyledTableCell>
                          <StyledTableCell>{row.added_by.first_name} {row.added_by.last_name}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View Details
                            </Button>
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Order"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <StockAdujstmentViewForm viewData={viewData} />
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={adjustmentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};