import { Box, Alert, useTheme, useMediaQuery, Paper } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import FixedSizeDialog from "components/fixedSizeDialog";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useGivenPeriodShiftsList } from "api/hooks/humanResource";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

const ShiftCalendar = ({ setIsSuccess, setIsFailed }) => {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [open, setOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [eventAssignemnts, setEventAssignemnts] =useState([]);
  const [eventTitle, setEventTitle] = useState([]);
  const [eventStart, setEventStart] = useState([]);
  const [eventEnd, setEventEnd] = useState([]);


  const {givenPeriodShiftList, isLoadingGivenPeriodShiftList, error, setNewGivenPeriodShiftData, setFilter} = useGivenPeriodShiftsList(business_branch_id);

  const calendarRef = useRef();

  function goNext() {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
  }

  function goPrev() {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const event = [];

  givenPeriodShiftList.forEach((element) => {
    element.shifts.forEach(shift =>{
      event.push({
        title: shift.detail.shift_name,
        start: `${element.day} ${shift.detail.start_time}`,
        end: `${element.day} ${shift.detail.end_time}`,
        extendedProps: {
          assignmnets: shift.assignments,
        }
      });
    })
      
  });

  return (
    <Box>
      <Paper sx={{ margin: "5px", padding: "15px" }}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridDay,dayGridWeek,dayGridMonth",
          }}
          height={"80vh"}
          events={event}
          eventClick={(info) => {
            setEventAssignemnts(info.event.extendedProps.assignmnets);
            setEventTitle(info.event.title);
            setEventStart(info.event.start);
            setEventEnd(info.event.end);
            console.log(info.event.end);
            setOpen(true); 
          }}
          datesSet={(dateInfo) => {
            setFilter({ start: moment(dateInfo.start).format("YYYY-MM-DD"), end: moment(dateInfo.end).format("YYYY-MM-DD") });
          }}
          customButtons={{
            prev: {
              text: "Prev",
              click: function () {
                goPrev();
              },
            },
            next: {
              text: "Next",
              click: function () {
                goNext();
              },
            },
          }}
        />
      </Paper>

      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        <FixedSizeDialog
          dialogButtonName="Shift Assignment"
          dialogHeading={`${eventTitle} - SHIFT ASSIGNMENT`}
          open={open}
          maxWidth="sm"
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          negativeResponse="Close"
          content={
            <Box
              // p="1rem"
              // borderRadius="0.5rem"
              backgroundColor={theme.palette.background.alt}
            >
              <p>{moment(eventStart).format("LL")}  {moment(eventStart).format("LT")} - {eventEnd ? moment(eventEnd).format("LT") : 'Next Day' }</p>
              <h4>Employees Assigned</h4>
              {eventAssignemnts.length === 0 ? (
                <p>No shifts assigned on this date</p>
              ) : (
                eventAssignemnts.map((row, index) => {
                  return (
                    <li style={{ padding: "0px 30px" }} key={index}>{row.related_employee.first_name} {row.related_employee.last_name} - {row.related_employee.phone_number}</li>
                  );
                })
              )}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default ShiftCalendar;
