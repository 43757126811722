import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete,Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {useState} from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const SupplierReturnDetailForm = (props) => {
  const {
    index,
    data,
    handleReturnQuantityChange,
    handleReturnAmountChange,
    name,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [error, setError] = useState(false);
  const [productList, setProductList] = useState(data.product_info ? [data.product_info] : []);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [unitsForGivenItemList, setUnitsForGivenItemList] = useState(
    data.product_units_list ? data.product_units_list : []
  );

  const fetchUnitsForGivenItemList = async (productId) => {
    try {
      const response = await requester.get(
        `/inventory/products/unit_list/${productId}`
      );
      setUnitsForGivenItemList(response.data.data);
    } catch (e) {
      setError(true);
    }
  };

  const fetchItemList = async (search) => {
    try {
      if (search) {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
        );
        setProductList(response.data.data);
      } else {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
        );
        setProductList(response.data.data);
      }
    } catch (e) {
      setError(true);
    }
  };

  const [totalCalculation, setTotalCalculation] = useState(0)

  const handleTotal = (e) =>{
    setTotalCalculation((Number(data.smallest_unit_unit_cost)* Number(e.target.value)).toFixed(2));
    handleReturnAmountChange((Number(data.smallest_unit_unit_cost)* Number(e.target.value)).toFixed(2), index);
  }

  const [makeReturn, setMakeReturn] = useState(true);

  const handleMakeReturn = () => {
    setMakeReturn(!makeReturn);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <p>#{index + 1}</p>
      </Box>
      <Box
        display="grid"
        gap="5px"
        p="0.5rem"
        gridTemplateColumns="repeat(20, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        <>
          <p
            hidden
            name={`${name}.related_purchase_detail`}
            value={data.id}
          ></p>

          <Autocomplete
            size="small"
            disabled
            onOpen={() => {
              fetchItemList(null);
            }}
            name={`${name}.related_product`}
            onChange={(e, value) => {
              if (value) {
                fetchUnitsForGivenItemList(value.id);
              }
              setFieldValue(`${name}.related_product`, value ? value.id : "");
            }}
            sx={{ gridColumn: "span 4" }}
            options={productList}
            defaultValue={productList[0]}
            getOptionLabel={(option) =>
              `${option.product_name}  ${option.description}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, productList) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={productList.id}
              >
                {productList.product_name} {productList.description}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                label="Item Name"
                value={data.related_product ? data.related_product ?? null : ""}
                error={
                  Boolean(
                    touched?.return_detail &&
                      touched?.return_detail[index]?.related_product
                  ) &&
                  Boolean(
                    errors?.return_detail &&
                      errors?.return_detail[index]?.related_product
                  )
                }
                helperText={
                  touched?.return_detail &&
                  touched?.return_detail[index]?.related_product &&
                  errors?.return_detail &&
                  errors?.return_detail[index]?.related_product
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  fetchItemList(e.currentTarget.value);
                }}
              />
            )}
          />

          <TextField
            label="Batch"
            size="small"
            disabled
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.batch}
            name={`${name}.batch`}
            error={
              Boolean(
                touched?.return_detail && touched?.return_detail[index]?.batch
              ) &&
              Boolean(
                errors?.return_detail && errors?.return_detail[index]?.batch
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.batch &&
              errors?.return_detail &&
              errors?.return_detail[index]?.batch
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Expiry Date"
            disabled
            size="small"
            type="date"
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.expiry_date}
            name={`${name}.expiry_date`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.expiry_date
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.expiry_date
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.expiry_date &&
              errors?.return_detail &&
              errors?.return_detail[index]?.expiry_date
            }
            sx={{ gridColumn: "span 3" }}
          />

          <TextField
            label="Remaining Quantity"
            size="small"
            disabled
            type="number"
            inputProps={{
              min: 0,
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.remaining_quantity}
            name={`${name}.current_quantity_at_return`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.current_quantity_at_return
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.current_quantity_at_return
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.current_quantity_at_return &&
              errors?.return_detail &&
              errors?.return_detail[index]?.current_quantity_at_return
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Item Unit"
            size="small"
            disabled
            onBlur={handleBlur}
            onChange={handleChange}
            select
            value={data.related_smallest_unit}
            name={`${name}.related_smallest_unit`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.related_smallest_unit
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.related_smallest_unit
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.related_smallest_unit &&
              errors?.return_detail &&
              errors?.return_detail[index]?.related_smallest_unit
            }
            sx={{ gridColumn: "span 2" }}
          >
            {unitsForGivenItemList.map((oneUnit, index) => {
              return (
                <MenuItem
                  key={index}
                  value={oneUnit.id}
                  selected={
                    oneUnit.id === data.related_smallest_unit ? true : false
                  }
                >
                  {oneUnit.related_business_product_unit.name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            label="Unit Cost"
            size="small"
            disabled
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.smallest_unit_unit_cost}
            inputProps={{
              min: 0,
            }}
            name={`${name}.smallest_unit_unit_cost`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.smallest_unit_unit_cost
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.smallest_unit_unit_cost
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.smallest_unit_unit_cost &&
              errors?.return_detail &&
              errors?.return_detail[index]?.smallest_unit_unit_cost
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Return Quantity"
            size="small"
            disabled={makeReturn}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              handleTotal(e);
            }}
            value={data.quantity_returned}
            type="number"
            defaultValue={0}
            inputProps={{
              min: 0,
              max: data.remaining_quantity,
            }}
            name={`${name}.quantity_returned`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.quantity_returned
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.quantity_returned
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.quantity_returned &&
              errors?.return_detail &&
              errors?.return_detail[index]?.quantity_returned
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Return Amount"
            size="small"
            onBlur={handleBlur}
            disabled={makeReturn}
            onChange={handleChange}
            value={(data.return_amount = totalCalculation)}
            type="number"
            name={`${name}.return_amount`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.return_amount
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.return_amount
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.return_amount &&
              errors?.return_detail &&
              errors?.return_detail[index]?.return_amount
            }
            sx={{ gridColumn: "span 2" }}
          />

          <Box sx={{ gridColumn: "span 1", p: "0px"}}>
            <label>Return</label>
            <Checkbox
              value={data.is_return=!makeReturn}
              name={`${name}.is_return`}
              onChange={(e) => {
                handleChange(e);
                handleMakeReturn();
              }}
              sx={{ margin: "0px", p:"0px 8px" }}
              color="success"
            />
          </Box>

        </>
      </Box>
    </Box>
  );
};

export default SupplierReturnDetailForm;
