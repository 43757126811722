import { Box,  Grid, Paper, Button, MenuItem, TextField, Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNearExpiryStockList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NearExpiryPdf from "scenes/reports/pdfForms/inventory/nearExpiredStockPdf";

export default function NearExpiryStock () {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const user = useSelector((state) => state.user);
  const {nearExpiryStockList, isLoadingNearExpiryStockList, setNewPeriod, setExpiryStartDate} = useNearExpiryStockList(1, business_branch_id);
  const [periodType, setPeriodType] = useState("TODAY");
  const [periodInput, setPeriodInput] = useState(1);

  const [openExportOptions, setOpenExportOptions] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenExportOptions(true);
  };

  const handleClose = () => {
    setOpenExportOptions(false);
  };

  const handleChangeFilter = async (e) => {
    setNewPeriod(e.target.value);
    setPeriodType(e.target.value);
  };

  const handleFilterSelect = async () => {
    setExpiryStartDate(periodInput);
  }

  const handleClickView = (e)=>{
    navigate("/productLogList", { state: {productList: [e]} });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    
  return (
    <Box>
      <h3>Near Expiry Items List</h3>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "8px",
              }}
            >
              <h4>EXPIRY LIMIT FROM:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="LIMIT SELECTED"
                  select
                  size="small"
                  defaultValue={"TODAY"}
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="DAYS">DAYS</MenuItem>
                  <MenuItem value="WEEKS">WEEKS</MenuItem>
                </TextField>
              </Paper>
              
            </Box>
          </Grid>
          {periodType !== "TODAY" ? (
            <Grid xs>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "8px",
                }}
              >
                <h4>INPUT PERIOD:</h4>
                <Paper
                  sx={{
                    m: "10px",
                    display: "flex",
                    alignItems: "center",
                    minWidth: 100,
                    maxWidth: 200,
                  }}
                >
                  <TextField
                    label="LIMIT PERIOD"
                    size="small"
                    onChange={(e) => {
                      setPeriodInput(e.target.value);
                    }}
                    type="number"
                    defaultValue={1}
                    margin="dense"
                    inputProps={{
                      min: 1,
                    }}
                    sx={{
                      minWidth: 50,
                      width:'100%',
                      m: "0px",
                      // backgroundColor: "white",
                    }}
                  />
                </Paper>
                
                <Button
                  variant="contained"
                  size="small"
                  sx={{ margin: "12px" }}
                  onClick={handleFilterSelect}
                >
                  FILTER
                </Button>
              </Box>
            </Grid>
          ) : null}
          <Grid xs={4} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate("/productLogList", { state: { productList: [] } })
              }
            >
              VIEW ALL LOGS
            </Button>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              anchorE1={anchorE1}
              endIcon={<DownloadIcon />}
              sx={{ mx: "5px" }}
            >
              EXPORT
            </Button>
            <Menu
              style={{ marginRight: "30px" }}
              anchorEl={anchorE1}
              open={openExportOptions}
              onClose={handleClose}
            >
              <MenuItem
                style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                onClick={() => {handleClose();}}
              >
                <PDFDownloadLink document={<NearExpiryPdf viewData={{ report_detail: nearExpiryStockList,}}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Nearly Expired Stock Report`}>
                  PDF
                </PDFDownloadLink>
              </MenuItem>    
              <MenuItem
                style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                onClick={() => {handleClose();}}
              >
                EXCEL
              </MenuItem>  
            </Menu>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Item Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Description</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 50 }}>Expiry Limit(Days)</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 50 }}>Batch</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 50 }}>Expiry Date</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 50 }}>Remaining Quantity</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 50 }}>Days To Expiry</StyledTableCell>
                  {/* <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>Actions</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {nearExpiryStockList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  nearExpiryStockList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.related_product.product_name}</StyledTableCell>
                          <StyledTableCell>{row.related_product.description}</StyledTableCell>
                          <StyledTableCell  style={{ textAlign: "center" }}>{row.related_product.expiry_limit}</StyledTableCell>
                          <StyledTableCell  style={{ textAlign: "center" }}>{row.batch}</StyledTableCell>
                          <StyledTableCell  style={{ textAlign: "center" }}>{row.expiry_date}</StyledTableCell>
                          <StyledTableCell  style={{ textAlign: "center" }}>{row.remaining_quantity}</StyledTableCell>
                          <StyledTableCell  style={{ textAlign: "center" }}>{Number(row.days_to_expiry) < 0 ? (  <span style={{ color: "red" }}>{Number(row.days_to_expiry)}</span>) : (  Number(row.days_to_expiry))}</StyledTableCell>
                          {/* <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={() => handleClickView(row)}
                            >
                              View Log
                            </Button>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={nearExpiryStockList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};