import { Box, Grid, Paper, Alert, Fab, Breadcrumbs, Link, Typography, useTheme, Card, CardActions, CardContent, CardMedia, Button, Tab, Tabs } from "@mui/material";
import Navbar from "scenes/navbar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FixedSizeDialog from "components/fixedSizeDialog";
import EmployeeDetailEditForm from "./employeeDetailEdit";
import EmployeePictureEditForm from "./employeePictureEdit";
import BadgeIcon from "@mui/icons-material/Badge";
import  Sidebar  from "scenes/global/Sidebar";
import FlexBetween from "components/FlexBetween";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetEmployee } from "api/hooks/humanResource";
import PropTypes from "prop-types";
import EmployeeDocumentTable from "../../tables/employeeDocumentsTable";
import EmployeePayrollTable from "../../tables/employeePayrollDetailTable";
import EmployeePayslipHistoryTable from "../../tables/employeePayslipHistoryTable";
import moment from "moment";
import { URL } from "api/pesabackend";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}


const EmployeeDetailsForm = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const theme = useTheme();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEmployeeDetailEdit, setOpenEmployeeDetailEdit] = useState(false);
  const {employee, isLoadingEmployee, setNewEmployeeData} = useGetEmployee(location.state.id);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  


  const employeeId = location.state.id;
  const related_department = employee ?  employee.related_department.department_name : "";
  const name = employee ? employee.first_name + " " + employee.last_name : "";
  const id_number = employee ? employee.id_number : "";
  const address = employee ? employee.address : "";
  const phone_number = employee ? employee.phone_number : "";
  const next_of_kin_name = employee ? employee. next_of_kin_name : "";
  const next_of_kin_phone_number = employee ? employee.next_of_kin_phone_number : "";
  const employeeImage = employee ? employee.image : "";
  const date_employed = employee ? employee.date_employed : "";


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEmployeeDetailEdit = () => {
    setOpenEmployeeDetailEdit(true);
  };

  const handleCloseEmployeeDetailEdit = () => {
    setOpenEmployeeDetailEdit(false);
  };
 

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        container
        spacing={2}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Operation was successful!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Box
            sx={{
              padding: "20px",
              marginX: "20px",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={() => navigate("/humanResource")}
              >
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Human Resource
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <b>Employee Details</b>
              </Typography>
            </Breadcrumbs>
            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs={3} sx={{ mx: "8px", my: "15px" }}>
                <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <Card sx={{ maxWidth: 300, margin: "20px" }}>
                    <CardMedia
                      sx={{ height: 300 }}
                      image={`${URL}${employeeImage}`}
                      title="Profile Picture"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {related_department}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={handleClickOpen}>
                        Edit Picture
                      </Button>
                    </CardActions>
                  </Card>
                  <FixedSizeDialog
                    dialogButtonName="Edit Employee Picture "
                    dialogHeading="Edit Employee Picture"
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    negativeResponse="Cancel"
                    maxWidth={"md"}
                    content={
                      <Box
                        p="1rem"
                        borderRadius="0.5rem"
                        backgroundColor={theme.palette.background.alt}
                      >
                        <EmployeePictureEditForm
                          setIsSuccess={setIsSuccess}
                          setOpen={setOpen}
                          employeeId={employeeId}
                          setNewEmployeeData={setNewEmployeeData}
                        />
                      </Box>
                    }
                  />
                  <hr />
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      marginY: "20px",
                    }}
                  >
                    <Tab label="Personell Details" {...a11yProps(0)} />
                    <Tab label="Financial Details" {...a11yProps(1)} />
                    {/* <Tab label="Performance Details" {...a11yProps(2)} /> */}
                    <Tab label="Documents" {...a11yProps(2)} />
                  </Tabs>
                </Paper>
              </Grid>
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                <TabPanel value={value} index={0}>
                  <Paper
                    sx={{ width: "100%", padding: "40px", margin: "20px" }}
                    elevation={3}
                  >
                    <FlexBetween>
                      <h2 style={{ margin: "0px", padding: "0px" }}>
                        EMPLOYEE DETAILS
                      </h2>
                      <Fab
                        onClick={handleClickOpenEmployeeDetailEdit}
                        size="small"
                      >
                        <EditOutlinedIcon />
                      </Fab>
                    </FlexBetween>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b> NAME:</b> {name}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b> ID NUMBER:</b> {id_number}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>DEPARTMENT ASSIGNED:</b> {related_department}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>PHONE NUMBER:</b> {phone_number}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>ADDRESS:</b> {address}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>NEXT OF KIN:</b> {next_of_kin_name}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>NEXT OF KIN CONTACT:</b> {next_of_kin_phone_number}
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      <b>DATE OF EMPLOYMENT:</b>{" "}
                      {moment(date_employed).format("LL")}
                    </p>
                    <FixedSizeDialog
                      dialogButtonName="Edit Employee Details "
                      dialogHeading="Edit  Employee Details"
                      open={openEmployeeDetailEdit}
                      handleClickOpen={handleClickOpenEmployeeDetailEdit}
                      handleClose={handleCloseEmployeeDetailEdit}
                      negativeResponse="Cancel"
                      maxWidth={"md"}
                      content={
                        <Box
                          p="1rem"
                          borderRadius="0.5rem"
                          backgroundColor={theme.palette.background.alt}
                        >
                          <EmployeeDetailEditForm
                            data={{
                              employeeId: employeeId,
                              first_name: employee ? employee.first_name : "",
                              last_name: employee ? employee.last_name : "",
                              id_number: id_number,
                              address: address,
                              phone_number: phone_number,
                              related_department: employee
                                ? employee.related_department.id
                                : "",
                              next_of_kin_name: next_of_kin_name,
                              next_of_kin_phone_number:
                                next_of_kin_phone_number,
                            }}
                            setIsSuccess={setIsSuccess}
                            setOpen={setOpenEmployeeDetailEdit}
                            setNewEmployeeData={setNewEmployeeData}
                          />
                        </Box>
                      }
                    />
                  </Paper>

                  <Paper
                    sx={{ width: "100%", padding: "40px", margin: "20px" }}
                    elevation={3}
                  >
                    <FlexBetween>
                      <h2 style={{ margin: "0px", padding: "0px" }}>
                        PAYROLL DETAILS
                      </h2>
                    </FlexBetween>

                    <EmployeePayrollTable
                      employeeId={employeeId}
                      setIsSuccess={setIsSuccess}
                    />
                  </Paper>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <EmployeePayslipHistoryTable employeeId={employeeId} />
                </TabPanel>
                {/* <TabPanel value={value} index={2}></TabPanel> */}
                <TabPanel value={value} index={2}>
                  <EmployeeDocumentTable
                    setIsFailed={setIsFailed}
                    setIsSuccess={setIsSuccess}
                    employeeId={employeeId}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeDetailsForm;
