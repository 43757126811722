
import {Box,Button,TextField,useMediaQuery,Typography,useTheme,Switch,FormControlLabel, MenuItem, Alert} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import ItemUnitDetailForm from "./itemUnitDetailForm";
import { requester } from "api/pesabackend";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import { URL } from "api/pesabackend";
import axios from "axios";


const ItemForm = (props) => {
  let {id} = props; 
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  const token =  useSelector((state) => state.token);

  const [unitList, setUnitList] = useState([]);
  const [isLoadingUnitList, setIsLoadingUnitList] = useState(true);
  const [unitListError, setUnitListError] = useState(false);

  const [typeList, setTypeList] = useState([]);
  const [isLoadingTypeList, setIsLoadingTypeList] = useState(true);
  const [typeListError, setTypeListError] = useState(false);

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoadingSubCategoryList, setIsLoadingSubCategoryList] = useState(true);
  const [SubCategoryListError, setSubCategoryListError] = useState(false);
    
  const fetchUnitList = async (search) => {
      try {
          const response = await requester.get(`/inventory/business_product_units/list?business_branch=${business_branch_id}&search=${search}`);
          setUnitList(response.data.data);
          setIsLoadingUnitList(false);
      } catch (e) {
          setUnitListError(true);
          setIsLoadingUnitList(false);
      }
  };

  const fetchTypeList = async (search) => {
    try {
        const response = await requester.get(`/inventory/product_categories/list?business_branch=${business_branch_id}&search=${search}`);
        setTypeList(response.data.data);
        setIsLoadingTypeList(false);
    } catch (e) {
        setTypeListError(true);
        setIsLoadingTypeList(false);
    }
  };

  const fetchSubCategoryList = async (filter) => {
    try {
        const response = await requester.get(`/inventory/product_sub_categories/list?business_branch=${business_branch_id}&categoryFilter=${filter}`);
        setSubCategoryList(response.data.data);
        setIsLoadingSubCategoryList(false);
    } catch (e) {
        setSubCategoryListError(true);
        setIsLoadingSubCategoryList(false);
    }
  };

  useEffect(()=>{
    fetchUnitList('false');
    fetchTypeList('false');
  },[])

  

  const initialValuesItem = {
    product_name: "",
    description: "",
    maximum_stock: 0,
    reorder_level: 10,
    expiry_limit: 90,
    dead_stock_limit: 90,
    selling_price: 0,
    related_product_category: "",
    related_product_sub_category: "",
    is_prescription_medicine: false,
    picture: "",
    addUnits: false,
    smallest_unit: "",
    product_units_list: [
      {
        related_business_product_unit: "",
        unit_selling_price: "",
        number_of_smallest_units: "",
        data_id: uuidv4(),
      },
    ],
  };


  const[addMoreUnits, setAddMoreUnits]= useState(false);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);


  const itemSchema = yup.object().shape({
    product_name: yup.string().required("required"),
    description: yup.string(),
    addUnits: yup.boolean(),
    maximum_stock: yup.number().required("required"),
    reorder_level: yup.number().required("required"),
    expiry_limit: yup.number().required("required"),
    dead_stock_limit: yup.number().required("required"),
    selling_price: yup.number().required("required"),
    smallest_unit: yup.number().required("required"),
    related_product_category: yup.number().required("required"),
    related_product_sub_category: yup.number(),
    is_prescription_medicine: yup.boolean(),
    picture: yup.string(),
    product_units_list: addMoreUnits
      ? yup.array().of(
          yup.object().shape({
            related_business_product_unit: yup.number().required("required"),
            unit_selling_price: yup.number().required("required"),
            number_of_smallest_units: yup.number().required("required"),
          })
        )
      : yup.array(),
  });

  const[itemUnitDetailFormData, setItemUnitDetailFormData] = useState([{
    related_business_product_unit: '',  unit_selling_price: '', number_of_smallest_units: '', data_id: uuidv4(),
  }]);
  
  const handleAddingMoreUnits = (e) => {
    
    if (e.target.checked === true ) {
      setAddMoreUnits(true)
    }
    if (e.target.checked === false ) {
      setAddMoreUnits(false)
    }
  };  
  

  const saveItem = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    let formData = new FormData();
    formData.append('picture', values['picture']);

    delete values.picture

    const savedJsonDataResponse = await requester.post(
      `/inventory/products/add`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (savedJsonDataResponse.data.status===201) {
      
      props.setIsSuccess(true);
      props.setNewItemData(true);
      props.setOpen(false);

      if (formData.get("picture") && formData.get("picture")!=='undefined') {
        formData.append("id", savedJsonDataResponse.data.data.id);

        const saveImageResponse = await axios.post( `${URL}/inventory/products/upload_image`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        if (saveImageResponse.data.status===400) {
          setIsFailed(true)
          setIsFailedError('Image Upload not successful')
        } 
      };

      onSubmitProps.resetForm();
    };

    if (savedJsonDataResponse.data.status===400) {
      setIsFailed(true)
      setIsFailedError(savedJsonDataResponse.data.errors)
    };
  };


  const handleItemUnitChange = (event, index, value) =>{
  
    const newArray = itemUnitDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_business_product_unit: value}
        }
        return item
    })
    setItemUnitDetailFormData(newArray)
  };

  const handleNumberOfSmallestUnitChange = (event, index) =>{
    const {value} = event.target;
    const newArray = itemUnitDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, number_of_smallest_units: value}
        }
        return item
    })
    setItemUnitDetailFormData(newArray)
  };

  const handleSellingPriceChange = (event, index) =>{
    const {value} = event.target;
    const newArray = itemUnitDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, unit_selling_price: value}
        }
        return item
    })
    setItemUnitDetailFormData(newArray)
  };

  const handleAddItemUnitDetailSubmitData = () => {
    const oneRow = {
      related_business_product_unit:'', unit_selling_price: '', number_of_smallest_units: '',data_id: uuidv4()
    };
    setItemUnitDetailFormData([...itemUnitDetailFormData, oneRow])
  }; 

  const handleRemoveItemUnitRow = (data_id) => {
    const newFormData = itemUnitDetailFormData.filter((i) => i.data_id !== data_id)
    setItemUnitDetailFormData(newFormData)      
  };


  const handleFormSubmit = async (values, onSubmitProps) => {

    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>

          {
             isFailed ? (
              <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
          ) : null
          }
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Item Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.product_name}
              name="product_name"
              error={
                Boolean(touched.product_name) && Boolean(errors.product_name)
              }
              helperText={touched.product_name && errors.product_name}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Description"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={2}
              value={values.description}
              name="description"
              error={Boolean(touched.description) && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              sx={{ gridColumn: "span 4" }}
            />
            <p style={{ color: "red", gridColumn: "span 4"  }}> *Please select the smallest unit carefully as it can not be editted after saving</p>

            <TextField
              label="Smallest  Unit of Sale"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.smallest_unit}
              name="smallest_unit"
              select
              error={Boolean(touched.smallest_unit) && Boolean(errors.smallest_unit)}
              helperText={touched.smallest_unit && errors.smallest_unit}
              sx={{ gridColumn: "span 2" }}
            >

                {unitList.map((row, index)=>{
                  return(
                  <MenuItem key={index} value={row.id}>{row.name}</MenuItem>
                  );
                })}
            </TextField>

            
            <TextField
              label="Selling Price"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.selling_price}
              inputProps={{
                min: 0
              }}
              name="selling_price"
              error={
                Boolean(touched.selling_price) && Boolean(errors.selling_price)
              }
              helperText={touched.selling_price && errors.selling_price}
              sx={{ gridColumn: "span 2" }}
            />


            <TextField
              label="Product Category"
              onBlur={handleBlur}
              onChange={(e)=>{ 
                handleChange(e); 
                fetchSubCategoryList(e.target.value)
              }}
              value={values.related_product_category}
              name="related_product_category"
              select
              error={Boolean(touched.related_product_category) && Boolean(errors.related_product_category)}
              helperText={touched.related_product_category && errors.related_product_category}
              sx={{ gridColumn: "span 1" }}
            >

                {typeList.map((row, index)=>{
                  return(
                  <MenuItem key={index} value={row.id}>{row.category_name}</MenuItem>
                  );
                })}
            </TextField>

            <TextField
              label="Product Sub-Category"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.related_product_sub_category}
              name="related_product_sub_category"
              select
              error={Boolean(touched.related_product_sub_category) && Boolean(errors.related_product_sub_category)}
              helperText={touched.related_product_sub_category && errors.related_product_sub_category}
              sx={{ gridColumn: "span 1" }}
            >

                {subCategoryList.map((row, index)=>{
                  return(
                  <MenuItem key={index} value={row.id}>{row.sub_category_name}</MenuItem>
                  );
                })}
            </TextField>

            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>

            <FormControlLabel
              value={values.is_prescription_medicine}
              name="is_prescription_medicine"
              control={
                <Switch 
                  color="primary" 
                  onChange={handleChange}
                />
              }
              label="Is this Prescriprion medicine?:  No"
              labelPlacement="start"
            />
            <Typography px="5px">Yes</Typography>

            </Box>

            <TextField
              label="Reorder Level"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.reorder_level}
              type="number"
              inputProps={{
                min: 0
              }}
              name="reorder_level"
              error={Boolean(touched.reorder_level) && Boolean(errors.reorder_level)}
              helperText={touched.reorder_level && errors.reorder_level}
              sx={{ gridColumn: "span 1" }}
            />
            
            <TextField
              label="Expiry Limit(in days)"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              inputProps={{
                min: 0
              }}
              value={values.expiry_limit}
              name="expiry_limit"
              error={
                Boolean(touched.expiry_limit) && Boolean(errors.expiry_limit)
              }
              helperText={touched.expiry_limit && errors.expiry_limit}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Dead Stock Limit(in days)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dead_stock_limit}
              type="number"
              inputProps={{
                min: 0
              }}
              name="dead_stock_limit"
              error={Boolean(touched.dead_stock_limit) && Boolean(errors.dead_stock_limit)}
              helperText={touched.dead_stock_limit && errors.dead_stock_limit}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Maximum Stock"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maximum_stock}
              type="number"
              inputProps={{
                min: 0
              }}
              name="maximum_stock"
              error={Boolean(touched.maximum_stock) && Boolean(errors.maximum_stock)}
              helperText={touched.maximum_stock && errors.maximum_stock}
              sx={{ gridColumn: "span 1" }}
            />
            
            <br/>
            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>

            <FormControlLabel
              value={values.addUnits = addMoreUnits}
              name="addUnits"
              control={
                <Switch 
                  color="primary" 
                  onChange={ (e)=>{
                    handleChange(e)
                    handleAddingMoreUnits(e)
                  }}
                />
              }
              label="Add more Units of Measure(UOM):  No"
              labelPlacement="start"
            />
            <Typography px="5px">Yes</Typography>

            </Box>

            {addMoreUnits === true ? (
              <Box
                gridColumn="span 4"
                border={`1px solid ${palette.neutral.medium}`}
                borderRadius="5px"
                p="1rem"
              >
                <h5>Add Other Item Units here: </h5> 
                
                <FieldArray
                              name="product_units_list"
                              render={(arrayHelpers)=>{
                                const product_units_list = values.product_units_list
                                return(
                                  <>
                                    {product_units_list.map((row, index) => {
                                      return (
                                        <ItemUnitDetailForm key={row.data_id}
                                          formType="create"
                                          index={index}
                                          data={row}
                                          handleRemoveItemUnitRow={(index)=>{
                                            arrayHelpers.remove(index);
                                            handleRemoveItemUnitRow(index);
                                          }}
                                          handleItemUnitChange = {handleItemUnitChange}
                                          handleNumberOfSmallestUnitChange = {handleNumberOfSmallestUnitChange}
                                          handleSellingPriceChange = {handleSellingPriceChange}
                                          handleChange={handleChange}
                                          errors={arrayHelpers.form.errors}
                                          touched={arrayHelpers.form.touched}
                                          name={`product_units_list[${index}]`}
                                          handleBlur={handleBlur}
                                          unitList={unitList}
                                        />
                                      )
                                    })}
                          
                                    <Button
                                      onClick={()=>{
                                        arrayHelpers.push(
                                          {
                                            related_business_product_unit: '',  unit_selling_price: '', number_of_smallest_units: '', data_id: uuidv4(),
                                          }
                                        );
                                        handleAddItemUnitDetailSubmitData();
                                      }}
                                      sx={{
                                        m: "1rem 0",
                                      }}
                                      size="small"
                                      variant="contained"
                                    >
                                      Add Unit
                                    </Button>
                                    </>
                                )
                              }}
                            />

              </Box>) : (
              null
            )}

            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  setFieldValue("picture", acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.picture ? (
                      <p>Add Item Picture Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.picture.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ItemForm;
