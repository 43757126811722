
import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";


const PurchaseOrderDetailForm = (props) => {


    const {index, 
        data,
        handleRemoveProductDetailRow, 
        handleItemAmountChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [unitsForGivenItemList, setUnitsForGivenItemList] = React.useState(data.product_units_list ? data.product_units_list : []);
    const [isLoadingUnitsForGivenItemList, setLoadingUnitsForGivenItemList] = React.useState(true);
	const [error, setError] = React.useState(false);
    const [productList, setProductList] = React.useState(data.product_info ? [data.product_info] : []);
	const [isLoadingItemList, setLoadingItemList] = React.useState(true);
    const business_branch_id = useSelector((state) => state.business_branch_id);
    

    
    
    const fetchUnitsForGivenItemList = async (productId) => {
        try {
            const response = await requester.get(`/inventory/products/unit_list/${productId}`);
            setUnitsForGivenItemList(response.data.data);
            setLoadingUnitsForGivenItemList(false);
        } catch (e) {
            setError(true);
            setLoadingUnitsForGivenItemList(false);
        }
    };
    
    const fetchItemList = async (search) => {
        try {
            if (search) {
              const response = await requester.get(
                `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
              );
              setProductList(response.data.data);
            } else {
              const response = await requester.get(
                `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
              );
              setProductList(response.data.data);
            }
        } catch (e) {
            setError(true);
            setLoadingItemList(false);
        }
    };
    

    return (
            
        
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="5px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>
                    <Autocomplete
                        size="small"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        name={`${name}.related_product`}
                        onChange={(e, value)=>{
                            if (value) {fetchUnitsForGivenItemList(value.id);};
                            setFieldValue(
                                `${name}.related_product`,
                                value ? value.id : ''
                              );
                            setFieldValue(`${name}.related_product_unit`, "");
                        }}
                        sx={{ gridColumn: "span 3" }}
                        options={productList}
                        defaultValue = {productList[0]}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id}>
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{...params.inputProps}}
                                label="Item Name"
                                value={data.related_product ? data.related_product ?? null : ''}
                                error={Boolean( touched?.products_ordered && touched?.products_ordered[index]?.related_product) && Boolean(errors?.products_ordered && errors?.products_ordered[index]?.related_product)}
                                helperText={ touched?.products_ordered && touched?.products_ordered[index]?.related_product && errors?.products_ordered && errors?.products_ordered[index]?.related_product}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                    />
                    

                    <TextField
                        label="Item Unit"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={data.related_product_unit}
                        name={`${name}.related_product_unit`}
                        error={Boolean( touched?.products_ordered && touched?.products_ordered[index]?.related_product_unit) && Boolean(errors?.products_ordered && errors?.products_ordered[index]?.related_product_unit)}
                        helperText={ touched?.products_ordered && touched?.products_ordered[index]?.related_product_unit && errors?.products_ordered && errors?.products_ordered[index]?.related_product_unit}
                        sx={{ gridColumn: "span 2" }}
                    >
                        {unitsForGivenItemList.map((oneUnit, index)=>{
                            return(
                                <MenuItem key={index} value= {oneUnit.id} selected={oneUnit.id===data.related_product_unit ? true :false }>{oneUnit.related_business_product_unit.name}</MenuItem>
                            );
                        })}
                        
                    </TextField>

                    <TextField
                        label="Quantity"
                        size="small"
                        type="number"
                        inputProps={{
                            min: 0
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.quantity}
                        name={`${name}.quantity`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.quantity) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.quantity)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.quantity && errors?.products_purchased && errors?.products_purchased[index]?.quantity}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Amount"
                        size="small"
                        type="number"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleItemAmountChange(e,index);
                        }}
                        value={data.amount}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.amount`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.amount) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.amount)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.amount && errors?.products_purchased && errors?.products_purchased[index]?.amount}
                        sx={{ gridColumn: "span 2" }}
                    />                                      
                    
                    {index > 0 ? 
                        <Button
                            value={index}
                            onClick={() => {
                                handleRemoveProductDetailRow(index);
                            }}                                                     
                            size="small"
                            variant="contained"
                            sx={{ 
                                width: "20%",
                            }}
                        >
                            Remove
                        </Button> : null
                    }
                </>
            </Box>
        </Box>           
                            
           );
  };
  
  export default PurchaseOrderDetailForm;
  