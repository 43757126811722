import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";

import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import InventoryTab from "scenes/inventory/tabs/inventoryTab";
import InventorySetupTab from "scenes/inventory/tabs/inventorySetupTab";
import CustomerTab from "scenes/customer/tabs/customerTab";
import PurchaseTab from "scenes/purchase/tabs/purchaseTab";
import SupplierTab from "scenes/supplier/tabs/supplierTab";
import UserManagementTab from "scenes/userManagement/tabs/userManagementTab";
import AccountingTab from "scenes/accounting/tabs/accountingTab";
import HumanResourceTab from "scenes/humanResource/tabs/humanResourceTab";
import SettingsTab from "scenes/settings/tabs/settingsTab";
import BillingTab from "scenes/billing/tabs/billingTab";
import DashboardTab from "scenes/dashboards/tabs/dashboardTab";
import StockTransferTab from "scenes/stockTransfer/tabs/stockTransferTab";
import BusinessTab from "scenes/business/tabs/businessTab";
import UploadsTab from "scenes/upload/tabs/uploadTab";

// import PurchaseForm from "scenes/purchase/forms/purchaseForms/purchaseForm";
import InvoiceForm from "scenes/billing/forms/invoiceForm";
import InvoiceDetailsView from "scenes/billing/views/invoiceDetailView";
import ReceiptDetailsView from "scenes/billing/views/receiptDetailView";
import MainDashboard from "scenes/dashboards/forms/mainDashboard";
import ProductLog from "scenes/inventory/tables/productLogs";
import AdjustmentForm from "scenes/inventory/forms/adjustmentForms/stockAdjustmentForm";
import PurchaseOrderForm from "scenes/purchase/forms/purchaseOrderForms/purchaseOrderForm";
import PurchaseViewForm from "scenes/purchase/forms/purchaseForms/purchaseViewForm";
import EditPurchaseForm from "scenes/purchase/forms/purchaseForms/editPurchaseForm";
import PurchaseOrderViewForm from "scenes/purchase/forms/purchaseOrderForms/purchaseOrderViewForm";
import EditPurchaseOrderForm from "scenes/purchase/forms/purchaseOrderForms/editPurchaseOrderFom";
import EmployeeDetailsForm from "scenes/humanResource/forms/employeeForms/employeeDetailsForm";
import UserGroupCreateForm from "scenes/userManagement/forms/userGroupCreateForm";
import EditUserGroupPermssionForm from "scenes/userManagement/forms/editGroupPermisions";
import ReportsForm from "scenes/reports/forms";
import PayslipForm from "scenes/humanResource/forms/payslipForms/payslipForm";
import EditPayslipForm from "scenes/humanResource/forms/payslipForms/editPayslipForm";
import PurchaseRequisitionForm from "scenes/purchase/forms/purchaseRequisitionForms/purchaseRequisitionForm";
import EditPurchaseRequisitionForm from "scenes/purchase/forms/purchaseRequisitionForms/editPurchaseRequisitionForm";
import CustomerReturnForm from "scenes/returns/forms/customerReturnForm";
import SupplierReturnForm from "scenes/purchase/forms/returns/supplierReturnForm";
import PurchaseDraftForm from "scenes/purchase/forms/purchaseDraftForms/purchaseDraftForm";
import EditPurchaseDraftForm from "scenes/purchase/forms/purchaseDraftForms/editPurchaseDraftForm";
import PurchaseDraftViewForm from "scenes/purchase/forms/purchaseDraftForms/purchaseDraftViewForm";
import StockTransferCreateForm from "scenes/stockTransfer/forms/createStockTransferForm";
import EditStockTransferForm from "scenes/stockTransfer/forms/editStockTransferForm";

import ItemPurchaseDetailReport from "scenes/reports/tables/purchase/itemPurchaseDetailReport";
import ItemPurchaseSummativeReport from "scenes/reports/tables/purchase/itemPurchaseSummativeReport";
import ItemSalesSummativeReport from "scenes/reports/tables/sales/itemSalesSummativeReport";
import BalanceSheet from "scenes/reports/tables/accounting/balanceSheet";
import ProfitAndLossReport from "scenes/reports/tables/accounting/profitLossReport";
import ItemSalesReport from "scenes/reports/tables/sales/itemSalesReport";
import AgingReport from "scenes/reports/tables/inventory/agingReport";
import ItemTrends from "scenes/reports/tables/inventory/itemTrends";
import RecievableReport from "scenes/reports/tables/accounting/recievables";
import PayablesReport from "scenes/reports/tables/accounting/payables";
import SupplierPurchaseReport from "scenes/reports/tables/purchase/supplierPurchaseReport";
import BatchwiseItemStockReport from "scenes/reports/tables/inventory/batchwiseStockReport";
import CustomerReturnReport from "scenes/reports/tables/returns/customerReturnReport";
import SupplierReturnReport from "scenes/reports/tables/returns/supplierReturnReport";
import DeadStockReport from "scenes/reports/tables/inventory/deadStockReport";
import StockAdjustmentReport from "scenes/reports/tables/inventory/stockAdjustmentReport";

import ChartNextAccounts from "scenes/accounting/tables/chartNextAccounts";


function App() {
  const mode = useSelector((state) => state.mode);
  const token =  useSelector((state) => state.token);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = token!==null ? true : false;

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/home"
              // element={isAuth ? <HomePage /> : <Navigate to="/" />}
              element={isAuth ? <DashboardTab /> : <Navigate to="/" />}
            />
            {/* <Route
              path="/dashboard"
              element={isAuth ? <MainDashboard /> : <Navigate to="/" />}
            /> */}
            <Route
              path="/customer"
              element={isAuth ? <CustomerTab /> : <Navigate to="/" />}
            />
            <Route
              path="/billing"
              element={isAuth ? <BillingTab /> : <Navigate to="/" />}
            />
            <Route
              path="/invoiceForm"
              element={isAuth ? <InvoiceForm /> : <Navigate to="/" />}
            />
            <Route
              path="/invoiceDetails"
              element={isAuth ? <InvoiceDetailsView /> : <Navigate to="/" />}
            />
            <Route
              path="/receiptDetails"
              element={isAuth ? <ReceiptDetailsView /> : <Navigate to="/" />}
            />
            <Route
              path="/supplier"
              element={isAuth ? <SupplierTab /> : <Navigate to="/" />}
            />
            <Route
              path="/purchase"
              element={isAuth ? <PurchaseTab /> : <Navigate to="/" />}
            />
            <Route
              path="/inventory"
              element={isAuth ? <InventoryTab /> : <Navigate to="/" />}
            />
            <Route
              path="/inventorySetup"
              element={isAuth ? <InventorySetupTab /> : <Navigate to="/" />}
            />
            <Route
              path="/settings"
              element={isAuth ? <SettingsTab /> : <Navigate to="/" />}
            />
            <Route
              path="/userManagement"
              element={isAuth ? <UserManagementTab /> : <Navigate to="/" />}
            />
            <Route
              path="/accounting"
              element={isAuth ? <AccountingTab /> : <Navigate to="/" />}
            />
            <Route
              path="/chartNextAccounts"
              element={isAuth ? <ChartNextAccounts /> : <Navigate to="/" />}
            />
            <Route
              path="/humanResource"
              element={isAuth ? <HumanResourceTab /> : <Navigate to="/" />}
            />
            <Route
              path="/dashboards"
              element={isAuth ? <DashboardTab /> : <Navigate to="/" />}
            />

            <Route
              path="/transfers"
              element={isAuth ? <StockTransferTab /> : <Navigate to="/" />}
            />

            <Route
              path="/fileUploads"
              element={isAuth ? <UploadsTab /> : <Navigate to="/" />}
            />

            <Route
              path="/business"
              element={isAuth ? <BusinessTab /> : <Navigate to="/" />}
            />

            <Route
              path="/reports"
              element={isAuth ? <ReportsForm /> : <Navigate to="/" />}
            />

            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/purchaseViewForm"
              element={isAuth ? <PurchaseViewForm /> : <Navigate to="/" />}
            />
            <Route
              path="/purchaseDraftForm"
              element={isAuth ? <PurchaseDraftForm /> : <Navigate to="/" />}
            />
            <Route
              path="/purchaseDraftViewForm"
              element={isAuth ? <PurchaseDraftViewForm /> : <Navigate to="/" />}
            />
            <Route
              path="/editPurchaseDraftForm"
              element={isAuth ? <EditPurchaseDraftForm /> : <Navigate to="/" />}
            />
            <Route
              path="/productLogList"
              element={isAuth ? <ProductLog /> : <Navigate to="/" />}
            />
            <Route
              path="/adjustmentForm"
              element={isAuth ? <AdjustmentForm /> : <Navigate to="/" />}
            />
            <Route
              path="/purchaseOrderForm"
              element={isAuth ? <PurchaseOrderForm /> : <Navigate to="/" />}
            />
            <Route
              path="/editPurchaseOrderForm"
              element={isAuth ? <EditPurchaseOrderForm /> : <Navigate to="/" />}
            />
            <Route
              path="/purchaseOrderViewForm"
              element={isAuth ? <PurchaseOrderViewForm /> : <Navigate to="/" />}
            />

            <Route
              path="/payslipForm"
              element={isAuth ? <PayslipForm /> : <Navigate to="/" />}
            />

            <Route
              path="/editPayslipForm"
              element={isAuth ? <EditPayslipForm /> : <Navigate to="/" />}
            />

            <Route
              path="/purchaseRequisitionForm"
              element={
                isAuth ? <PurchaseRequisitionForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/editPurchaseRequisitionForm"
              element={
                isAuth ? <EditPurchaseRequisitionForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/customerReturnForm"
              element={isAuth ? <CustomerReturnForm /> : <Navigate to="/" />}
            />
            <Route
              path="/supplierReturnForm"
              element={isAuth ? <SupplierReturnForm /> : <Navigate to="/" />}
            />
            <Route
              path="/stockTransferForm"
              element={
                isAuth ? <StockTransferCreateForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/editStockTransferForm"
              element={isAuth ? <EditStockTransferForm /> : <Navigate to="/" />}
            />

            <Route
              path="/employeeDetails"
              element={isAuth ? <EmployeeDetailsForm /> : <Navigate to="/" />}
            />
            <Route
              path="/userGroup"
              element={isAuth ? <UserGroupCreateForm /> : <Navigate to="/" />}
            />

            <Route
              path="/userGroupPermissions"
              element={isAuth ? <EditUserGroupPermssionForm /> : <Navigate to="/" />}
            />

            <Route
              path="/itemPurchaseDetailReport"
              element={
                isAuth ? <ItemPurchaseDetailReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/itemPurchaseSummativeReport"
              element={
                isAuth ? <ItemPurchaseSummativeReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/balanceSheet"
              element={isAuth ? <BalanceSheet /> : <Navigate to="/" />}
            />
            <Route
              path="/payables"
              element={isAuth ? <PayablesReport /> : <Navigate to="/" />}
            />
            <Route
              path="/profit&Loss"
              element={isAuth ? <ProfitAndLossReport /> : <Navigate to="/" />}
            />
            <Route
              path="/recievables"
              element={isAuth ? <RecievableReport /> : <Navigate to="/" />}
            />
            <Route
              path="/agingReport"
              element={isAuth ? <AgingReport /> : <Navigate to="/" />}
            />
            <Route
              path="/itemTrends"
              element={isAuth ? <ItemTrends /> : <Navigate to="/" />}
            />
            <Route
              path="/supplierPurchase"
              element={
                isAuth ? <SupplierPurchaseReport /> : <Navigate to="/" />
              }
            />
            <Route
              path="/itemSalesReport"
              element={isAuth ? <ItemSalesReport /> : <Navigate to="/" />}
            />
            <Route
              path="/itemSalesSummativeReport"
              element={
                isAuth ? <ItemSalesSummativeReport /> : <Navigate to="/" />
              }
            />

            <Route
              path="/batchwiseItemStockReport"
              element={
                isAuth ? <BatchwiseItemStockReport /> : <Navigate to="/" />
              }
            />

            <Route
              path="/deadStockReport"
              element={isAuth ? <DeadStockReport /> : <Navigate to="/" />}
            />

            <Route
              path="/customerReturnReport"
              element={isAuth ? <CustomerReturnReport /> : <Navigate to="/" />}
            />

            <Route
              path="/supplierReturnReport"
              element={isAuth ? <SupplierReturnReport /> : <Navigate to="/" />}
            />
            <Route
              path="/stockAdjustmentReport"
              element={isAuth ? <StockAdjustmentReport /> : <Navigate to="/" />}
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
