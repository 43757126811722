import {useEffect, useState} from "react";
import requester from "api/pesabackend"; 
import moment from "moment";


const useYearsList = () => {
    const [yearList, setYearList] = useState([]);
	const [isLoadingYearsList, setLoadingYearsList] = useState(true);
	const [error, setError] = useState(false);

	let urlString = `/inventory/years`;
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setYearList(response.data.data);
				setLoadingYearsList(false);
			} catch (e) {
				setError(true);
				setLoadingYearsList(false);
			}
		};
		fetchData();
	}, [isLoadingYearsList, urlString]);
	return { yearList, isLoadingYearsList, error};
};


const useItemList = (pageNumber, business_branch_id) => {
    const [productList, setProductList] = useState([]);
	const [isLoadingItemList, setLoadingItemList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemData, setNewItemData] = useState(true);
	const [searchItem, setSearchItem] = useState(null);
	const [itemTypeFilter, setItemTypeFilter] = useState("all")

	let urlString;

	if (searchItem) {
		urlString = `/inventory/products/list?business_branch=${business_branch_id}&search=${searchItem}&typeFilter=${itemTypeFilter}`;
	} else {
		urlString = `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=${itemTypeFilter}`;
	}
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setProductList(response.data.data);
				setLoadingItemList(false);
				setNewItemData(false)
			} catch (e) {
				setError(true);
				setLoadingItemList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemList, newItemData, urlString]);
	return { productList, isLoadingItemList, error, setNewItemData, setSearchItem, setItemTypeFilter };
};


const useItemUnitList = (pageNumber, business_branch_id) => {
    const [unitList, setUnitList] = useState([]);
	const [isLoadingItemUnitList, setLoadingItemUnitList] =useState(true);
	const [error, setError] = useState(false);
	const [newItemUnitData, setNewItemUnitData] = useState(true);
	const [searchUnit, setUnitSearch] = useState(false);
	
	useEffect(() => {

		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/business_product_units/list?business_branch=${business_branch_id}&search=${searchUnit}`);
				setUnitList(response.data.data);
				setLoadingItemUnitList(false);
				setNewItemUnitData(false)
			} catch (e) {
				setError(true);
				setLoadingItemUnitList(false);
			}
		};
		fetchData();
		
	}, [pageNumber,isLoadingItemUnitList, newItemUnitData, searchUnit]);
	return { unitList, isLoadingItemUnitList, error, setNewItemUnitData, setUnitSearch };
};

// TYPE TO BE CHANGED TO CATEGORY
const useItemTypeList = (pageNumber, business_branch_id) => {
    const [typeList, setTypeList] = useState([]);
	const [isLoadingItemTypeList, setLoadingItemTypeList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemTypeData, setNewItemTypeData] = useState(true);
	const [searchItemType, setItemTypeSearch] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/product_categories/list?business_branch=${business_branch_id}&search=${searchItemType}`);
				setTypeList(response.data.data);
				setLoadingItemTypeList(false);
				setNewItemTypeData(false)
			} catch (e) {
				setError(true);
				setLoadingItemTypeList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemTypeList,newItemTypeData, searchItemType]);
	return { typeList, isLoadingItemTypeList, error, setNewItemTypeData, setItemTypeSearch };
};


const useItemSubCategoryList = (pageNumber, business_branch_id) => {
    const [subCategoryList, setSubCategoryList] = useState([]);
	const [isLoadingItemSubCategoryList, setLoadingItemSubCategoryList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemSubCategoryData, setNewItemSubCategoryData] = useState(true);
	const [searchItemSubCategory, setItemSubCategorySearch] = useState(false);
	const [itemCategoryFilter, setItemCategoryFilter] = useState("all");

	let urlString;

	if (searchItemSubCategory) {
		urlString = `/inventory/product_sub_categories/list?business_branch=${business_branch_id}&search=${searchItemSubCategory}&categoryFilter=${itemCategoryFilter}`;
	} else {
		urlString = `/inventory/product_sub_categories/list?business_branch=${business_branch_id}&categoryFilter=${itemCategoryFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setSubCategoryList(response.data.data);
				setLoadingItemSubCategoryList(false);
				setNewItemSubCategoryData(false)
			} catch (e) {
				setError(true);
				setLoadingItemSubCategoryList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemSubCategoryList,newItemSubCategoryData, urlString]);
	return { subCategoryList, isLoadingItemSubCategoryList, error, setNewItemSubCategoryData, setItemSubCategorySearch , setItemCategoryFilter};
};


const useSupplierList = (pageNumber, business_branch_id) => {
    const [supplierList, setSupplierList] = useState([]);
	const [isLoadingSupplierList, setLoadingSupplierList] = useState(true);
	const [error, setError] = useState(false);
	const [newSupplierData, setNewSupplierData] = useState(true);
	const [search, setSearch] = useState(false);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/suppliers/list?business_branch=${business_branch_id}&search=${search}`);
				setSupplierList(response.data.data);
				setLoadingSupplierList(false);
				setNewSupplierData(false)
			} catch (e) {
				setError(true);
				setLoadingSupplierList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingSupplierList, newSupplierData, search]);
	return { supplierList, isLoadingSupplierList, error, setNewSupplierData, setSearch };
};


const usePurchaseList = (pageNumber, business_branch_id) => {
    const [purchaseList, setPurchaseList] = useState([]);
	const [isLoadingPurchaseList, setLoadingPurchaseList] = useState(true);
	const [error, setError] = useState(false);
	const [newPurchaseData, setNewPurchaseData] = useState(true);
	const [search, setSearch] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState('all');

	let urlString;

	if (search) {
		urlString = `/inventory/product_purchase/list?business_branch=${business_branch_id}&search=${search}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/inventory/product_purchase/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseList(response.data.data);
				setLoadingPurchaseList(false);
				setNewPurchaseData(false)
			} catch (e) {
				setError(true);
				setLoadingPurchaseList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseList, newPurchaseData, urlString]);
	return { purchaseList, isLoadingPurchaseList, error, setNewPurchaseData, setSearch, setSupplierFilter };
};


const usePurchaseDraftList = (pageNumber, business_branch_id) => {
    const [purchaseDraftList, setPurchaseDraftList] = useState([]);
	const [isLoadingPurchaseDraftList, setLoadingPurchaseDraftList] = useState(true);
	const [error, setError] = useState(false);
	const [newPurchaseDraftData, setNewPurchaseDraftData] = useState(true);
	const [search, setSearch] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState('all');

	let urlString;

	if (search) {
		urlString = `/inventory/purchase_draft/list?business_branch=${business_branch_id}&search=${search}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/inventory/purchase_draft/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseDraftList(response.data.data);
				setLoadingPurchaseDraftList(false);
				setNewPurchaseDraftData(false)
			} catch (e) {
				setError(true);
				setLoadingPurchaseDraftList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseDraftList, newPurchaseDraftData, urlString]);
	return { purchaseDraftList, isLoadingPurchaseDraftList, error, setNewPurchaseDraftData, setSearch, setSupplierFilter };
};


const usePurchaseReturnList = (pageNumber, business_branch_id) => {
    const [purchaseReturnList, setPurchaseReturnList] = useState([]);
	const [isLoadingPurchaseReturnList, setLoadingPurchaseReturnList] = useState(true);
	const [error, setError] = useState(false);
	const [newReturnData, setNewReturnData] = useState(true);
	const [search, setSearch] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState('all');

	let urlString;

	if (search) {
		urlString = `/inventory/purchase_return/list?business_branch=${business_branch_id}&search=${search}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/inventory/purchase_return/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseReturnList(response.data.data);
				setLoadingPurchaseReturnList(false);
				setNewReturnData(false)
			} catch (e) {
				setError(true);
				setLoadingPurchaseReturnList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseReturnList, newReturnData, urlString]);
	return { purchaseReturnList, isLoadingPurchaseReturnList, error, setNewReturnData, setSearch, setSupplierFilter };
};


const usePurchaseRequisitionList = (pageNumber, business_branch_id) => {
    const [purchaseRequisitionList, setPurchaseRequisitionList] = useState([]);
	const [isLoadingPurchaseRequisitionList, setLoadingPurchaseRequisitionList] = useState(true);
	const [error, setError] = useState(false);
	const [newPurchaseRequisitionData, setNewPurchaseRequisitionData] = useState(true);
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (search) {
		urlString = `/inventory/purchase_requisition/list?business_branch=${business_branch_id}&search=${search}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/purchase_requisition/list?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseRequisitionList(response.data.data);
				setLoadingPurchaseRequisitionList(false);
				setNewPurchaseRequisitionData(false)
			} catch (e) {
				setError(true);
				setLoadingPurchaseRequisitionList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseRequisitionList, newPurchaseRequisitionData, urlString]);
	return { purchaseRequisitionList, isLoadingPurchaseRequisitionList, error, setNewPurchaseRequisitionData, setSearch, setFilter };
};


const useStockList = (pageNumber, business_branch_id) => {
    const [stockList, setStockList] = useState([]);
	const [isLoadingStockList, setLoadingStockList] = useState(true);
	const [error, setError] = useState(false);
	const [search, setSearch] = useState(false);
	const [searchFilter, setSearchFilter] = useState('all');

	let urlString;

	if (search) {
		urlString = `/inventory/stock/list?business_branch=${business_branch_id}&search=${search}&category_filter=${searchFilter}`;
	} else {
		urlString = `/inventory/stock/list?business_branch=${business_branch_id}&category_filter=${searchFilter}`;
	}


	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setStockList(response.data.data);
				setLoadingStockList(false);
			} catch (e) {
				setError(true);
				setLoadingStockList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingStockList, urlString ]);
	return { stockList, isLoadingStockList, error, setSearchFilter, setSearch };
};


const useOutOfStockList = (pageNumber, business_branch_id) => {
  const [outOfStockList, setOutOfStockList] = useState([]);
  const [isLoadingOutOfStockList, setLoadingOutOfStockList] = useState(true);
  const [error, setError] = useState(false);
  const [searchFilter, setSearchFilter] = useState("all");

  let urlString = `/inventory/stock/out_of_stock?business_branch=${business_branch_id}&searchFilter=${searchFilter}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(urlString);
        setOutOfStockList(response.data.data);
        setLoadingOutOfStockList(false);
      } catch (e) {
        setError(true);
        setLoadingOutOfStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingOutOfStockList, urlString]);
  return { outOfStockList, isLoadingOutOfStockList, error, setSearchFilter };
};


const useNearlyOutOfStockList = (pageNumber, business_branch_id) => {
  const [nearlyOutOfStockList, setNearlyOutOfStockList] = useState([]);
  const [isLoadingNearlyOutOfStockList, setLoadingNearlyOutOfStockList] = useState(true);
  const [error, setError] = useState(false);
  const [searchFilter, setSearchFilter] = useState('all');

  let urlString = `/inventory/stock/near_out_of_stock?business_branch=${business_branch_id}&searchFilter=${searchFilter}`
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(urlString);
        setNearlyOutOfStockList(response.data.data);
        setLoadingNearlyOutOfStockList(false);
      } catch (e) {
        setError(true);
        setLoadingNearlyOutOfStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingNearlyOutOfStockList, urlString]);
  return { nearlyOutOfStockList, isLoadingNearlyOutOfStockList, error, setSearchFilter };
};


const useExpiredStockList = (pageNumber, business_branch_id) => {
  const [expiredStockList, setExpiredStockList] = useState([]);
  const [isLoadingExpiredStockList, setLoadingExpiredStockList] = useState(true);
  const [error, setError] = useState(false);
  const [searchFilter, setSearchFilter] = useState("all");

  let urlString = `/inventory/stock/expired_stock?business_branch=${business_branch_id}&searchFilter=${searchFilter}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(urlString);
        setExpiredStockList(response.data.data);
        setLoadingExpiredStockList(false);
      } catch (e) {
        setError(true);
        setLoadingExpiredStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingExpiredStockList, urlString]);
  return { expiredStockList, isLoadingExpiredStockList, error, setSearchFilter };
};

const useNearExpiryStockList = (pageNumber, business_branch_id) => {
  const [nearExpiryStockList, setNearExpiryStockList] = useState([]);
  const [isLoadingNearExpiryStockList, setLoadingNearExpiryStockList] = useState(true);
  const [error, setError] = useState(false);
  const [newPeriod, setNewPeriod] = useState("TODAY");
  const [expiryStartDate, setExpiryStartDate] = useState(1);

  let urlString

  if (newPeriod==="TODAY") {
    urlString = `/inventory/stock/near_expiry_stock?business_branch=${business_branch_id}&period=${newPeriod}`;
  } else {
    urlString = `/inventory/stock/near_expiry_stock?business_branch=${business_branch_id}&period=${newPeriod}&expiry_start_date=${expiryStartDate}`;
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(urlString);
        setNearExpiryStockList(response.data.data);
        setLoadingNearExpiryStockList(false);
      } catch (e) {
        setError(true);
        setLoadingNearExpiryStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingNearExpiryStockList, urlString]);
  return { nearExpiryStockList, isLoadingNearExpiryStockList, error, setNewPeriod, setExpiryStartDate };
};


const useProductLogList = (pageNumber, business_branch_id, product) => {
    const [productLogList, setProductLogList] = useState([]);
	const [isLoadingProductLogList, setLoadingProductLogList] = useState(true);
	const [error, setError] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});
	const [action, setAction] = useState("all");

	let urlString;

	if (product==='') {
		urlString = `/inventory/stock_log/list?business_branch=${business_branch_id}&action=${action}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/stock_log/list?business_branch=${business_branch_id}&action=${action}&product=${product}&start=${filter.start}&end=${filter.end}`;
  }

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setProductLogList(response.data.data);
				setLoadingProductLogList(false);
			} catch (e) {
				setError(true);
				setLoadingProductLogList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingProductLogList, urlString]);
	return { productLogList, isLoadingProductLogList, error, setAction, setFilter };
};


const usePurchaseOrderList = (pageNumber, business_branch_id) => {
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
	const [isLoadingPurchaseOrderList, setLoadingPurchaseOrderList] = useState(true);
	const [errorPurchaseOrders, setErrorPurchaseOrders] = useState(false);
	const [searchPurchaseOrder, setSearchPurchaseOrder] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState("all");

	let urlString;

	if (searchPurchaseOrder) {
    urlString = `/inventory/purchase_order/list?business_branch=${business_branch_id}&search=${searchPurchaseOrder}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/inventory/purchase_order/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseOrderList(response.data.data);
				setLoadingPurchaseOrderList(false);
			} catch (e) {
				setErrorPurchaseOrders(true);
				setLoadingPurchaseOrderList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseOrderList, urlString]);
	return { purchaseOrderList, isLoadingPurchaseOrderList, errorPurchaseOrders, setSearchPurchaseOrder, setSupplierFilter };
};


const useAdjustmentsList = (pageNumber, business_branch_id) => {
    const [adjustmentList, setAdjustmentList] = useState([]);
	const [isLoadingAdjustmentList, setLoadingAdjustmentList] = useState(true);
	const [errorAdjustmentList, setErrorAdjustmentList] = useState(false);
	const [filter, setFilter] =  useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/stock_adjustment/list?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`);
				setAdjustmentList(response.data.data);
				setLoadingAdjustmentList(false);
			} catch (e) {
				setErrorAdjustmentList(true);
				setLoadingAdjustmentList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingAdjustmentList, filter ]);
	return { adjustmentList, isLoadingAdjustmentList, errorAdjustmentList, setFilter };
};



const useStockTransferList = (pageNumber, transfer, business_branch_id) => {
    const [stockTransferList, setStockTransferList] = useState([]);
	const [isLoadingStockTransferList, setLoadingStockTransferList] = useState(true);
	const [error, setError] = useState(false);
	const [newStockTransferData, setNewStockTransferData] = useState(true);
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (search) {
		urlString = `/inventory/stock_transfer/list?business_branch=${business_branch_id}&transfer=${transfer}&search=${search}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/stock_transfer/list?business_branch=${business_branch_id}&transfer=${transfer}&start=${filter.start}&end=${filter.end}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setStockTransferList(response.data.data);
				setLoadingStockTransferList(false);
				setNewStockTransferData(false)
			} catch (e) {
				setError(true);
				setLoadingStockTransferList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingStockTransferList, newStockTransferData, urlString]);
	return { stockTransferList, isLoadingStockTransferList, error, setNewStockTransferData, setSearch, setFilter };
};


// ######################################### SINGLE RECORD CALLS ####################################################


const useGetItem = (id) => {
    const [products, setProducts] = useState("");
	const [isLoadingProducts, setLoadingProducts] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/products/get/${id}`);
				setProducts(response.data.data);
				setLoadingProducts(false);
			} catch (e) {
				setError(true);
				setLoadingProducts(false);
			}
		};
		fetchData();
	}, [id,isLoadingProducts]);
	return { products, isLoadingProducts, error };
};



const useGetItemType = (id) => {
    const [itemType, setItemType] = useState("");
	const [isLoadingItemType, setLoadingItemType] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/product_categories/get/${id}`);
				setItemType(response.data.data);
				setLoadingItemType(false);
			} catch (e) {
				setError(true);
				setLoadingItemType(false);
			}
		};
		fetchData();
	}, [id,isLoadingItemType]);
	return { itemType, isLoadingItemType, error };
};


const useGetItemUnit = (id) => {
    const [itemUnit, setItemUnit] = useState("");
	const [isLoadingitemUnit, setLoadingitemUnit] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/business_product_units/get/${id}`);
				setItemUnit(response.data.data);
				setLoadingitemUnit(false);
			} catch (e) {
				setError(true);
				setLoadingitemUnit(false);
			}
		};
		fetchData();
	}, [id,isLoadingitemUnit]);
	return { itemUnit, isLoadingitemUnit, error };
};


const useGetUnitsForGivenItem = (id) => {
    const [unitsForGivenItemList, setUnitsForGivenItemList] = useState("");
	const [isLoadingUnitsForGivenItemList, setLoadingUnitsForGivenItemList] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/products/unit_list/${id}`);
				setUnitsForGivenItemList(response.data.data);
				setLoadingUnitsForGivenItemList(false);
			} catch (e) {
				setError(true);
				setLoadingUnitsForGivenItemList(false);
			}
		};
		fetchData();
	}, [id,isLoadingUnitsForGivenItemList]);
	return { unitsForGivenItemList, isLoadingUnitsForGivenItemList, error };
};

const useGetBatchesForGivenItem = (id) => {
    const [batchList, setBatchList] = useState([]);
	const [isLoadingItemBatchList, setLoadingItemBatchList] = useState(true);
	const [batchesForGivenItemError, setBatchesForGivenItemError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/products/batch_list?id=${id}`);
				setBatchList(response.data.data);
				setLoadingItemBatchList(false);
			} catch (e) {
				setBatchesForGivenItemError(true);
				setLoadingItemBatchList(false);
			}
		};
		fetchData();
	}, [id,isLoadingItemBatchList]);
	return { batchList, isLoadingItemBatchList, batchesForGivenItemError };
};


const useGetSupplier = (id) => {
    const [supplier, setSupplier] = useState("");
	const [isLoadingSupplier, setLoadingSupplier] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/suppliers/get/${id}`);
				setSupplier(response.data.data);
				setLoadingSupplier(false);
			} catch (e) {
				setError(true);
				setLoadingSupplier(false);
			}
		};
		fetchData();
	}, [id,isLoadingSupplier]);
	return { supplier, isLoadingSupplier, error };
};


const useGetPurchase = (id) => {
    const [purchase, setPurchase] = useState("");
	const [isLoadingPurchase, setLoadingPurchase] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/inventory/product_purchase/get/${id}`);
				setPurchase(response.data.data);
				setLoadingPurchase(false);
			} catch (e) {
				setError(true);
				setLoadingPurchase(false);
			}
		};
		fetchData();
	}, [id,isLoadingPurchase]);
	return { purchase, isLoadingPurchase, error };
};


export {useYearsList, useItemList, useItemUnitList, useItemTypeList, useSupplierList, usePurchaseList, useStockList,  useProductLogList, usePurchaseOrderList, useAdjustmentsList, useOutOfStockList, usePurchaseRequisitionList, usePurchaseReturnList, useExpiredStockList, useItemSubCategoryList, usePurchaseDraftList, useStockTransferList, useNearlyOutOfStockList, useNearExpiryStockList, useGetItem, useGetItemType, useGetItemUnit,  useGetPurchase, useGetSupplier, useGetUnitsForGivenItem, useGetBatchesForGivenItem}
