import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  MenuItem,
  Alert,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import { useState } from "react";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester, URL } from "api/pesabackend";
import { useDepartmentsList } from "api/hooks/humanResource";
import axios from "axios";

const EmployeePictureEditForm = (props) => {
  const {employeeId} = props;
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { departmentList } = useDepartmentsList(1, business_branch_id);
  const [isFailed, setIsFailed] = useState(false);

  const initialValuesItem = {
    id: employeeId,
    image: "",
  };

  const itemSchema = yup.object().shape({
    image: yup.string(),
  });

  const saveItem = async (values, onSubmitProps) => {
    const savedItemResponse = await axios.post(`${URL}/human_resource/employees/upload_image`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      props.setOpen(false);
      props.setNewEmployeeData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }

    if (savedItemResponse.data.status === 400) {
      // // props.setOpen(false);
      // props.setNewUserData(true);
      // onSubmitProps.resetForm();
      setIsFailed(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    let formData = new FormData();

    for (let value in values) {
      formData.append(value, values[value]);
    }

    saveItem(formData, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isFailed ? (
              <Alert sx={{ m: "15px" }} variant="filled" severity="error">
                Operation was not successful!
              </Alert>
            ) : null}

            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  setFieldValue("image", acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.image ? (
                      <p>Add New Employee Image Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.image.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EmployeePictureEditForm;
