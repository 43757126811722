import { Box, useMediaQuery, Tab, Tabs, Typography, Alert } from "@mui/material";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import UserGroupsTable from "../tables/userGroupsTable";
import UserTable from "../tables/usersTable";
import ShiftTable from "../tables/shiftTable";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UserMangementTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['userMangementTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "userMangementTab", tab: newValue })); 
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);
  // const { _id, picturePath } = useSelector((state) => state.user);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        container
        spacing={2}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
          }}
        >
          {isSuccess ? (
            <Alert variant="filled"  severity="success">Operation was successful!</Alert>
          ) : isFailed ? (
            <Alert variant="filled"  severity="error">Operation was not successful!</Alert>
          ) : null}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              fullWidth
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="USER GROUPS" {...a11yProps(0)} />
              <Tab label="USERS" {...a11yProps(1)} />
              <Tab label="SHIFTS" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={value} index={0}> <UserGroupsTable/> </TabPanel>
            <TabPanel value={value} index={1}> <UserTable setIsSuccess={setIsSuccess} /> </TabPanel>
            <TabPanel value={value} index={2}> <ShiftTable setIsSuccess={setIsSuccess} /></TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserMangementTab;