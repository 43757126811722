import {
  Box,Grid,Button,Paper,InputBase,MenuItem,TextField, useTheme} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { requester,  } from "api/pesabackend";
import FlexBetween from "components/FlexBetween";
import { useRenumerationTypesList, useDeductionTypesList, useAllowanceTypesList } from "api/hooks/humanResource";
import RenumerationTypeForm from "../forms/renumerationTypeCreateForm";
import DeductionTypeForm from "../forms/deductionTypeCreateForm";
import AllowanceTypeForm from "../forms/allowanceTypeCreateForm";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function PayrollSettings({ setIsSuccess }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const business_branch_id = useSelector((state) => state.business_branch_id);
    const {renumerationTypeList, setNewRenumerationTypeData} = useRenumerationTypesList(1, business_branch_id);
    const {allowanceTypeList, setNewAllowanceTypeData} = useAllowanceTypesList(1, business_branch_id);
    const {deductionTypeList, setNewDeductionTypeData} = useDeductionTypesList(1, business_branch_id);

    const [openRenumeration, setOpenRenumeration] = useState(false);
    const [openEditRenumeration, setOpenEditRenumeration] = useState(false);
    const [openDeleteRenumeration, setOpenDeleteRenumeration] = useState(false);
    const [editRenumerationData, setEditRenumerationData] = useState({ name: "" });
    const [clickedRenumeration, setClickedRenumeration] = useState("");

    const [openAllowance, setOpenAllowance] = useState(false);
    const [openEditAllowance, setOpenEditAllowance] = useState(false);
    const [openDeleteAllowance, setOpenDeleteAllowance] = useState(false);
    const [editAllowanceData, setEditAllowanceData] = useState({ name: "" });
    const [clickedAllowance, setClickedAllowance] = useState("");

    const [openDeduction, setOpenDeduction] = useState(false);
    const [openEditDeduction, setOpenEditDeduction] = useState(false);
    const [openDeleteDeduction, setOpenDeleteDeduction] = useState(false);
    const [editDeductionData, setEditDeductionData] = useState({ name: "" });
    const [clickedDeduction, setClickedDeduction] = useState("");

    const handleClickOpenRenumeration = async () => {
        setOpenRenumeration(true);
    }

    const handleClickCloseRenumeration = async () => {
        setOpenRenumeration(false);
    }

    const handleClickEditOpenRenumeration = async (e) => {
        const response = await requester.get(
        `/human_resource/renumeration/get/${e.target.value}`
        );
        if (response.status === 200) {
        setEditRenumerationData(response.data.data)
        setOpenEditRenumeration(true);
        }
    };

    const handleClickEditCloseRenumeration = () => {
        setOpenEditRenumeration(false);
    };

    const handleClickDeleteOpenRenumeration = async (e) => {
        const response = await requester.get(
        `/human_resource/renumeration/get/${e.target.value}`
        );
        if (response.status === 200) {
          setClickedRenumeration(response.data.data.id);
          setOpenDeleteRenumeration(true);
        
        }
    };

    const handleClickDeleteCloseRenumeration = () => {
        setOpenDeleteRenumeration(false);
    };

    const handleClickDeleteRenumeration = async () => {
      const response = await requester.post(`/human_resource/renumeration/delete/${clickedRenumeration}`);
      setIsSuccess(true)
      setNewRenumerationTypeData(true)
      setOpenDeleteRenumeration(false);
    };

    const handleClickOpenAllowance = async () => {
        setOpenAllowance(true);
    }

    const handleClickCloseAllowance = async () => {
        setOpenAllowance(false);
    }

    const handleClickEditOpenAllowance = async (e) => {
        const response = await requester.get(
        `/human_resource/allowance/get/${e.target.value}`
        );
        if (response.status === 200) {
        setEditAllowanceData(response.data.data)
        setOpenEditAllowance(true);
        }
    };

    const handleClickEditCloseAllowance = () => {
        setOpenEditAllowance(false);
    };

    const handleClickDeleteOpenAllowance = async (e) => {
        const response = await requester.get(
        `/human_resource/allowance/get/${e.target.value}`
        );
        if (response.status === 200) {
          setClickedAllowance(response.data.data.id);
          setOpenDeleteAllowance(true);
        
        }
    };

    const handleClickDeleteCloseAllowance = () => {
        setOpenDeleteAllowance(false);
    };

    const handleClickDeleteAllowance = async () => {
      const response = await requester.post(`/human_resource/allowance/delete/${clickedAllowance}`);
      setIsSuccess(true)
      setNewAllowanceTypeData(true)
      setOpenDeleteAllowance(false);
    };


    const handleClickOpenDeduction = async () => {
        setOpenDeduction(true);
    }

    const handleClickCloseDeduction = async () => {
        setOpenDeduction(false);
    }

    const handleClickEditOpenDeduction = async (e) => {
        const response = await requester.get(
        `/human_resource/deduction/get/${e.target.value}`
        );
        if (response.status === 200) {
        setEditDeductionData(response.data.data);
        setOpenEditDeduction(true);
        }
    };

    const handleClickEditCloseDeduction = () => {
        setOpenEditDeduction(false);
    };

    const handleClickDeleteOpenDeduction = async (e) => {
        const response = await requester.get(
        `/human_resource/deduction/get/${e.target.value}`
        );
        if (response.status === 200) {
          setClickedDeduction(response.data.data.id)
          setOpenDeleteDeduction(true);
        }
    };

    const handleClickDeleteCloseDeduction = () => {
        setOpenDeleteDeduction(false);
    };

    const handleClickDeleteDeduction = async () => {
      const response = await requester.post(`/human_resource/deduction/delete/${clickedDeduction}`);
      setIsSuccess(true)
      setNewDeductionTypeData(true);
      setOpenDeleteDeduction(false);
    };





    return (
      <Box>
        <Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                {/* <TableHead>
                    <StyledTableRow>
                    <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 150 }}>Date Added</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Invoice Number</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 150 }}>Invoice Date</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Grand Total</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Discount</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Supplier</StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                        Actions
                    </StyledTableCell>
                    </StyledTableRow>
                </TableHead> */}
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#C5C6C7",
                        padding: "6px 6px",
                        border: "1px solid",
                      }}
                      colSpan={12}
                    >
                      <FlexBetween>
                        <h3>RENUMERATION TYPES</h3>
                        <Button
                          variant="contained"
                          onClick={handleClickOpenRenumeration}
                        >
                          Add Renumeration Type
                        </Button>
                      </FlexBetween>
                      <FixedSizeDialog
                        dialogButtonName="Add Renumeration Type "
                        dialogHeading="Add Renumeration Type"
                        open={openRenumeration}
                        handleClickOpen={handleClickOpenRenumeration}
                        handleClose={handleClickCloseRenumeration}
                        negativeResponse="Cancel"
                        maxWidth={"md"}
                        content={
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <RenumerationTypeForm
                              formType="create"
                              setOpen={setOpenRenumeration}
                              setNewRenumerationTypeData={
                                setNewRenumerationTypeData
                              }
                              setIsSuccess={setIsSuccess}
                              editData={editRenumerationData}
                            />
                          </Box>
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <Box width={"100%"} padding={"5px"} margin={"5px"}>
                        <TableContainer sx={{ maxHeight: "100vh" }}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            maxHeight="100vh"
                          >
                            <TableBody>
                              {renumerationTypeList.length === 0 ? (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={12}>
                                    <h3>
                                      No Data found. Please Add a Renumeration
                                      Type
                                    </h3>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                renumerationTypeList.map((row, index) => {
                                  return (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {index + 1}.
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {row.renumeration_name}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickEditOpenRenumeration(e)
                                          }
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickDeleteOpenRenumeration(e)
                                          }
                                        >
                                          Delete
                                        </Button>
                                        <FixedSizeDialog
                                          dialogButtonName="Edit Renumeration Type "
                                          dialogHeading="Edit Renumeration Type"
                                          open={openEditRenumeration}
                                          handleClickOpen={
                                            handleClickEditOpenRenumeration
                                          }
                                          handleClose={
                                            handleClickEditCloseRenumeration
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <RenumerationTypeForm
                                                formType="edit"
                                                setOpen={
                                                  setOpenEditRenumeration
                                                }
                                                setNewRenumerationTypeData={
                                                  setNewRenumerationTypeData
                                                }
                                                setIsSuccess={setIsSuccess}
                                                editData={editRenumerationData}
                                              />
                                            </Box>
                                          }
                                        />
                                        <FixedSizeDialog
                                          dialogButtonName="Delete Renumeration Type "
                                          dialogHeading="Are you sure you want to delete?"
                                          open={openDeleteRenumeration}
                                          handleClickOpen={
                                            handleClickDeleteOpenRenumeration
                                          }
                                          handleClose={
                                            handleClickDeleteCloseRenumeration
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                onClick={
                                                  handleClickDeleteRenumeration
                                                }
                                                sx={{
                                                  m: "1rem 0",
                                                  p: "1rem",
                                                }}
                                              >
                                                Yes, Delete
                                              </Button>
                                            </Box>
                                          }
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#C5C6C7",
                        padding: "6px 6px",
                        border: "1px solid",
                      }}
                      colSpan={12}
                    >
                      <FlexBetween>
                        <h3>ALLOWANCE TYPES</h3>
                        <Button
                          variant="contained"
                          onClick={handleClickOpenAllowance}
                        >
                          Add Allowance Type
                        </Button>
                      </FlexBetween>
                      <FixedSizeDialog
                        dialogButtonName="Add Allowance Type "
                        dialogHeading="Add Allowance Type"
                        open={openAllowance}
                        handleClickOpen={handleClickOpenAllowance}
                        handleClose={handleClickCloseAllowance}
                        negativeResponse="Cancel"
                        maxWidth={"md"}
                        content={
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <AllowanceTypeForm
                              formType="create"
                              setOpen={setOpenAllowance}
                              setNewAllowanceTypeData={
                                setNewAllowanceTypeData
                              }
                              setIsSuccess={setIsSuccess}
                              editData={editAllowanceData}
                            />
                          </Box>
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <Box width={"100%"} padding={"5px"} margin={"5px"}>
                        <TableContainer sx={{ maxHeight: "100vh" }}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            maxHeight="100vh"
                          >
                            <TableBody>
                              {allowanceTypeList.length === 0 ? (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={12}>
                                    <h3>
                                      No Data found. Please Add a Allowance
                                      Type
                                    </h3>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                allowanceTypeList.map((row, index) => {
                                  return (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {index + 1}.
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {row.allowance_name}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickEditOpenAllowance(e)
                                          }
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickDeleteOpenAllowance(e)
                                          }
                                        >
                                          Delete
                                        </Button>
                                        <FixedSizeDialog
                                          dialogButtonName="Edit Allowance Type "
                                          dialogHeading="Edit Allowance Type"
                                          open={openEditAllowance}
                                          handleClickOpen={
                                            handleClickEditOpenAllowance
                                          }
                                          handleClose={
                                            handleClickEditCloseAllowance
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <AllowanceTypeForm
                                                formType="edit"
                                                setOpen={
                                                  setOpenEditAllowance
                                                }
                                                setNewAllowanceTypeData={
                                                  setNewAllowanceTypeData
                                                }
                                                setIsSuccess={setIsSuccess}
                                                editData={editAllowanceData}
                                              />
                                            </Box>
                                          }
                                        />
                                        <FixedSizeDialog
                                          dialogButtonName="Delete Allowance Type "
                                          dialogHeading="Are you sure you want to delete?"
                                          open={openDeleteAllowance}
                                          handleClickOpen={
                                            handleClickDeleteOpenAllowance
                                          }
                                          handleClose={
                                            handleClickDeleteCloseAllowance
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                onClick={
                                                  handleClickDeleteAllowance
                                                }
                                                sx={{
                                                  m: "1rem 0",
                                                  p: "1rem",
                                                }}
                                              >
                                                Yes, Delete
                                              </Button>
                                            </Box>
                                          }
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#C5C6C7",
                        padding: "6px 6px",
                        border: "1px solid",
                      }}
                      colSpan={12}
                    >
                      <FlexBetween>
                        <h3>DEDUCTION TYPES</h3>
                        <Button
                          variant="contained"
                          onClick={handleClickOpenDeduction}
                        >
                          Add Deduction Type
                        </Button>
                      </FlexBetween>
                      <FixedSizeDialog
                        dialogButtonName="Add Deduction Type "
                        dialogHeading="Add Deduction Type"
                        open={openDeduction}
                        handleClickOpen={handleClickOpenDeduction}
                        handleClose={handleClickCloseDeduction}
                        negativeResponse="Cancel"
                        maxWidth={"md"}
                        content={
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <DeductionTypeForm
                              formType="create"
                              setOpen={setOpenDeduction}
                              setNewDeductionTypeData={setNewDeductionTypeData}
                              setIsSuccess={setIsSuccess}
                              editData={editDeductionData}
                            />
                          </Box>
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <Box width={"100%"} padding={"5px"} margin={"5px"}>
                        <TableContainer sx={{ maxHeight: "100vh" }}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            maxHeight="100vh"
                          >
                            <TableBody>
                              {deductionTypeList.length === 0 ? (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={3}>
                                    <h3>
                                      No Data found. Please Add a Deduction Type
                                    </h3>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                deductionTypeList.map((row, index) => {
                                  return (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {index + 1}.
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        {row.deduction_name}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 0px" }}>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickEditOpenDeduction(e)
                                          }
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          value={row.id}
                                          size="small"
                                          onClick={(e) =>
                                            handleClickDeleteOpenDeduction(e)
                                          }
                                        >
                                          Delete
                                        </Button>
                                        <FixedSizeDialog
                                          dialogButtonName="Edit Deduction Type "
                                          dialogHeading="Edit Deduction Type"
                                          open={openEditDeduction}
                                          handleClickOpen={
                                            handleClickEditOpenDeduction
                                          }
                                          handleClose={
                                            handleClickEditCloseDeduction
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <DeductionTypeForm
                                                formType="edit"
                                                setOpen={setOpenEditDeduction}
                                                setNewDeductionTypeData={
                                                  setNewDeductionTypeData
                                                }
                                                setIsSuccess={setIsSuccess}
                                                editData={editDeductionData}
                                              />
                                            </Box>
                                          }
                                        />
                                        <FixedSizeDialog
                                          dialogButtonName="Delete Deduction Type "
                                          dialogHeading="Are you sure you want to delete?"
                                          open={openDeleteDeduction}
                                          handleClickOpen={
                                            handleClickDeleteOpenDeduction
                                          }
                                          handleClose={
                                            handleClickDeleteCloseDeduction
                                          }
                                          negativeResponse="Cancel"
                                          maxWidth={"md"}
                                          content={
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={
                                                theme.palette.background.alt
                                              }
                                            >
                                              <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                onClick={
                                                  handleClickDeleteDeduction
                                                }
                                                sx={{
                                                  m: "1rem 0",
                                                  p: "1rem",
                                                }}
                                              >
                                                Yes, Delete
                                              </Button>
                                            </Box>
                                          }
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    );
}
