import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import CustomerForm from "../forms/customerForm";
import FormDialog from "components/modal";
import requester from "api/pesabackend";
import { setRefreshCount } from "state";

import { useCustomerList } from "api/hooks/customer";


export default function CustomersTable () {
  
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {customerList, isLoadingCustomerList, errorCustomerList} = useCustomerList(1, business_branch_id)

  const [firstName, setfirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const handleChangeFirstName = (e) => {
    setfirstName(e.target.value)
  }

  const handleChangeLastName = (e) => {
    setLastName(e.target.value)
  }

  const handleChangeEmailAddress = (e) => {
    setEmail(e.target.value)
  }

  const handleChangeAddress = (e) => {
    setAddress(e.target.value)
  }

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setModal("");
    setOpen(false);
  };

  const saveCustomer = async () => {
    setIsSubmittingCustomer(true)
    const submitData = {
      "related_business": 1,
      "related_business_branch": business_branch_id,
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "address": address,
      "phone_number": phoneNumber,
    }
    try {
      const response = await requester.post("/customers/add", submitData)
      setIsSubmittingCustomer(false)
      dispatch(setRefreshCount())
      setOpen(false)
    }catch (err){
      setIsSubmittingCustomer(false)
    }
  }

  const renderStatusPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INACTIVE" size="medium" color="warning" />;
			case 1:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="ACTIVE" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };


  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h3>Customers</h3>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <InputBase
            placeholder="Search by Account Name"
            value={searchContent}
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
          />
          {/* <IconButton onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton> */}

        <Button
          variant="contained"
          onClick={handleClickOpen}
        >
          CREATE CUSTOMER
        </Button>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
        {isLoadingCustomerList ? (
            <DummyTable />
        ) : (
          <>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>First Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Last Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Email</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Phone Number</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Status</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {customerList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  customerList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.related_user.first_name}</StyledTableCell>
                          <StyledTableCell>{row.related_user.last_name}</StyledTableCell>
                          <StyledTableCell>{row.related_user.email}</StyledTableCell>
                          <StyledTableCell>{row.related_user.phone_number}</StyledTableCell>
                          <StyledTableCell>{renderStatusPills(row.status)}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 100 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => navigate("/chartNextAccounts", { state: { id: row.id }})}
                            >
                              View Details
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={customerList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
        )}
        </Paper>

        <FormDialog
          open={open}
          handleClose={handleClose}
          enableActions={true}
          title={"Add New Customer"}
          buttonText="Create New Customer" 
          handleSubmit={saveCustomer}
          isSubmitting={isSubmittingCustomer}
          isSubmittingText={"Creating Customer, Please wait..."}
          textStatus={true}
          text={`Please note that this action is going to create a new ledger called ${firstName} ${lastName} under Accounts Receivable`}
          content={
            <CustomerForm
              handleChangeFirstName={handleChangeFirstName}
              handleChangeLastName={handleChangeLastName}
              handleChangeEmailAddress={handleChangeEmailAddress}
              handleChangeAddress={handleChangeAddress}
              handleChangePhoneNumber={handleChangePhoneNumber}
            />
          }
          />
      </Box>
    </Box>
  );
};