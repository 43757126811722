import {Box,Button,TextField,useMediaQuery, MenuItem} from "@mui/material";
import {useState} from "react";

const ShiftDetailForm = (props) => {
  const {
    index,
    data,
    handleRemoveShiftDetailRow,
    name,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [assignmentPeriod, setAssignmentPeriod] = useState("DAY");
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(10, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 8" },
        }}
      >
        <>
          <TextField
            label="Employee"
            onBlur={handleBlur}
            size="small"
            select
            onChange={handleChange}
            value={data.related_employee}
            name={`${name}.related_employee`}
            error={
              Boolean(touched.related_employee) &&
              Boolean(errors.related_employee)
            }
            helperText={touched.related_employee && errors.related_employee}
            sx={{ gridColumn: "span 3" }}
          >
            {props.employeeList.map((row, index) => {
              return (
                <MenuItem
                  value={row.id}
                >
                  {row.first_name} {row.last_name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            label="Shift Assigned"
            size="small"
            select
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.related_shift}
            name={`${name}.related_shift`}
            error={
              Boolean(
                touched?.shifts &&
                  touched?.shifts[index]?.related_shift
              ) &&
              Boolean(
                errors?.shifts &&
                  errors?.shifts[index]?.related_shift
              )
            }
            helperText={
              touched?.shifts &&
              touched?.shifts[index]?.related_shift &&
              errors?.shifts &&
              errors?.shifts[index]?.related_shift
            }
            sx={{ gridColumn: "span 2" }}
          >
            {props.shiftList.map((row, index) => {
              return (
                <MenuItem
                  value={row.id}
                >
                  {row.shift_name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            label="Assignment Period"
            onBlur={handleBlur}
            select
            size="small"
            defaultValue={"DAY"}
            onChange={(e) => {
              handleChange(e);
              setAssignmentPeriod(e.target.value);
            }}
            value={data.assignment_period}
            name={`${name}.assignment_period`}
            error={
              Boolean(touched.assignment_period) &&
              Boolean(errors.assignment_period)
            }
            helperText={touched.assignment_period && errors.assignment_period}
            sx={{ gridColumn: "span 2" }}
          >
            <MenuItem value="DAY">DAY</MenuItem>
            <MenuItem value="WEEK">WEEK</MenuItem>
            <MenuItem value="MONTH">MONTH</MenuItem>
          </TextField>

          {assignmentPeriod === "DAY" ? (
            <TextField
              label="Period Assigned"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              type="date"
              value={data.period_assigned}
              name={`${name}.period_assigned`}
              error={
                Boolean(touched.period_assigned) &&
                Boolean(errors.period_assigned)
              }
              helperText={touched.period_assigned && errors.period_assigned}
              sx={{ gridColumn: "span 2" }}
            />
          ) : assignmentPeriod === "WEEK" ? (
            <TextField
              label="Period Assigned"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              type="week"
              value={data.period_assigned}
              name={`${name}.period_assigned`}
              error={
                Boolean(touched.period_assigned) &&
                Boolean(errors.period_assigned)
              }
              helperText={touched.period_assigned && errors.period_assigned}
              sx={{ gridColumn: "span 2" }}
            />
          ) : assignmentPeriod === "MONTH" ? (
            <TextField
              label="Period Assigned"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              type="month"
              value={data.period_assigned}
              name={`${name}.period_assigned`}
              error={
                Boolean(touched.period_assigned) &&
                Boolean(errors.period_assigned)
              }
              helperText={touched.period_assigned && errors.period_assigned}
              sx={{ gridColumn: "span 2" }}
            />
          ) : null}

          {index > 0 ? (
            <Button
              value={index}
              onClick={() => {
                handleRemoveShiftDetailRow(index);
              }}
              size="small"
              variant="contained"
              sx={{
                width: "20%",
              }}
            >
              Remove
            </Button>
          ) : null}
        </>
      </Box>
    </Box>
  );
};

export default ShiftDetailForm;
