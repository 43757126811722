import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  MenuItem,
  useTheme,
  Alert,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
} from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEmployeesList } from "api/hooks/humanResource";
import { requester } from "api/pesabackend";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import RenumerationDetailForm from "./renumerationDetail";
import DeductionDetailForm from "./deductionDetailForm";
import AllowanceDetailForm from "./allowanceDetail";

const payslipSchema = yup.object().shape({
  related_employee: yup.number().required("required"),
  total_renumeration: yup.number().required("required"),
  total_deduction: yup.number().required("required"),
  total_allowance: yup.number().required("required"),
  payslip_for: yup.string().required("required"),
  payment_date: yup.date().required("required"),
  renumeration_detail: yup.array().of(
    yup.object().shape({
      related_renumeration_type: yup.number().required("required"),
      amount: yup.number().required("required"),
    })
  ),
  allowance_detail: yup.array().of(
    yup.object().shape({
      related_allowance_type: yup.number().required("required"),
      amount: yup.number().required("required"),
    })
  ),
  deduction_detail: yup.array().of(
    yup.object().shape({
      related_deduction_type: yup.number().required("required"),
      amount: yup.number().required("required"),
    })
  ),
});

const EditPayslipForm = (props) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const location = useLocation();
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { employeeList } = useEmployeesList(1, business_branch_id);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const [employeeSelect, setEmployeeSelect] = useState(true);
  const [dateSelect, setDateSelect] = useState(moment().format("YYYY-MM-DD"));

  

 
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesPayslip = {
    related_employee: location.state.related_employee.id,
    total_renumeration: location.state.total_renumeration,
    total_deduction: location.state.total_deduction,
    total_allowance: location.state.total_allowance,
    payment_date: moment(location.state.payment_date).format("YYYY-MM-DD"),
    payslip_for: location.state.payslip_for,
    renumeration_detail: location.state.renumeration_detail,
    allowance_detail: location.state.allowance_detail,
    deduction_detail: location.state.deduction_detail,
  };


  const [totalAllowance, setTotalAllowance] = useState(initialValuesPayslip.total_allowance);
  const [totalDeduction, setTotalDeduction] = useState(initialValuesPayslip.total_deduction);
  const [totalRenumeration, setTotalRenumeration] = useState(initialValuesPayslip.total_renumeration);

 

  const [allowanceDetailFormData, setAllowanceDetailFormData] = useState(initialValuesPayslip.allowance_detail);

  const [deductionDetailFormData, setDeductionDetailFormData] = useState(initialValuesPayslip.deduction_detail);

  const [renumerationDetailFormData, setRenumerationDetailFormData] = useState(initialValuesPayslip.renumeration_detail);



  const [overallTotal, setOverallTotal] = useState(
    Number(
      Number(totalRenumeration) +
        Number(totalAllowance) -
        Number(totalDeduction)
    )
  );

  const savePayslip = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post(
      `/human_resource/payslip/edit/${location.state.id}`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/humanResource");
      }, 1500);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };

  const handleTotalDeduction = (newArray) => {
    const totalDeductionCalculation = newArray.reduce((total, newArray) => {
      return Number(total) + Number(newArray.amount);
    }, 0);
    setTotalDeduction(totalDeductionCalculation);
    handleOveralTotalChange(
      totalRenumeration,
      totalAllowance,
      totalDeductionCalculation
    );
  };

  const handleTotalAllowance = (newArray) => {
    const totalAllowanceCalculation = newArray.reduce((total, newArray) => {
      return Number(total) + Number(newArray.amount);
    }, 0);
    setTotalAllowance(totalAllowanceCalculation);
    handleOveralTotalChange(
      totalRenumeration,
      totalAllowanceCalculation,
      totalDeduction
    );
  };

  const handleTotalRenumeration = (newArray) => {
    const totalRenumerationCalculation = newArray.reduce((total, newArray) => {
      return Number(total) + Number(newArray.amount);
    }, 0);
    setTotalRenumeration(totalRenumerationCalculation);
    handleOveralTotalChange(
      totalRenumerationCalculation,
      totalAllowance,
      totalDeduction
    );
  };

  const handleDeductionAmountChange = (event, index) => {
    const { value } = event.target;
    const newArray = deductionDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: Number(value) };
      }
      return item;
    });
    setDeductionDetailFormData(newArray);
    handleTotalDeduction(newArray);
  };

  const handleRenumerationAmountChange = (event, index) => {
    const { value } = event.target;
    const newArray = renumerationDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: Number(value) };
      }
      return item;
    });
    setRenumerationDetailFormData(newArray);
    handleTotalRenumeration(newArray);
  };

  const handleAllowanceAmountChange = (event, index) => {
    const { value } = event.target;
    const newArray = allowanceDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: Number(value) };
      }
      return item;
    });
    setAllowanceDetailFormData(newArray);
    handleTotalAllowance(newArray);
  };

  const handleOveralTotalChange = (
    totalRenumeration,
    totalAllowance,
    totalDeduction
  ) => {
    const grandTotalCalculation = Number(
      Number(totalRenumeration) +
        Number(totalAllowance) -
        Number(totalDeduction)
    );
    setOverallTotal(grandTotalCalculation);
  };

  const handleAddRenumerationDetailSubmitData = () => {
    const oneRow = {
      related_renumeration_type: "",
      amount: 0,
    };
    setRenumerationDetailFormData([...renumerationDetailFormData, oneRow]);
  };

  const handleAddAllowanceDetailSubmitData = () => {
    const oneRow = {
      related_allowance_type: "",
      amount: 0,
    };
    setAllowanceDetailFormData([...allowanceDetailFormData, oneRow]);
  };

  const handleAddDeductionDetailSubmitData = () => {
    const oneRow = {
      related_deduction_type: "",
      amount: 0,
    };
    setDeductionDetailFormData([...deductionDetailFormData, oneRow]);
  };

  const handleRemoveAllowanceDetailRow = (index) => {
    let newFormData = allowanceDetailFormData;
    newFormData.splice(index, 1);
    setAllowanceDetailFormData(newFormData);
    handleTotalAllowance(newFormData);
  };
  const handleRemoveDeductionDetailRow = (index) => {
    let newFormData = deductionDetailFormData;
    newFormData.splice(index, 1);
    setDeductionDetailFormData(newFormData);
    handleTotalDeduction(newFormData);
  };
  const handleRemoveRenumerationDetailRow = (index) => {
    let newFormData = renumerationDetailFormData;
    newFormData.splice(index, 1);
    setRenumerationDetailFormData(newFormData);
    handleTotalRenumeration(newFormData);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePayslip(values, onSubmitProps);
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Payslip Invoice recorded successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/humanResource")}
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Human Resource
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Create Payslip Form</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValuesPayslip}
                validationSchema={payslipSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "45%",
                          }}
                        >
                          <label>Payment Date: </label>
                          <TextField
                            label=""
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setDateSelect(e.target.value);
                            }}
                            value={values.payment_date}
                            name="payment_date"
                            type="date"
                            error={
                              Boolean(touched.payment_date) &&
                              Boolean(errors.payment_date)
                            }
                            helperText={
                              touched.payment_date && errors.payment_date
                            }
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                        <TextField
                          label="Selected Employee"
                          disabled
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.related_employee}
                          name="related_employee"
                          select
                          error={
                            Boolean(touched.related_employee) &&
                            Boolean(errors.related_employee)
                          }
                          helperText={
                            touched.related_employee && errors.related_employee
                          }
                          sx={{
                            width: "45%",
                            margin: " 20px 10px",
                          }}
                        >
                          {employeeList.map((row, index) => {
                            return (
                              <MenuItem key={index} value={row.id}>
                                {row.first_name} {row.last_name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Box>

                      <TextField
                        label="Payment For"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        value={values.payslip_for}
                        name="payslip_for"
                        error={
                          Boolean(touched.payslip_for) &&
                          Boolean(errors.payslip_for)
                        }
                        helperText={touched.payslip_for && errors.payslip_for}
                        sx={{
                          width: "95%",
                          margin: "20px",
                        }}
                      />
                    </Box>
                    {employeeSelect ? (
                      <>
                        <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          my="10px"
                        >
                          <h5>Input the Renumeration here</h5>

                          <FieldArray
                            name="renumeration_detail"
                            render={(arrayHelpers) => {
                              const renumeration_detail =
                                values.renumeration_detail;

                              return (
                                <>
                                  {renumeration_detail.map((row, index) => {
                                    return (
                                      <RenumerationDetailForm
                                        key={index}
                                        index={index}
                                        data={row}
                                        handleRemoveProductDetailRow={(
                                          index
                                        ) => {
                                          arrayHelpers.remove(index);
                                          handleRemoveRenumerationDetailRow(
                                            index
                                          );
                                        }}
                                        handleItemAmountChange={
                                          handleRenumerationAmountChange
                                        }
                                        handleChange={handleChange}
                                        errors={arrayHelpers.form.errors}
                                        touched={arrayHelpers.form.touched}
                                        name={`renumeration_detail[${index}]`}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        businessBranchId={business_branch_id}
                                      />
                                    );
                                  })}

                                  <Button
                                    onClick={() => {
                                      arrayHelpers.push({
                                        related_renumeration_type: "",
                                        amount: 0,
                                      });
                                      handleAddRenumerationDetailSubmitData();
                                    }}
                                    sx={{
                                      m: "1rem 0",
                                    }}
                                    size="small"
                                    variant="contained"
                                  >
                                    Add Renumeration
                                  </Button>
                                </>
                              );
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            gridColumn="span 4"
                            width={"49%"}
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            my="10px"
                          >
                            <h5>Input the Allowance here</h5>

                            <FieldArray
                              name="allowance_detail"
                              render={(arrayHelpers) => {
                                const allowance_detail =
                                  values.allowance_detail;

                                return (
                                  <>
                                    {allowance_detail.map((row, index) => {
                                      return (
                                        <AllowanceDetailForm
                                          key={index}
                                          index={index}
                                          data={row}
                                          handleRemoveProductDetailRow={(
                                            index
                                          ) => {
                                            arrayHelpers.remove(index);
                                            handleRemoveAllowanceDetailRow(
                                              index
                                            );
                                          }}
                                          handleItemAmountChange={
                                            handleAllowanceAmountChange
                                          }
                                          handleChange={handleChange}
                                          errors={arrayHelpers.form.errors}
                                          touched={arrayHelpers.form.touched}
                                          name={`allowance_detail[${index}]`}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          businessBranchId={business_branch_id}
                                        />
                                      );
                                    })}

                                    <Button
                                      onClick={() => {
                                        arrayHelpers.push({
                                          related_allowance_type: "",
                                          amount: 0,
                                        });
                                        handleAddAllowanceDetailSubmitData();
                                      }}
                                      sx={{
                                        m: "1rem 0",
                                      }}
                                      size="small"
                                      variant="contained"
                                    >
                                      Add Allowance
                                    </Button>
                                  </>
                                );
                              }}
                            />
                          </Box>
                          <Box
                            gridColumn="span 4"
                            width={"49%"}
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            my="10px"
                          >
                            <h5>Input the Deduction here</h5>

                            <FieldArray
                              name="deduction_detail"
                              render={(arrayHelpers) => {
                                const deduction_detail =
                                  values.deduction_detail;

                                return (
                                  <>
                                    {deduction_detail.map((row, index) => {
                                      return (
                                        <DeductionDetailForm
                                          key={index}
                                          index={index}
                                          data={row}
                                          handleRemoveProductDetailRow={(
                                            index
                                          ) => {
                                            arrayHelpers.remove(index);
                                            handleRemoveDeductionDetailRow(
                                              index
                                            );
                                          }}
                                          handleItemAmountChange={
                                            handleDeductionAmountChange
                                          }
                                          handleChange={handleChange}
                                          errors={arrayHelpers.form.errors}
                                          touched={arrayHelpers.form.touched}
                                          name={`deduction_detail[${index}]`}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          businessBranchId={business_branch_id}
                                        />
                                      );
                                    })}

                                    <Button
                                      onClick={() => {
                                        arrayHelpers.push({
                                          related_deduction_type: "",
                                          amount: 0,
                                        });
                                        handleAddDeductionDetailSubmitData();
                                      }}
                                      sx={{
                                        m: "1rem 0",
                                      }}
                                      size="small"
                                      variant="contained"
                                    >
                                      Add Deduction
                                    </Button>
                                  </>
                                );
                              }}
                            />
                          </Box>
                        </Box>

                        <Box
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Renumeration: </label>
                            <TextField
                              label=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                (values.total_renumeration = totalRenumeration)
                              }
                              name="total_renumeration"
                              disabled
                              size="small"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              error={
                                Boolean(touched.total_renumeration) &&
                                Boolean(errors.total_renumeration)
                              }
                              helperText={
                                touched.total_renumeration &&
                                errors.total_renumeration
                              }
                              sx={{
                                // width: "100%",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Allowances: </label>
                            <TextField
                              label=""
                              disabled
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={(values.total_allowance = totalAllowance)}
                              name="total_allowance"
                              type="number"
                              size="small"
                              error={
                                Boolean(touched.total_allowance) &&
                                Boolean(errors.total_allowance)
                              }
                              helperText={
                                touched.total_allowance &&
                                errors.total_allowance
                              }
                              inputProps={{
                                min: 0,
                              }}
                              sx={{
                                gridColumn: "span 1",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Deductions: </label>
                            <TextField
                              label=""
                              disabled
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={(values.total_deduction = totalDeduction)}
                              name="total_deduction"
                              type="number"
                              size="small"
                              error={
                                Boolean(touched.total_deduction) &&
                                Boolean(errors.total_deduction)
                              }
                              helperText={
                                touched.total_deduction &&
                                errors.total_deduction
                              }
                              inputProps={{
                                min: 0,
                              }}
                              sx={{
                                gridColumn: "span 1",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Overall Net Pay: </label>
                            <TextField
                              label=""
                              disabled
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={(values.overall_total = overallTotal)}
                              name="overall_total"
                              type="number"
                              size="small"
                              error={
                                Boolean(touched.overall_total) &&
                                Boolean(errors.overall_total)
                              }
                              helperText={
                                touched.overall_total && errors.overall_total
                              }
                              inputProps={{
                                min: 0,
                              }}
                              sx={{
                                gridColumn: "span 1",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <h3
                        style={{
                          textAlign: "center",
                          color: "red",
                          margin: "40px 0px",
                        }}
                      >
                        {" "}
                        PLEASE SELECT THE EMPLOYEE YOU WANT TO MAKE A PAYSLIP
                        FOR
                      </h3>
                    )}

                    {/* BUTTONS */}
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Save Payslip
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          navigate(`/humanResource`);
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default EditPayslipForm;
