import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import {  PdfRegular,  PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    marginVertical: 10,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#3AB9EA",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const OutOfStockPdf = ({data, viewData, printedBy}) => {

  const report_detail = viewData.report_detail;

  const reportFields = [
    { title: "No", width: "3" },
    { title: "Item Name", width: "20" },
    { title: "Description", width: "25" },
    { title: "Item Unit", width: "17" },
    { title: "Reorder Level", width: "17" },
    { title: "Current Stock", width: "18" },
  ];

  
  const ReportTable = ({ headerFixed = false, reportFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {reportFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      {report_detail?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <PdfBorderView key={index} style={{   width: reportFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: reportFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.product_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: reportFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.description}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{ width: reportFields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.unit}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: reportFields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.reorder_level}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: reportFields[5]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.available_stock}</PdfSmall>
          </PdfBorderView>
        </View>
      ))}
    </View>
  ); 




  return (
    <Document title={`Out of Stock Report for ${moment().format("LLL")}`}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed>
          {/* <PdfHeading>JOLILABS PHARMACY</PdfHeading> */}
        </PdfHeaderView>
        <PdfSubHeaderView fixed>
          <PdfSmall>+256 761 780 800/ +256 704 912 888</PdfSmall>
          <PdfSmall>jolilabspharmacy@gmail.com</PdfSmall>
          <PdfSmall>www.jolilabspharmacy.com</PdfSmall>
          <PdfSmall>Located in Mukono Town Next to Bank of Baroda.</PdfSmall>
        </PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <PdfSubSubHeading>OUT OF STOCK REPORT</PdfSubSubHeading>
        </PdfView>

        {/* DATE */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <PdfSubSubSubHeading>As of: </PdfSubSubSubHeading>
          <PdfRegular>{moment().format("LLL")}</PdfRegular>
        </PdfView>

        
        {/* TABLES */}
        <ReportTable headerFixed reportFields={reportFields} />
        
        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>Here to make you feel better</PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>Printed By: {printedBy} {moment().format("LLL")}</PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default OutOfStockPdf;