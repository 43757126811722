import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import FixedSizeDialog from "components/fixedSizeDialog";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useShiftsList } from "api/hooks/humanResource";
import ShiftCreateForm from "../forms/shiftCreateForm";
import ShiftEditForm from "../forms/shiftEditForm";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function ShiftTable({ setIsSuccess }) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState("");
  const [clickedItem, setClickedItem] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const {shiftList, setNewShiftData, isLoadingShiftList} = useShiftsList(1, business_branch_id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEditOpen = async (e) => {
    const response = await requester.get(
    `/human_resource/shifts/get/${e.target.value}`
    );
    if (response.status === 200) {
      setClickedItem(e.target.value);
      setEditData(response.data.data);
      setOpenEdit(true);
    }
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    const response = await requester.get(
    `/human_resource/shifts/get/${e.target.value}`
    );
    if (response.status === 200) {
      setClickedItem(e.target.value);
      setOpenDelete(true);
    }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/human_resource/shifts/delete?id=${clickedItem}`);
    setIsSuccess(true);
    setNewShiftData(true);
    setOpenDelete(false);
  };


  return (
    <Box>
      <h4>Shifts</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            
          </Grid>
          <Grid xs></Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Add New Shift
            </Button>
            <FixedSizeDialog
              dialogButtonName="Create Shift "
              dialogHeading="Create a New Shift"
              open={open}
              maxWidth={"md"}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <ShiftCreateForm setIsSuccess={setIsSuccess} setOpen={setOpen} setNewShiftData={setNewShiftData}/>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Shift Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>From</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>To</StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {shiftList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  shiftList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow 
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>{row.shift_name}</StyledTableCell>
                          <StyledTableCell>{row.start_time}</StyledTableCell>
                          <StyledTableCell>{row.end_time}</StyledTableCell>

                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickEditOpen(e)}
                            >
                              Edit
                            </Button>

                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Delete
                            </Button>

                            {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                            <FixedSizeDialog
                              dialogHeading="Edit"
                              open={openEdit}
                              maxWidth={"md"}
                              handleClose={handleClickEditClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {
                                    <ShiftEditForm
                                      setOpen={setOpenEdit}
                                      setNewShiftData={setNewShiftData}
                                      id={clickedItem}
                                      editData={editData}
                                      setIsSuccess={setIsSuccess}
                                    />
                                  }
                                </Box>
                              }
                            />

                            <ResponsiveDialog
                              dialogHeading="Are you sure you want to delete?"
                              open={openDelete}
                              handleClose={handleClickDeleteClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {
                                    <Button
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      onClick={handleClickDelete}
                                      sx={{
                                        m: "1rem 0",
                                        p: "1rem",
                                      }}
                                    >
                                      Yes, Delete
                                    </Button>
                                  }
                                </Box>
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={shiftList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
