import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import ColumnGroupingTable from "components/normalTable";
import ItemUnitForm from "../forms/itemUnitForm";
import { useItemUnitList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import { requester } from "api/pesabackend";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const columns = [
      { id: 'id', label: 'No.', minWidth: 100 },
      { id: 'name', label: 'Unit Name', minWidth: 170 },
      
    ];
    

export default function ItemUnits ({setIsSuccess}) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {unitList, isLoadingItemUnitList, setNewItemUnitData, setSearch} = useItemUnitList(1, business_branch_id)
  
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({name:""})
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };

  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/business_product_units/get/${e.target.value}`);
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/business_product_units/get/${e.target.value}`);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/inventory/business_product_units/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewItemUnitData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };
  
    return (
      <Box>
          <h4>Item Unit List</h4>
          <Box
            p="1rem"
            m ="1rem"
            borderRadius="0.5rem"
            
          >
            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs >
                <Paper
                  sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
                >
                  <InputBase placeholder="Search Unit Name" 
                    value={searchContent}
                    onChange={handleSearchInput}
                    sx={{ ml: 1, flex: 1 }}
                    
                  />
                  <IconButton
                    onClick={handleClickSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid xs >
                
                
              </Grid>
              <Grid xs sx={{ textAlign:'right' }} >
                <Button variant="contained" onClick={handleClickOpen}>Add Item Unit</Button>
                <ResponsiveDialog
                dialogButtonName = "Add Item Unit"
                dialogHeading = "Add a New Item Unit"
                open = {open}
                handleClickOpen = {handleClickOpen}
                handleClose = {handleClose}
                negativeResponse = "Cancel"
                content = {
                            <Box
                              p="1rem"
                              borderRadius="0.5rem"
                              backgroundColor={theme.palette.background.alt}
                            >
                              <ItemUnitForm 
                                formType = "create" setOpen={setOpen} setNewItemUnitData={setNewItemUnitData} setIsSuccess={setIsSuccess} editData={editData}
                              />
                            </Box>
                          }
                >
                  
                </ResponsiveDialog>
              </Grid>
            </Grid>

          </Box>
       
        <Box>
          <ColumnGroupingTable
              columnData = {columns}
              tableData = {unitList}
              deleteButton = {true}
              editButton = {true}
              handleClickEditOpen={handleClickEditOpen}
              handleClickEditClose={handleClickEditClose}
              handleClickDeleteOpen={handleClickDeleteOpen}
              handleClickDeleteClose={handleClickDeleteClose}
              openEdit={openEdit}
              openDelete={openDelete}
              editContent={<ItemUnitForm formType="edit" id={clickedItem} editData={editData} setOpen={setOpenEdit} setNewItemUnitData={setNewItemUnitData} setIsSuccess={setIsSuccess}/>}
              deleteContent={<Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                onClick={
                                  handleClickDelete
                                }
                                sx={{
                                  m: "1rem 0",
                                  p: "1rem",
                                }}
                              >
                                Yes, Delete
                              </Button>}
          >
          </ColumnGroupingTable>
        </Box>
      </Box>
    );
};