import {Box, Paper, Button} from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useRef } from "react";
// import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TableFooter from "@mui/material/TableFooter";
// import ReactToPrint from "react-to-print";
import requester from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import StockTranferPdf from "./stockTransferPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

const StockTransferViewForm = (props) => {

  // const [isSidebar, setIsSidebar] = useState(true);
  const user = useSelector((state) => state.user);
  const { viewData } = props;
  // const location = useLocation();
  const componentRef = useRef();

  const transfer_number = viewData.id;
  const detail= viewData.detail;
  const grand_total= viewData.grand_total;
  const transfer_date= viewData.transfer_date;
  const transferred_from= viewData.related_business_branch;
  const transferred_to = viewData.transferred_to;
  const products_transferred=viewData.products_transferred;
  const status = viewData.status

  const handleStockTransferConfirm = async ()=>{
    const response = await requester.post(`/inventory/stock_transfer/confirm/${viewData.id}`);
    if (response.status === 201) {
      props.setIsSuccess(true);
      props.setNewStockTransferData(true);
      props.setOpenView(false);
    }
    if (response.status === 400) {
      props.setIsFailed(true);
      props.setOpenView(false);
    }
  }


  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>STOCK TRANSFER FORM</h1>
            <h2># {transfer_number}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Transferred From
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {transferred_from.branch_name}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address: {transferred_from.branch_location}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {transferred_from.branch_contact}
              </p>

              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Transfer Date
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(transfer_date).format("LL")}
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                Transferred To
              </h4>
              <p style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                {transferred_to.branch_name}
              </p>
              <p style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                Address: {transferred_to.branch_location}
              </p>
              <p style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                Phone: {transferred_to.branch_contact}
              </p>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow  sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Unit
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Batch
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Expiry Date
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products_transferred ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products_transferred.map((row, index) => {
                    return (
                      <StyledTableRow  key={index}>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.related_product.product_name}
                          {row.related_product.description}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {
                            row.related_product_unit
                              .related_business_product_unit.name
                          }
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.batch}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.expiry_date}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {withoutSymbolFormatter(parseFloat(row.amount))}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{currencyFormatter(parseFloat(grand_total))}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {grand_total}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Transfer Details
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              {detail}
            </p>
          </Box>
          <hr />
        </Box>
      </Paper>
      {/*<ReactToPrint
        documentTitle={`Stock Transfer Form #${transfer_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              my: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<StockTranferPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Stock Transfer Record #${transfer_number}`}>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: "20%",
            my: "15px",
            mx: "5px",
          }}
        >
          Download
        </Button>
      </PDFDownloadLink>
      {status === 0 ? (
        <>
          <Button
            size="small"
            variant="contained"
            onClick={handleStockTransferConfirm}
            sx={{
              width: "20%",
              my: "15px",
              mx: "5px",
            }}
            color="success"
          >
            CONFIRM TRANSFER
          </Button>
          <p>
            *If the stock details are acceptable please click the CONFIRM
            TRANSFER button to record the stock transfer
          </p>
        </>
      ) : null}
    </>
  );

}

export default StockTransferViewForm;