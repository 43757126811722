import React, {useState, useEffect} from "react";
import {Box, Button, TextField, useTheme, Alert, Breadcrumbs, Link, Typography, Paper, Divider, Card, CardContent, Stack} from "@mui/material";
import Chip from "@mui/material/Chip";
import FormDialog from "components/modal";
import Grid from "@mui/material/Unstable_Grid2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { FlexRowCenter, FlexCenter, FlexStart, FlexBetween, FlexEnd } from "components/FlexBox";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import InvoiceDetailsTable from "../tables/invoiceDetailsTable";
import requester from "api/pesabackend";
import moment from "moment/moment";


const detailsText = {
	fontSize: "16px",
	fontWeight: 600,
	padding: "5px"
}

const descriptionText = {
	fontSize: "16px",
	fontWeight: 400,
}

const facilityIcons = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#52524e",
}


const ReceiptDetailsView = (props) => {
  const { palette } = useTheme();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token =  useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([])
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [loadingInvoiceDetails, setLoadingInvoiceDetails] = useState(true)

  useEffect(() =>{
    fetchInvoiceDetails()
      
  }, []);

  const fetchInvoiceDetails = async () => {
		try {
			const response = await requester.get(`/billing/invoices/details?id=${6}`);
			setInvoiceDetails(response.data.data);
			setLoadingInvoiceDetails(false);
		} catch (e) {
			setLoadingInvoiceDetails(false);
		}
	} 

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="UNPAID" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="PAID" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};
  
  return (
    <Box>
      <Navbar />
      <FlexRowCenter>
        <Box sx={{ flexGrow: 1}}>
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box sx={{ flexGrow: 999, paddingX: "40px"}}>

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/purchase")}
            >
              <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Sales
            </Link>
            <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
              <b>Sales Invoice Details</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
          <Grid container spacing={4}>
            <Grid xs={12} sm={8}>
              <Stack spacing={3} sx={{ marginBottom: "20px" }}>
                <FlexBetween>
                  <Typography variant="h4">Invoice Details - {invoiceDetails.invoice_number}</Typography>
                </FlexBetween>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                  <Grid container spacing={2} marginBottom={"15px"}>
                    <Grid xs={12} sm={6}>

                      <Stack spacing={2}>
                        <FlexCenter>
                          {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Invoice Number: {invoiceDetails.invoice_number}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                        <FlexCenter>
                          {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Invoice Description: {invoiceDetails.invoice_description}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                        <FlexCenter>
                          {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Order Type: {invoiceDetails.order_type}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={6}>

                      <Stack spacing={2}>
                        <FlexCenter>
                          {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Total Amount: UGX {invoiceDetails.total_amount}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                        <FlexCenter>
                          {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Total Discount: {invoiceDetails.total_discount}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                        <FlexCenter>
                          {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Status: <span>renderStatusPills(invoiceDetails.status)</span></Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                        <FlexCenter>
                          {/* <ShowerOutlinedIcon sx={facilityIcons} /> */}
                          <Typography sx={descriptionText}>Created By: {invoiceDetails.invoice_number}</Typography>
                          <Typography sx={detailsText}></Typography>
                        </FlexCenter>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Typography variant="h5">Products</Typography>
                  <Grid container spacing={2} marginTop={"10px"}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <InvoiceDetailsTable products={invoiceDetails.products} />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            <Grid sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={4}>
              <Card >
                <CardContent sx={{ padding: 0 }}>
                  <FlexStart sx={{ padding: "16px" }}>
                  {/* <LandLordAvatar /> */}
                    <Box sx={{ marginLeft: "8px" }}>
                      <Typography variant="body1" sx={{ fontSize: "17px" }}>
                        Notes
                      </Typography>
                    </Box>
                  </FlexStart>
                  <Divider />
                  <Stack spacing={3} sx={{ padding: "16px" }}>
                    
                  </Stack>
                </CardContent>
              </Card>
              <Grid sx={{marginTop:"30px"}}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px"}}>Create Receipt</Button>
              </Grid>
            </Grid>
          </Grid>
          <FormDialog
          open={open}
          // handleClose={handleClose}
          enableActions={true}
          content={<></>}
          title={"Create Receipt"}
          // handleSubmit={handleRentalEditFormSubmit}
          buttonText="submit"
        />
            
          </Paper>
        </Box>
      </FlexRowCenter>
    </Box>
  );
};

export default ReceiptDetailsView;
