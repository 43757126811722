
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme, Alert, Breadcrumbs, Link, Typography, Paper} from "@mui/material"; 
import ReceiptIcon from "@mui/icons-material/Receipt";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PurchaseOrderDetailForm from "./purchaseOrderDetailForm";
import { useSupplierList } from "api/hooks/inventory";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment";
import { requester } from "api/pesabackend";


const purchaseOrderSchema = yup.object().shape({
  detail: yup.string().nullable(),
  fob: yup.string().nullable(),
  ship_via: yup.string().nullable(),
  terms: yup.string().nullable(),
  grand_total: yup.number().required("required"),
  delivery_date: yup.date().required("required"),
  related_supplier: yup.number().required("required"),
  products_ordered: yup.array()
  .of(
    yup.object().shape({
      related_product: yup.number().required("required"),
      related_product_unit: yup.number().required("required"),
      quantity: yup.number().required("required"),
      amount: yup.number().required("required"),
      
    })
  )
  .required('Please add a purchase order record items')
  .min(1, 'At least one purchase order must be added'),
});


const EditPurchaseOrderForm = (props) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const location = useLocation();
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const {supplierList} = useSupplierList(1, business_branch_id);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token =  useSelector((state) => state.token);

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  const initialValuesPurchaseOrder = {
    detail: location.state.detail,
    fob: location.state.fob,
    terms: location.state.terms,
    ship_via: location.state.ship_via,
    grand_total: location.state.grand_total,
    delivery_date: location.state.delivery_date,
    related_supplier: location.state.related_supplier.id,
    products_ordered:location.state.products_ordered
  };
  
  const[purchaseDetailFormData, setPurchaseDetailFormData] = useState(initialValuesPurchaseOrder.products_ordered);
  
  const [grandTotal,setGrandTotal] = useState(initialValuesPurchaseOrder.grand_total);
  

  const savePurchase = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedItemResponse = await requester.post(
      `/inventory/purchase_order/edit/${location.state.id}`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/purchase");
      }, 1500);
      
    };

    if (savedItemResponse.data.status===400) {
      setIsFailed(true)
      setIsFailedError(savedItemResponse.data.errors)
    };
  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.amount)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };

  const handleItemAmountChange = (event, index) =>{
    const {value} = event.target;
    const newArray = purchaseDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, amount: Number(value)}
        }
        return item
    })
    setPurchaseDetailFormData(newArray);
    handleGrandTotal(newArray);
  };


  const handleAddProductDetailSubmitData = () => {
    const oneRow = {quantity: '', amount: '', data_id: uuidv4()};
    setPurchaseDetailFormData([...purchaseDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = purchaseDetailFormData
    newFormData.splice(index,1);
    setPurchaseDetailFormData(newFormData);
    handleGrandTotal(newFormData);      
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePurchase(values, onSubmitProps);
  };
  
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Purchase Order created successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/purchase")}
            >
              <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Purchase Orders
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Edit Purchase Order Form</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValuesPurchaseOrder}
                validationSchema={purchaseOrderSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label>Expected Delivery Date: </label>
                          <TextField
                            label=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.delivery_date}
                            name="delivery_date"
                            type="date"
                            error={
                              Boolean(touched.delivery_date) &&
                              Boolean(errors.delivery_date)
                            }
                            helperText={
                              touched.delivery_date && errors.delivery_date
                            }
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                        <TextField
                          label="Select Supplier"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.related_supplier}
                          name="related_supplier"
                          select
                          error={
                            Boolean(touched.related_supplier) &&
                            Boolean(errors.related_supplier)
                          }
                          helperText={
                            touched.related_supplier && errors.related_supplier
                          }
                          sx={{
                            width: "100%",
                            marginBottom: "15px",
                          }}
                        >
                          {supplierList.map((supplierRecord, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={supplierRecord.id}
                                selected={
                                  supplierRecord.id === values.related_supplier
                                    ? true
                                    : false
                                }
                              >
                                {supplierRecord.name}{" "}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        <TextField
                          label="F.O.B."
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fob}
                          name="fob"
                          error={Boolean(touched.fob) && Boolean(errors.fob)}
                          helperText={touched.fob && errors.fob}
                          sx={{
                            width: "100%",
                            marginBottom: "15px",
                          }}
                        />
                        <TextField
                          label="Ship Via"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ship_via}
                          name="ship_via"
                          error={
                            Boolean(touched.ship_via) &&
                            Boolean(errors.ship_via)
                          }
                          helperText={touched.ship_via && errors.ship_via}
                          sx={{
                            width: "100%",
                            marginBottom: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <TextField
                          label="Terms"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.terms}
                          name="terms"
                          error={
                            Boolean(touched.terms) && Boolean(errors.terms)
                          }
                          helperText={touched.terms && errors.terms}
                          sx={{
                            width: "100%",
                            marginTop: "15px",
                          }}
                        />
                        <TextField
                          label="Comments or Special Instructions"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          multiline
                          rows={6}
                          value={values.detail}
                          name="detail"
                          error={
                            Boolean(touched.detail) && Boolean(errors.detail)
                          }
                          helperText={touched.detail && errors.detail}
                          sx={{
                            width: "100%",
                            marginTop: "15px",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      gridColumn="span 4"
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                    >
                      <h5>Input the products to be ordered here</h5>

                      <FieldArray
                        name="products_ordered"
                        render={(arrayHelpers) => {
                          const products_ordered = values.products_ordered;

                          return (
                            <>
                              {products_ordered.map((row, index) => {
                                return (
                                  <PurchaseOrderDetailForm
                                    key={row.data_id}
                                    index={index}
                                    data={row}
                                    handleRemoveProductDetailRow={(index) => {
                                      arrayHelpers.remove(index);
                                      handleRemoveProductDetailRow(index);
                                    }}
                                    handleItemAmountChange={
                                      handleItemAmountChange
                                    }
                                    handleChange={handleChange}
                                    errors={arrayHelpers.form.errors}
                                    touched={arrayHelpers.form.touched}
                                    name={`products_ordered[${index}]`}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                  />
                                );
                              })}

                              <Button
                                onClick={() => {
                                  arrayHelpers.push({
                                    related_product: "",
                                    related_product_unit: "",
                                    quantity: 1,
                                    amount: 0,
                                    data_id: uuidv4(),
                                  });
                                  handleAddProductDetailSubmitData();
                                }}
                                sx={{
                                  m: "1rem 0",
                                }}
                                size="small"
                                variant="contained"
                              >
                                Add Product
                              </Button>
                              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            </>
                          );
                        }}
                      />
                    </Box>

                    <Box
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mx: "2px",
                        }}
                      >
                        <label>Grand Total: </label>
                        <TextField
                          label=""
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={(values.grand_total = grandTotal)}
                          name="grand_total"
                          disabled
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          error={
                            Boolean(touched.grand_total) &&
                            Boolean(errors.grand_total)
                          }
                          helperText={touched.grand_total && errors.grand_total}
                          sx={{
                            // width: "100%",
                            marginBottom: "15px",
                          }}
                        />
                      </Box>
                    </Box>

                    {/* BUTTONS */}
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Save Purchase Order
                      </Button>

                      <Button
                        onClick={() => {
                          resetForm();
                          navigate(`/purchase`, { state: { startTab: 1 } });
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
  
  export default EditPurchaseOrderForm;
  