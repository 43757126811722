import { Box, useTheme, Grid, Button, Paper, InputBase, TextField, MenuItem } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import FixedSizeDialog from "components/fixedSizeDialog";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { useEmployeesList, useDepartmentsList } from "api/hooks/humanResource";
import EmployeeCreateForm from "../forms/employeeForms/employeeCreateForm";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { URL } from "api/pesabackend";

export default function EmployeeTable({ setIsSuccess }) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState("");
  const [clickedItem, setClickedItem] = useState("");
  const [searchContent, setSearchContent] = useState("");

  const {employeeList, isLoadingEmployeeList, errorEmployeeList, setSearchEmployees, setNewEmployeeData, setDepartmentFilter} = useEmployeesList(1, business_branch_id, token);
  const { departmentList } = useDepartmentsList(1, business_branch_id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickView= (e) =>{
    navigate("/employeeDetails", { state: {id: e.target.value} });
  }

  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value);
    const response = await axios.get(``);
    setEditData(response.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value);
    const response = await axios.get(``);
    setOpenDelete(true);
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {};

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };
  
  const handleChangeFilter = (e) => {
    setDepartmentFilter(e.target.value);
  }

  const handleClickSearch = async () => {
    setSearchEmployees(searchContent);
  };

  return (
    <Box>
      <h4>Employee List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Paper
              sx={{
                my: "4px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Employee Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m:"8px"
              }}
            >
              <h4>FILTER BY DEPARTMENT:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="DEPARTMENT"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="all">ALL</MenuItem>
                  {departmentList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.department_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Paper>
            </Box>
          </Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Add Employee
            </Button>
            <FixedSizeDialog
              dialogButtonName="Add Employee "
              dialogHeading="Add a New Employee"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              maxWidth={"md"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <EmployeeCreateForm
                    setIsSuccess={setIsSuccess}
                    setOpen={setOpen}
                    setNewEmployeeData={setNewEmployeeData}
                  />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Image</StyledTableCell>
                  <StyledTableCell >Phone Number</StyledTableCell>
                  <StyledTableCell >ID Number</StyledTableCell>
                  <StyledTableCell >Address</StyledTableCell>
                  <StyledTableCell >Department</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Next Of Kin Name</StyledTableCell>
                  <StyledTableCell >Next Of Kin Contact </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeeList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  employeeList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>
                            {row.first_name} {row.last_name}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "10px 10px" }}>
                            <img
                              alt="Image"
                              src={`${URL}${row.image}`}
                              style={{
                                display: "inline-block",
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>{row.phone_number}</StyledTableCell>
                          <StyledTableCell>{row.id_number}</StyledTableCell>
                          <StyledTableCell>{row.address}</StyledTableCell>
                          <StyledTableCell>
                            {row.related_department.department_name}{" "}
                          </StyledTableCell>
                          <StyledTableCell>{row.next_of_kin_name}</StyledTableCell>
                          <StyledTableCell>{row.next_of_kin_phone_number}</StyledTableCell>

                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View Details
                            </Button>

                            <ResponsiveDialog
                              dialogHeading="Edit"
                              open={openEdit}
                              handleClose={handleClickEditClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {/* {<EditItemForm setOpen={setOpenEdit} setNewDepartmentRoleData={setNewDepartmentRoleData} id={clickedItem} editData={editData} setIsSuccess={setIsSuccess} />} */}
                                </Box>
                              }
                            />

                            <ResponsiveDialog
                              dialogHeading="Are you sure you want to delete?"
                              open={openDelete}
                              handleClose={handleClickDeleteClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {
                                    <Button
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      onClick={handleClickDelete}
                                      sx={{
                                        m: "1rem 0",
                                        p: "1rem",
                                      }}
                                    >
                                      Yes, Delete
                                    </Button>
                                  }
                                </Box>
                              }
                            />

                            {/* <ResponsiveDialog
                                  dialogHeading = "Are you sure you want to delete?"
                                  open = {openView}
                                  handleClose = {handleClickViewClose}
                                  negativeResponse = "Cancel"
                                  content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      {ViewContent}
                                    </Box>
                                      
                                      }
                                /> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
