import { Box, useTheme, Button, Paper} from "@mui/material";
import FixedSizeDialog from "components/fixedSizeDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { useGetBusinessBranchList } from "api/hooks/business";
import BusinessBranchDetailEditForm from "../forms/businessBranchDetailsEdit";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import requester from "api/pesabackend";

export default function BusinessBranchTable({ setIsSuccess }) {
  const theme = useTheme();
  const business_id = useSelector((state) => state.business_id);
  const [openEdit, setOpenEdit] = useState(false);

  const [editData, setEditData] = useState("");
  const [clickedItem, setClickedItem] = useState("");
  const { businessBranchList, isLoadingBusinessBranchList, errorBusinessBranchList, setNewBusinessBranchData} = useGetBusinessBranchList(1, business_id)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickEditOpen = async (e) => {
    setClickedItem(e.target.value);
    const response = await requester.get(
      `/tenants/business_branch/get/${e.target.value}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Box>
      <h4>Business Branch List</h4>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Branch Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 170 }}>Contact</StyledTableCell>
                  <StyledTableCell >Location</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {businessBranchList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  businessBranchList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>{row.branch_name}</StyledTableCell>
                          <StyledTableCell>
                            {row.branch_contact}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.branch_location}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickEditOpen(e)}
                            >
                              Edit Details
                            </Button>

                            <FixedSizeDialog
                              dialogHeading="Edit Business Branch Details"
                              open={openEdit}
                              handleClose={handleClickEditClose}
                              negativeResponse="Cancel"
                              maxWidth={"md"}
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  <BusinessBranchDetailEditForm setOpen={setOpenEdit} setNewBusinessBranchData={setNewBusinessBranchData} id={clickedItem} editData={editData} setIsSuccess={setIsSuccess} />
                                </Box>
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={businessBranchList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
