import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  MenuItem,
  useTheme,
  Alert,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { requester } from "api/pesabackend";
import { useShiftsList } from "api/hooks/humanResource";

const shiftAssignmentSchema = yup.object().shape({
  related_shift: yup.number().required("required"),
  day_assigned: yup.date().required("required"),
});

const EditShiftAssignmentForm = (props) => {
  const { employeeData } = props;
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const { shiftList } = useShiftsList(1, business_branch_id);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValues = {
    related_shift: employeeData.related_shift.id,
    day_assigned: employeeData.day_assigned,
  };

  const saveRecord = async (values, onSubmitProps) => {

    const savedItemResponse = await requester.post(
      `/human_resource/employees/shifts/edit/${employeeData.id}`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewEmployeeShiftData(true);
      props.setOpen(false);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveRecord(values, onSubmitProps);
  };

  return (
    <Box>
      {isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={shiftAssignmentSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p>
                    <b>Shift Assignment for </b>{" "}
                    {employeeData.related_employee.first_name +
                      " " +
                      employeeData.related_employee.last_name}
                  </p>
                </Box>

                <Box
                  display="grid"
                  gap="30px"
                  p="0.5rem"
                  gridTemplateColumns="repeat(10, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 8",
                    },
                  }}
                >
                  <TextField
                    label="Shift Assigned"
                    size="small"
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.related_shift}
                    name="related_shift"
                    error={
                      Boolean(touched.related_shift) &&
                      Boolean(errors.related_shift)
                    }
                    helperText={touched.related_shift && errors.related_shift}
                    sx={{ gridColumn: "span 5" }}
                  >
                    {shiftList.map((row, index) => {
                      return (
                        <MenuItem value={row.id}>{row.shift_name}</MenuItem>
                      );
                    })}
                  </TextField>
                  <TextField
                    label="Day Assigned"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.day_assigned}
                    name="day_assigned"
                    error={
                      Boolean(touched.day_assigned) &&
                      Boolean(errors.day_assigned)
                    }
                    helperText={touched.day_assigned && errors.day_assigned}
                    sx={{ gridColumn: "span 5" }}
                  />
                </Box>
              </Box>

              {/* BUTTONS */}
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    m: "1rem",
                    p: "0.5rem",
                  }}
                >
                  Edit Shift Assignment
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditShiftAssignmentForm;
