import { Box, useMediaQuery, Tab, Tabs, Typography,  Alert  } from "@mui/material";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import StockTransferCreateTable from "../tables/stockTransferCreateTable";
import StockTransferRecieveTable from "../tables/stockTransferRecieveTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const StockTransferTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['stockTransferTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "stockTransferTab", tab: newValue })); 
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }} 
        container spacing={2}
      >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
             sx={{
              flexGrow: 999
            }}
          >
              {
                isSuccess ? ( 
                    <Alert  sx={{ m:"15px" }}variant="filled" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert  sx={{ m:"15px" }}severity="error">Operation was not successful!</Alert>
                ) : null                                             
              }
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label="Send To" {...a11yProps(0)} />
                  <Tab label="Receive From" {...a11yProps(1)} />
                </Tabs>
              </Box>
            
              <Box>
                  <TabPanel value={value} index={0}>
                    <StockTransferCreateTable setIsSuccess={setIsSuccess} setIsFailed={setIsFailed}/>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <StockTransferRecieveTable setIsSuccess={setIsSuccess}/>
                  </TabPanel>
              </Box>
          </Box>
      </Box>
    </Box> 
  );
};

export default StockTransferTab;