import React, { useState, useEffect } from "react";
import {Box, Button, TextField, Alert, Breadcrumbs, Link, Typography, Paper, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { FlexRowCenter, FlexRowAround, FlexCol, BorderedFlexCol, BorderedFlexRow, FlexJustifyStart } from "components/FlexBox";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import {v4 as uuidv4} from 'uuid';
import { requester } from "api/pesabackend";
import InvoiceDetailForm from "./invoiceDetailForm";
import InvoiceConfirmView from "../views/invoiceConfirmView";
import FormDialog from "components/modal";


const InvoiceForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const navigate = useNavigate();
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const [customer, setCustomer] = useState(0)
  const [hasCustomer, setHasCustomer] = useState(false)
  const [customers, setCustomers] = useState([])
  const [loadingCustomers, setLoadingCustomers] = useState(false)


  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    fetchCustomers()
    return () => clearTimeout(timer);

  }, [isSuccess, isFailed]);

  const fetchCustomers = async () => {
		try {
			const response = await requester.get(`/customers/list?search=False&business_branch=${business_branch_id}`);
			setCustomers(response.data.data);
			setLoadingCustomers(false);
		} catch (e) {
			setLoadingCustomers(false);
		}
	} 

  const handleChangeCustomer = (e) => {
    setCustomer(e.target.value)
  }

  const handleChangeHasCustomer = (e) => {
		setHasCustomer(e.target.checked);
  }

  const invoiceSchema = yup.object().shape({
    invoice_description: yup.string(),
    invoice_products: yup.array()
    .of(
      yup.object().shape({
        related_product: yup.number().required("required"),
        related_product_unit: yup.number().required("required"),
        batch: yup.string(),
        quantity: yup.number().required("required"),
        discount: yup.number().required("required"),
        })
    )
    .required('Please add atleast one invoice item')
    .min(1, 'At least one sale must be added'),
  });

  const invoiceFormInitialValues = {
    invoice_description: "",
    total_amount: 0,
    total_discount:0,
    invoice_products:[
      {
        related_product: '', 
        related_product_unit: '', 
        batch: '', 
        quantity: 0, 
        sale_price: '',  
        discount: 0, 
        total: 0,
        data_id: uuidv4(),
      }
    ]
  };

  const[invoiceDetailFormData, setInvoiceDetailFormData] = useState([{
    quantity: 1, sale_price: '', batch: '',  discount: 0, total: 0, product_name: "", product_unit: "", related_product: "", related_product_unit: "", data_id: uuidv4(),
  }]);

  const [totalDiscount, setTotalDiscount] = useState(invoiceFormInitialValues.total_discount);
  const [grandTotal, setGrandTotal] = useState(invoiceFormInitialValues.total_amount);

  const handleGrandTotal = (newArray) =>{
    //use floats instead of number
    const grandTotalCalculation = newArray.reduce((total, newArray)=>{
      return Number(total) + Number(newArray.total)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };

  const handleTotalDiscount = (newArray) =>{
    //use floats instead of number
    const totalDiscountCalculation = newArray.reduce((total, newArray)=>{
      return Number(total) + Number(newArray.discount)
    }, 0);
    setTotalDiscount(totalDiscountCalculation);
  };

  
  const handleItemAmountChange = (price, total, quantity, batch, product_name, product_unit, related_product, related_product_unit, index) =>{
    const newArray = invoiceDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, sale_price: parseFloat(price), total: parseFloat(total), quantity: parseFloat(quantity), batch: batch, product_unit: product_unit, product_name: product_name, related_product: related_product, related_product_unit: related_product_unit}
        }
        return item
    })
    setInvoiceDetailFormData(newArray);
    handleGrandTotal(newArray);
  };
 

  const handleItemDiscountChange = (event, index) =>{
  const {value} = event.target;
    const newArray = invoiceDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, discount: value}
        }
        return item
    })
    handleTotalDiscount(newArray);
    setInvoiceDetailFormData(newArray);
  };

    
  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
     quantity: 1, sale_price: '', discount: 0, batch: '', total:0, data_id: uuidv4()
    };
    setInvoiceDetailFormData([...invoiceDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = invoiceDetailFormData
    newFormData.splice(index,1);
    setInvoiceDetailFormData(newFormData);
    handleGrandTotal(newFormData);    
    handleTotalDiscount(newFormData);  
  };

  const handleClickOpen = () => {
		setOpen(true);
	};

  const handleClose = () => {
		// setModal("");
		setOpen(false);
	};

  const saveInvoice = async () => {
    setIsSubmittingInvoice(true)
    const submitData = {
      "related_business": business_id,
      "related_business_branch": business_branch_id,
      "total_amount": grandTotal,
      "total_discount": totalDiscount,
      "related_customer": customer,
      "invoice_products": invoiceDetailFormData
    }
    try {
      const response = await requester.post("/billing/invoices", submitData)
      setIsSubmittingInvoice(false)
      navigate("/billing")
    }catch (err){
      console.log(err)
      setIsSubmittingInvoice(false)
    }
  }
  
  return (
    <Box>
      <Navbar />
      <FlexRowCenter>
        <Box sx={{ flexGrow: 1}}>
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box sx={{ flexGrow: 999, paddingX: "40px"}}>
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Sales Invoice recorded successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/purchase")}
            >
              <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Sales
            </Link>
            <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
              <b>Record Sales Invoice</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                initialValues={invoiceFormInitialValues}
                validationSchema={invoiceSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form >
                    <FlexRowAround>
                      <FormControlLabel control={<Checkbox checked={hasCustomer} onChange={handleChangeHasCustomer} />} label="Add Customer to Invoice" />
                    
                      {hasCustomer && (
                      <FormControl fullWidth sx={{marginTop: 2}}>
                        <InputLabel id="demo-simple-select-label">Select a Customer</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category-select"
                            value={customer}
                            label="Select a Customer"
                            onChange={handleChangeCustomer}
                        >
                            {customers.map((value, index ) => {
                                return (
                                  <MenuItem key={index} value={value.id}>{`${value.related_user.first_name} ${value.related_user.last_name}`}</MenuItem>
                                )
                            })}
                        </Select>
                      </FormControl>
                    )}
                    </FlexRowAround>

                    <h4>Input the products to invoice below</h4>
                    <BorderedFlexCol>

                      <FieldArray
                        name="invoice_products"
                        render={(arrayHelpers) => {
                          const invoice_products = values.invoice_products;

                          return (
                            <>
                              {invoice_products.map((row, index) => {
                                return (
                                  <InvoiceDetailForm
                                    key={row.data_id}
                                    index={index}
                                    data={row}
                                    handleRemoveProductDetailRow={(index) => {
                                      arrayHelpers.remove(index);
                                      handleRemoveProductDetailRow(index);
                                    }}
                                    handleItemAmountChange={
                                      handleItemAmountChange
                                    }
                                    handleItemDiscountChange={
                                      handleItemDiscountChange
                                    }
                                    handleChange={handleChange}
                                    errors={arrayHelpers.form.errors}
                                    touched={arrayHelpers.form.touched}
                                    name={`invoice_products[${index}]`}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                  />
                                );
                              })}

                              <FlexJustifyStart>
                                <FlexRowAround>
                                  <Button size="small" variant="contained"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        related_product: "",
                                        related_product_unit: "",
                                        batch: "",
                                        quantity: 0,
                                        sale_price: "",
                                        discount: 0,
                                        total:0,
                                        data_id: uuidv4(),
                                      });
                                      handleAddProductDetailSubmitData();
                                    }}
                                    sx={{m: "1rem", p: "0.5rem"}}
                                  >
                                    Add Product
                                  </Button>

                                  <Button size="small" variant="contained" color="error"
                                  disabled
                                    sx={{m: "1rem", p: "0.5rem"}}
                                  >
                                    Remove All
                                  </Button>
                                </FlexRowAround>
                              </FlexJustifyStart>
                            </>
                          );
                        }}
                      />
                    </BorderedFlexCol>
                    
                    <BorderedFlexRow>
                      <FlexCol>
                        <label>Total Discount: </label>
                        <TextField
                          label=""
                          disabled
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={(values.discount = totalDiscount)}
                          name="discount"
                          type="number"
                          size="small"
                          error={
                            Boolean(touched.discount) &&
                            Boolean(errors.discount)
                          }
                          helperText={touched.discount && errors.discount}
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </FlexCol>
                      <FlexCol>
                        <label>Grand Total: </label>
                        <TextField
                          label=""
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={(values.grand_total = grandTotal)}
                          name="grand_total"
                          disabled
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          error={
                            Boolean(touched.grand_total) &&
                            Boolean(errors.grand_total)
                          }
                          helperText={touched.grand_total && errors.grand_total}
                        />
                      </FlexCol>
                    </BorderedFlexRow>

                    {/* BUTTONS */}
                    <BorderedFlexRow>
                      <Button variant="contained" color="error" size="small"
                        onClick={() => {
                          resetForm();
                          navigate(`/billing`);
                        }}
                        sx={{m: "1rem", p: "0.5rem"}}>
                        Cancel
                      </Button>
                      <Button onClick={handleClickOpen} variant="contained" size="small"
                        sx={{m: "1rem", p: "0.5rem",}}>
                        Create Invoice
                      </Button>
                    </BorderedFlexRow>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
          <FormDialog
							open={open}
							handleClose={handleClose}
							title={"Confirm Invoice Details"}
							handleSubmit={saveInvoice}
							// enableActions={true}
							buttonText="Confirm Invoice"
              isSubmitting={isSubmittingInvoice}
              isSubmittingText={"Submitting Invoice, Please Wait..."}
							content={
								<InvoiceConfirmView
                  invoiceDescription={"New Invoice"}
									invoiceDetails={invoiceDetailFormData}
                  total={grandTotal}
                  discount={totalDiscount}
								/>
							}
						/>
        </Box>
      </FlexRowCenter>
    </Box>
  );
};

export default InvoiceForm;
