
import {Box,Button,TextField,useMediaQuery,MenuItem,} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useAllowanceTypesList } from "api/hooks/humanResource";




const AllowanceDetailForm = (props) => {


    const {index, 
        data,
        handleRemoveProductDetailRow, 
        handleItemAmountChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        businessBranchId
        } = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    
    const {allowanceTypeList} = useAllowanceTypesList(1, businessBranchId)

    
    return (

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>  
                    
                    
                    <TextField
                        label="Allowance"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={data.related_allowance_type}
                        name={`${name}.related_allowance_type`}
                        error={Boolean( touched?.allowance_detail && touched?.allowance_detail[index]?.related_allowance_type) && Boolean(errors?.allowance_detail && errors?.allowance_detail[index]?.related_allowance_type)}
                        helperText={ touched?.allowance_detail && touched?.allowance_detail[index]?.related_allowance_type && errors?.allowance_detail && errors?.allowance_detail[index]?.related_allowance_type}
                        sx={{ gridColumn: "span 2" }}
                    >
                        {allowanceTypeList.map((oneUnit, index)=>{
                            return(
                                <MenuItem key={index} value= {oneUnit.id} selected={oneUnit.id===data.related_allowance_type ? true :false }>{oneUnit.allowance_name}</MenuItem>
                            );
                        })}
                        
                    </TextField>
                    
                    <TextField
                        label="Amount"
                        size="small"
                        type="number"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleItemAmountChange(e,index);
                        }}
                        value={data.amount}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.amount`}
                        error={Boolean( touched?.allowance_detail && touched?.allowance_detail[index]?.amount) && Boolean(errors?.allowance_detail && errors?.allowance_detail[index]?.amount)}
                        helperText={ touched?.allowance_detail && touched?.allowance_detail[index]?.amount && errors?.allowance_detail && errors?.allowance_detail[index]?.amount}
                        sx={{ gridColumn: "span 2" }}
                    />                                      
                    
                    {index > 0 ? 
                        <Button
                            value={index}
                            onClick={() => {
                                handleRemoveProductDetailRow(index);
                            }}                                                     
                            size="small"
                            variant="contained"
                            sx={{ 
                                width: "20%",
                            }}
                        >
                            Remove
                        </Button> : null
                    }
                </>
            </Box>
        </Box>               
                    
    );
  };
  
  export default AllowanceDetailForm;
  