import {
  Box,
  useTheme,
  Grid,
  Button,
  InputBase,
  Paper,
  MenuItem,
  TextField,
} from "@mui/material";
import ItemSubCategoryForm from "../forms/itemSubCategoryForm";
import ItemSubCategoryEditForm from "../forms/itemSubCategoryEditForm";
import ResponsiveDialog from "components/responsiveDialog";
import FixedSizeDialog from "components/fixedSizeDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useItemTypeList, useItemSubCategoryList } from "api/hooks/inventory";
import { requester } from "api/pesabackend";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function ItemSubCategories ({setIsSuccess}) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {subCategoryList, isLoadingItemSubCategoryList, setNewItemSubCategoryData, setSearch, setItemCategoryFilter} = useItemSubCategoryList(1, business_branch_id)
  const {typeList,isLoadingItemTypeList} = useItemTypeList(1, business_branch_id);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({category_name:""})
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/product_sub_categories/get/${e.target.value}`);
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await requester.get(`/inventory/product_sub_categories/get/${e.target.value}`);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/inventory/product_sub_categories/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewItemSubCategoryData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };

  const handleChangeFilter = async (e) => {
    setItemCategoryFilter(e.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <Box>
      <h4>Item Category List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Paper
              sx={{
                my: "4px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Sub-Category Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m:"8px"
              }}
            >
              <h4>FILTER BY PARENT CATEGORY:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="ITEM CATEGORIES"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {typeList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.category_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Paper>
            </Box>
          </Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Add Item Sub-Category
            </Button>
            <FixedSizeDialog
              dialogButtonName="Add Item Sub-Category"
              dialogHeading="Add a New Item Sub-Category"
              open={open}
              maxWidth={"md"}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <ItemSubCategoryForm
                    formType="create"
                    setOpen={setOpen}
                    setNewItemSubCategoryData={setNewItemSubCategoryData}
                    setIsSuccess={setIsSuccess}
                    editData={editData}
                  />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    Parent Category
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {subCategoryList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  subCategoryList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.sub_category_name}</StyledTableCell>
                          <StyledTableCell>
                            {row.related_category.category_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickEditOpen(e)}
                            >
                              Edit
                            </Button>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Delete
                            </Button>
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Order"
                            open={openEdit}
                            handleClose={handleClickEditClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <ItemSubCategoryEditForm
                                  formType="edit"
                                  id={clickedItem}
                                  editData={editData}
                                  setOpen={setOpenEdit}
                                  setNewItemSubCategoryData={
                                    setNewItemSubCategoryData
                                  }
                                  setIsSuccess={setIsSuccess}
                                />
                              </Box>
                            }
                          />
                          <ResponsiveDialog
                            dialogHeading="Are you sure you want to delete?"
                            open={openDelete}
                            handleClose={handleClickDeleteClose}
                            negativeResponse="Cancel"
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  onClick={handleClickDelete}
                                  sx={{
                                    m: "1rem 0",
                                    p: "1rem",
                                  }}
                                >
                                  Yes, Delete
                                </Button>
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={subCategoryList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};