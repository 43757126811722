import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert,Breadcrumbs,Link,Typography,Paper, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Autocomplete} from "@mui/material";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEmployeesList } from "api/hooks/humanResource";
import { inventoryApi } from "api/pesabackend";
import { useState, useEffect } from "react";
import moment from "moment/moment";

const returnSchema = yup.object().shape({
  invoice_number: yup.number().required("required"),
  total_return: yup.number().required("required"),
  details: yup.string().required("required"),
  return_date: yup.date().required("required"),
  return_detail: yup.array().of(
    yup.object().shape({
      related_renumeration_type: yup.number().required("required"),
      amount: yup.number().required("required"),
    })
  ),
});

const CustomerReturnForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { employeeList } = useEmployeesList(1, business_branch_id);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const [invoiceSelect, setInvoiceSelect] = useState(false);
  const [reintialize, setReintialize] = useState(false);

  const [purchaseDetails, setPurchaseDetails] = useState({products_purchased:[]});
  const [purchaseList, setPurchaseList] = useState([]);
  const [purchaseListError, setPurchaseListError] = useState(false);
  const [purchaseDetailsError, setPurchaseDetailsError] = useState(false);


  const [returnDoc, setReturnDoc] = useState("receipt");

  const fetchPurchaseInvoiceList = async (invoiceNumber=null) => {
    let urlString;
    if (invoiceNumber) {
      urlString = `/product_purchase/list?business_branch=${business_branch_id}&supplierFilter=all&search=${invoiceNumber}`;
    } else  {
      urlString = `/product_purchase/list?business_branch=${business_branch_id}&supplierFilter=all`;
    }
    try {
      const purchase = await inventoryApi.get(urlString);
      setPurchaseList(purchase.data.data);
    } catch (e) {
      setPurchaseListError(true);
    }
  };

  const getPurchaseDetail = async (purchaseId) => {
    
    try {
      const purchase = await inventoryApi.get(`product_purchase/get/${purchaseId}`);
      setPurchaseDetails(purchase.data.data);
    } catch (e) {
      setPurchaseDetailsError(true);
    }
  }

  const handleInvoiceSelect = (id) => {
    getPurchaseDetail(id);
    setTimeout(() => {
      setReintialize(false);
    }, 1500);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesPayslip = {
    related_purchase: invoiceSelect,
    total_return: 0,
    total_discount: purchaseDetails.discount,
    total_purchase: purchaseDetails.grand_total,
    return_date: moment().format("YYYY-MM-DD"),
    details: "",
    return_detail: purchaseDetails.products_purchased,
  };

  const [totalReturn, setTotalReturn] = useState(0);


  const [returnDetailFormData, setReturnDetailFormData] = useState(
    initialValuesPayslip.return_detail
  );

 

  const savePayslip = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await inventoryApi.post(
      `/payslip`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/humanResource");
      }, 1500);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };
  

  const handleTotalReturn = (newArray) => {
    const totalReturnCalculation = newArray.reduce((total, newArray) => {
      return Number(total) + Number(newArray.amount);
    }, 0);
    setTotalReturn(totalReturnCalculation);
  };


  const handleReturnAmountChange = (event, index) => {
    const { value } = event.target;
    const newArray = returnDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: Number(value) };
      }
      return item;
    });
    handleTotalReturn(newArray);
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePayslip(values, onSubmitProps);
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Payslip Invoice recorded successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/returns")}
            >
              <AssignmentReturnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Human Resource
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Record Customer Return Form</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValuesPayslip}
                validationSchema={returnSchema}
                enableReinitialize={reintialize}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Select Return Document
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="controlled-radio-buttons-group"
                            value={values.return_doc=returnDoc}
                            onChange={(e) => {
                              handleChange(e);
                              setReturnDoc(e.target.value);
                            }}
                            row
                          >
                            <FormControlLabel
                              value="receipt"
                              control={<Radio />}
                              label="Receipt"
                            />
                            <FormControlLabel
                              value="invoice"
                              control={<Radio />}
                              label="Invoice"
                            />
                          </RadioGroup>
                        </FormControl>

                        {returnDoc === "receipt" ? (
                          <Autocomplete
                          onOpen={() => {fetchPurchaseInvoiceList();}}
                          name={`related_reciept`}
                          onChange={(e, value) => {
                            setFieldValue(`related_reciept`, value.id);
                            setInvoiceSelect(true);
                            handleInvoiceSelect(value.id);
                            setReintialize(true);
                          }}
                          sx={{
                            width: "45%",
                            margin: " 20px 0px",
                          }}
                          options={purchaseList}
                          getOptionLabel={(option) =>`${option.invoice_number}`}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, purchaseList) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={purchaseList.id}
                            >
                              {purchaseList.invoice_number} {purchaseList.related_supplier.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{...params.inputProps,}}
                              label="Search Sales Reciept Number"
                              value={values.related_reciept}
                              error={Boolean(touched.related_reciept) && Boolean(errors.related_reciept)}
                              helperText={ touched.related_reciept && errors.related_reciept}
                              onBlur={handleBlur}
                              onChange={(e) => {fetchPurchaseInvoiceList(e.target.value);}}
                            />
                          )}
                        />
                        ) : (
                          <Autocomplete
                          onOpen={() => {fetchPurchaseInvoiceList();}}
                          name={`related_invoice`}
                          onChange={(e, value) => {
                            setFieldValue(`related_invoice`, value.id);
                            setInvoiceSelect(true);
                            handleInvoiceSelect(value.id);
                            setReintialize(true);
                          }}
                          sx={{
                            width: "45%",
                            margin: " 20px 0px",
                          }}
                          options={purchaseList}
                          getOptionLabel={(option) =>`${option.invoice_number}`}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, purchaseList) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={purchaseList.id}
                            >
                              {purchaseList.invoice_number} {purchaseList.related_supplier.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{...params.inputProps,}}
                              label="Search Sales Invoice Number"
                              value={values.related_invoice}
                              error={Boolean(touched.related_invoice) && Boolean(errors.related_invoice)}
                              helperText={ touched.related_invoice && errors.related_invoice}
                              onBlur={handleBlur}
                              onChange={(e) => {fetchPurchaseInvoiceList(e.target.value);}}
                            />
                          )}
                        />
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "35%",
                          }}
                        >
                          <label>Return Date: </label>
                          <TextField
                            label=""
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values.return_date}
                            name="return_date"
                            type="date"
                            error={
                              Boolean(touched.return_date) &&
                              Boolean(errors.return_date)
                            }
                            helperText={
                              touched.return_date && errors.return_date
                            }
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                      </Box>

                      <TextField
                        label="Return Details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        value={values.details}
                        name="details"
                        error={
                          Boolean(touched.details) && Boolean(errors.details)
                        }
                        helperText={touched.details && errors.details}
                        sx={{
                          width: "95%",
                          margin: "10px 40px",
                        }}
                      />
                    </Box>
                    {invoiceSelect ? (
                      <>
                        <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          my="10px"
                        >
                          <h5>Input the Return Details here</h5>

                          <FieldArray
                            name="return_detail"
                            render={(arrayHelpers) => {
                              const return_detail = values.return_detail;

                              return (
                                <>
                                  {return_detail.map((row, index) => {
                                    return <></>;
                                  })}

                                  
                                </>
                              );
                            }}
                          />
                        </Box>

                        <Box
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Return Amount: </label>
                            <TextField
                              label=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={(values.total_return = totalReturn)}
                              name="total_return"
                              disabled
                              size="small"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              error={
                                Boolean(touched.total_return) &&
                                Boolean(errors.total_return)
                              }
                              helperText={
                                touched.total_return && errors.total_return
                              }
                              sx={{
                                // width: "100%",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <h3
                        style={{
                          textAlign: "center",
                          color: "red",
                          margin: "40px 0px",
                        }}
                      >
                        PLEASE INPUT THE SALES RECIEPT NUMBER FOR THE RETURN YOU
                        WANT TO RECORD.
                      </h3>
                    )}

                    {/* BUTTONS */}
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Save Return
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          navigate(`/humanResource`);
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerReturnForm;
