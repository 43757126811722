import {Box,Grid,Paper,MenuItem,TextField,Breadcrumbs,Link,Typography,useMediaQuery, Autocomplete} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProductTrends } from "api/hooks/reports";
import { useState } from "react";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LineChart from "components/Graphs/LineChart";
import moment from "moment";
import requester from "api/pesabackend";

export default function ItemTrends({ setIsSuccess }) {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate()
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {productTrends, isLoadingProductTrends,error, setNewPeriod, setProduct} = useProductTrends();

  const [periodSelect, setPeriodSelect] = useState(moment().format("YYYY"));
  const [productList, setProductList] = useState([]);
  const [productListError, setProductListError] = useState(false); 

  const [isSelectedProduct, setIsSelectedProduct] = useState(false);

  const fetchItemList = async (search) => {
    try {
      if (search) {
        if (search.length >= 3) {
          const response = await requester.get(
            `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
          );
          setProductList(response.data.data);
        }
      } else {
        // const response = await requester.get(
        //   `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
        // );
        // setProductList(response.data.data);
      }
    } catch (e) {
      setProductListError(true);
    }
  };

  const handlePeriodSelect = (e) => {
    setNewPeriod(e.target.value);
    setPeriodSelect(e.target.value);
  };

  const onChangeAutoComplete = async (value) => {
    if (value) {
      setIsSelectedProduct(true);
      setProduct(value.id);
    } else {
      setIsSelectedProduct(false);
    }
  };


  const purchasesInYear = productTrends ? productTrends.period_purchase_record: [];
  const salesInYear = productTrends ? productTrends.period_sales_record : [];

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/reports")}
            >
              <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Reports
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Item Trends</b>
            </Typography>
          </Breadcrumbs>
          <Box>
            <Box p="1rem" m="1rem" borderRadius="0.5rem">
              <Grid container spacing={4} className="alignHorizantal">
                <Grid xs={4}>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 170,
                      maxWidth: 300,
                    }}
                  >
                    <Autocomplete
                        size="small"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        sx={{ width: '100%' }}
                        onChange={(e, value) => onChangeAutoComplete(value)}
                        options={productList}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id} >
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{
                                    ...params.inputProps
                                }}
                                label="Select Product"
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                    />
                  </Paper>
                </Grid>

                <Grid xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      m: "4px",
                    }}
                  >
                    <h4>YEAR:</h4>
                    <Paper
                      sx={{
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 100,
                        maxWidth: 200,
                      }}
                    >
                      <TextField
                        label="Year"
                        size="small"
                        select
                        defaultValue={moment().format("YYYY")}
                        onChange={(e) => {
                          handlePeriodSelect(e);
                        }}
                        sx={{
                          minWidth: 50,
                          width:'100%',
                        }}
                      >
                        <MenuItem value="2023"> 2023</MenuItem>
                        <MenuItem value="2024"> 2024</MenuItem>
                        <MenuItem value="2025"> 2025</MenuItem>
                        <MenuItem value="2026"> 2026</MenuItem>
                        <MenuItem value="2027"> 2027</MenuItem>
                        <MenuItem value="2028"> 2028</MenuItem>
                        <MenuItem value="2029"> 2029</MenuItem>
                        <MenuItem value="2030"> 2030</MenuItem>
                        <MenuItem value="2031"> 2031</MenuItem>
                        <MenuItem value="2032"> 2032</MenuItem>
                        <MenuItem value="2033"> 2033</MenuItem>
                        <MenuItem value="2034"> 2034</MenuItem>
                      </TextField>
                    </Paper>
                    
                  </Box>
                </Grid>
                <Grid xs={4} sx={{ textAlign: "right" }}></Grid>
              </Grid>
            </Box>

            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <Paper
                  sx={{
                    padding: 3,
                    marginY: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around",  marginLeft: 8,  width: "90%" }}>
                    {/* <h2>Item Trends</h2> */}
                    {isSelectedProduct ? 
                      <LineChart
                      label={[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "Septmeber",
                        "October",
                        "November",
                        "December",
                      ]}
                      data={[
                        {
                          label: `Sales for ${periodSelect}(In Units)`,
                          data: salesInYear,
                          fill: false,
                          borderColor: "rgb(75, 192, 192)",
                          tension: 0.1,
                        },
                        {
                          label: `Purchases for ${periodSelect}(In Units)`,
                          data: purchasesInYear,
                          fill: false,
                          borderColor: "rgb(45, 100, 170)",
                          tension: 0.1,
                        },
                      ]}
                    /> : <p style={{ color: "red"}}> *PLEASE SEARCH AND SELECT A PRODUCT</p> 
                    }
                    
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
