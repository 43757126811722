import {Text} from "@react-pdf/renderer";

export const PdfHeading = ({
  fontWeight = "bold",
  fontSize = 22,
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfSubHeading = ({
  fontWeight = "bold",
  fontSize = 20,
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfSubSubHeading = ({
  fontWeight = "bold",
  fontSize = 16,
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfSubSubSubHeading = ({
  fontWeight = "bold",
  fontSize = 14,
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};


export const PdfMedium = ({
  fontWeight = "medium",
  fontSize = 16,
  textAlign = "left",
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        textAlign: textAlign,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfRegular = ({
  fontWeight = "normal",
  fontSize = 12,
  fontFamily = 'arimaRegular',
  children,
}) => {
  return (
    <Text
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfSmall = ({
  fontWeight = "normal",
  fontSize = 10,
  fontFamily = 'arimaRegular',
  style,
  children,
}) => {
  return (
    <Text
      style={{
        ...style,
        fontSize: fontSize,
        fontWeight: fontWeight,
        // fontFamily: fontFamily,
      }}
    >
      {children}
    </Text>
  );
};

export const PdfPageNumber = ({ style, bottom = 55 }) => {
  return (
    <Text
      style={{
        ...style,
        position: "absolute",
        bottom: bottom,
        right: 5,
        fontSize: 12,
        color: "grey",
      }}
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} of ${totalPages}`
      }
      fixed
    />
  );
};
