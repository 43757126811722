import {Box,Grid,Button,Paper,InputBase,MenuItem,TextField,Breadcrumbs,Link,Typography,useMediaQuery,Menu} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useItemDeadStockReportList } from "api/hooks/reports";
import { useSelector } from "react-redux";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemDeadStockReportPdf from "scenes/reports/pdfForms/inventory/deadStockReportPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

export default function DeadStockReport({ setIsSuccess }) {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const user = useSelector((state) => state.user);
  const {itemDeadStockReportList, isLoadingItemDeadStockReportList, error, setSearch} = useItemDeadStockReportList(1, business_branch_id);

  const [openExportOptions, setOpenExportOptions] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenExportOptions(true);
  };

  const handleClose = () => {
    setOpenExportOptions(false);
  };
  const [searchContent, setSearchContent] = useState("");

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearch(searchContent);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/reports")}
            >
              <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Reports
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Item Dead Stock Report</b>
            </Typography>
          </Breadcrumbs>
          <Box>
            <Box p="1rem" m="1rem" borderRadius="0.5rem">
              <Grid container spacing={4} className="alignHorizantal">
                <Grid xs={4}>
                  <Paper
                    sx={{
                      my: "4px",
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 170,
                      maxWidth: 300,
                    }}
                  >
                    <InputBase
                      placeholder="Search Item Name"
                      value={searchContent}
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                    />
                    <IconButton onClick={handleClickSearch}>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>

                <Grid xs={4}>
                  
                </Grid>
                <Grid xs={2} sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    anchorE1={anchorE1}
                    endIcon={<DownloadIcon />}
                  >
                    EXPORT
                  </Button>
                  <Menu
                    style={{ marginRight: "30px" }}
                    anchorEl={anchorE1}
                    open={openExportOptions}
                    onClose={handleClose}
                  >
                    <MenuItem
                      style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                      onClick={() => {handleClose();}}
                    >
                      <PDFDownloadLink document={<ItemDeadStockReportPdf viewData={{ report_detail: itemDeadStockReportList,}}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Item Dead Stock Report`}>
                        PDF
                      </PDFDownloadLink>
                    </MenuItem>    
                    <MenuItem
                      style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                      onClick={() => {handleClose();}}
                    >
                      EXCEL
                    </MenuItem>  
                  </Menu>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    maxHeight="100vh"
                  >
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell style={{ width: 275 }}>Item</StyledTableCell>
                        <StyledTableCell style={{ width: 275 }}>Description</StyledTableCell>
                        <StyledTableCell>Purchase Date</StyledTableCell>
                        <StyledTableCell>Dead Stock Date</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>Current Stock</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>Batch</StyledTableCell>
                        <StyledTableCell>Expiry Date</StyledTableCell>
                        <StyledTableCell>Unit</StyledTableCell>
                        <StyledTableCell>Cost Price</StyledTableCell>
                        <StyledTableCell>Supplier</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {itemDeadStockReportList.length === 0 ? (
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={12}
                            style={{ textAlign: "center" }}
                          >
                            <h3>No Data found</h3>
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        itemDeadStockReportList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.related_product.product_name}</StyledTableCell>
                                <StyledTableCell>{row.related_product.description}</StyledTableCell>
                                <StyledTableCell>{moment(row.related_product_purchase.purchase_date).format("L")}</StyledTableCell>
                                <StyledTableCell>{moment(row.dead_stock_date).format("L")}</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }}>{row.remaining_quantity}</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }}>{row.batch}</StyledTableCell>
                                <StyledTableCell>{moment(row.related_product_purchase.expiry_date).format("L")}</StyledTableCell>
                                <StyledTableCell>{row.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                                <StyledTableCell>{currencyFormatter(parseFloat(Number(row.smallest_unit_unit_cost)*Number(row.remaining_quantity)))}</StyledTableCell>
                                <StyledTableCell>{row.related_product_purchase.related_supplier.name}</StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={itemDeadStockReportList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
