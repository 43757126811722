import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  FormControlLabel,
  Switch,
  useTheme,
  MenuItem,
} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { inventoryApi } from "api/pesabackend";

const PaymentMethodCreateForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    payment_method: "",
    related_ledger: "",
    initial_balance: 0,
  };

  const itemSchema = yup.object().shape({
    payment_method: yup.string().required("required"),
    related_ledger: yup.number().required("required"),
    initial_balance: yup.number().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {
    const savedItemResponse = await inventoryApi.post(
      `/product_purchase/add`,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const savedItem = await savedItemResponse.json();

    if (savedItem.status === 201) {
      props.setOpen(false);
      props.setNewUserData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Payment Method Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.payment_method}
              name="payment_method"
              error={
                Boolean(touched.payment_method) &&
                Boolean(errors.payment_method)
              }
              helperText={touched.payment_method && errors.payment_method}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              label="Current Asset Account"
              onBlur={handleBlur}
              select
              onChange={handleChange}
              value={values.related_ledger}
              name="related_ledger"
              error={
                Boolean(touched.related_ledger) &&
                Boolean(errors.related_ledger)
              }
              helperText={touched.related_ledger && errors.related_ledger}
              sx={{ gridColumn: "span 1" }}
            >
              <MenuItem value="Airtel Money"> Airtel Money</MenuItem>
            </TextField>

            <TextField
              label="Initial Balance"
              onBlur={handleBlur}
              type="number"
              onChange={handleChange}
              value={values.initial_balance}
              name="initial_balance"
              error={
                Boolean(touched.initial_balance) &&
                Boolean(errors.initial_balance)
              }
              helperText={touched.initial_balance && errors.initial_balance}
              sx={{ gridColumn: "span 1" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PaymentMethodCreateForm;
