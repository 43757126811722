import { useEffect, useState } from "react";
import requester from "api/pesabackend";
import { useSelector } from "react-redux";
import moment from "moment";

const resource_tag = 'customers';

const useCustomerList = (pageNumber, business_branch_id) => {
	const refreshCount = useSelector((state) => state.refreshCount);
    const [customerList, setCustomerList] = useState([]);
	const [isLoadingCustomerList, setLoadingCustomerList] = useState(true);
	const [errorCustomerList, setErrorCustomerList] = useState(false);

	const url = `/${resource_tag}/list?search=False&business_branch=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setCustomerList(response.data.data);
				setLoadingCustomerList(false);
				setErrorCustomerList(false);
			} catch (e) {
				setErrorCustomerList(true);
				setLoadingCustomerList(false);
			}
		};
		fetchData();
	}, [refreshCount]);
	return { customerList, isLoadingCustomerList, errorCustomerList };
};


const useCustomerFeedbackList = (pageNumber, business_branch_id) => {
    const [customerFeedbackList, setCustomerFeedbackList] = useState([]);
	const [isLoadingCustomerFeedbackList, setLoadingCustomerFeedbackList] = useState(true);
	const [newCustomerFeedbackData, setNewCustomerFeedbackData] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});
	const [errorCustomerFeedbackList, setErrorCustomerFeedbackList] = useState(false);

	const url = `/${resource_tag}/feedback/list?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setCustomerFeedbackList(response.data.data);
				setNewCustomerFeedbackData(false);
				setLoadingCustomerFeedbackList(false);
				setErrorCustomerFeedbackList(false);
			} catch (e) {
				setErrorCustomerFeedbackList(true);
				setLoadingCustomerFeedbackList(false);
			}
		};
		fetchData();
	}, [pageNumber, isLoadingCustomerFeedbackList, newCustomerFeedbackData, url]);
	return { customerFeedbackList, isLoadingCustomerFeedbackList, errorCustomerFeedbackList, setNewCustomerFeedbackData, setFilter };
};


// ######################################### SINGLE RECORD CALLS ####################################################


const useGetCustomer = (id) => {
    const [customer, setCustomer] = useState("");
	const [isLoadingCustomer, setLoadingCustomer] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/customers/get/${id}`);
				setCustomer(response.data.data);
				setLoadingCustomer(false);
			} catch (e) {
				setError(true);
				setLoadingCustomer(false);
			}
		};
		fetchData();
	}, [id,isLoadingCustomer]);
	return { customer, isLoadingCustomer, error };
};


export { useCustomerList, useCustomerFeedbackList, useGetCustomer };