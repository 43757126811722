import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import IconButton from '@mui/material/IconButton';
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { FlexRowCenter } from "components/FlexBox";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import moment from "moment";

import { useChartOfAccountsNextAccountsList } from "api/hooks/accounting"; 


export default function BalanceSheetMonthsTable () {
  
  const location = useLocation();
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [isSidebar, setIsSidebar] = useState(true);

//   const {chartNextList, isLoadingChartNextList, errorChartNextList} = []
  const chartNextList = []
  const isLoadingChartNextList = true

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const handleClickDeleteOpen = async (e) => {
  
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };


  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
        <Box >
        
        <h3>Financial Year Months </h3>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
            <Grid container spacing={4} className="alignHorizantal">
            <InputBase
                placeholder="Search by Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
            />
            {/* <IconButton onClick={handleClickSearch}>
                <SearchIcon />
            </IconButton> */}
            </Grid>
        </Box>

        <Box>
            <Paper sx={{ width: "100%" }}>
            {isLoadingChartNextList ? (
                <DummyTable />
            ) : (
            <>
            <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                    <StyledTableRow>
                    <StyledTableCell style={{ minWidth: 100 }}>Month</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 100 }}>Actions</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {chartNextList.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                        <h3>No Data found</h3>
                        </TableCell>
                    </TableRow>
                    ) : (
                        chartNextList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                        return (
                            <StyledTableRow key={row.id}>
                            <StyledTableCell>{row.particulars}</StyledTableCell>
                            <StyledTableCell>{row.amount}</StyledTableCell>
                            </StyledTableRow>
                        );
                    })
                    )}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={chartNextList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </>
            )}
            </Paper>
        </Box>
        </Box>
  );
};