import React from "react";
import {Box, Stack, Typography, TextField, InputLabel, Select, MenuItem, FormControl} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween, FlexEnd } from "components/FlexBox";

const PaymentTypesForm = ({name, description, handleChangeName, handleChangeDescription, category, handleChangeCategory, categories}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                  <Grid container spacing={2} marginTop={"10px"}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Name of the Payment Type
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Payment Type Name"
                            onChange={handleChangeName}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Description of the Payment Type
                        </Typography>
                        <TextField
                            id="outlined-required"
                            label="Payment Type Description"
                            onChange={handleChangeDescription}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12}>
                        <FormControl fullWidth sx={{marginTop: 2}}>
                            <InputLabel id="demo-simple-select-label">Select Payment Type Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="category-select"
                                value={category}
                                label="Select Payment Type Category"
                                onChange={handleChangeCategory}
                            >
                                {categories.map((value, index ) => {
                                    return (
                                        <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                  </Grid>
                </Box>
            </Grid>
          </Grid>
   );
};

export default PaymentTypesForm;