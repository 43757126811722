import React from "react";
import {Box, Stack, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween, FlexEnd, FlexRow } from "components/FlexBox";
import { currencyFormatter } from "utilities/currencyFormatter";

const ReceiptConfirmView = ({amountPaid, total, handleChangeAmount, paymentModes, handleChangePaymentMode, paymentMode, handleChangeTransactionId, handleChangePhoneNumber}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <FlexBetween>
                  <Typography fontSize={16} variant="h4" sx={{marginBottom: 1}}>Invoice Amount - {currencyFormatter(parseFloat(total))}</Typography>
                </FlexBetween>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "2px", padding: "0.5rem" }}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the amount received from the customer
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Amount Received"
                            onChange={handleChangeAmount}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                  <Grid xs={12} sm={12}>
                    <FormControl fullWidth sx={{marginTop: 2}}>
                        <InputLabel id="demo-simple-select-label">Select Mode of Payment</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category-select"
                            value={paymentMode}
                            label="Select Payment Type Category"
                            onChange={handleChangePaymentMode}
                        >
                            {paymentModes.map((value, index ) => {
                                return (
                                    <MenuItem key={index} value={value}>{value.payment_type_name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                  </Grid>
                  
                  {paymentMode?.payment_type_category === 2 && (
                    <Grid xs={12} sm={12}>
                      <FlexRow>
                        <TextField
                            id="outlined-required"
                            label="Phone Number Used"
                            onChange={handleChangePhoneNumber}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                        <TextField
                            id="outlined-required"
                            label="Transaction I.D"
                            onChange={handleChangeTransactionId}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </FlexRow>
                    </Grid>
                  )}
                </Box>
                <FlexEnd>
                    <Grid>
                        <Typography variant="h5">Amount Tendered - {currencyFormatter(parseFloat(amountPaid))}</Typography>
                        <Typography variant="h5">Amount To Be Paid - {currencyFormatter(parseFloat(total))}</Typography>
                        {amountPaid && (
                          <Typography variant="h5">Balance - {currencyFormatter((parseFloat(amountPaid) - parseFloat(total)))}</Typography>
                        )}
                    </Grid>
                </FlexEnd>
            </Grid>
          </Grid>
   );
};

export default ReceiptConfirmView;