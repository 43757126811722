import { Box, Paper, Button } from "@mui/material";
import moment from "moment/moment";
import { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import ReactToPrint from "react-to-print";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

const StockAdujstmentViewForm = (props) => {
  // const [isSidebar, setIsSidebar] = useState(true);
  const { viewData } = props;
  // const location = useLocation();
  const componentRef = useRef();

  const adjustment_number = viewData.id;
  const detail = viewData.detail;
  const total_adjustment_value = viewData.total_adjustment_value;
  const adjusted_by = viewData.added_by.first_name + " " + viewData.added_by.last_name
  const date_added = viewData.date_added;
  const products_adjusted = viewData.products_adjusted;

  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>STOCK ADJUSTMENT</h1>
            <h2># {adjustment_number}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Adjustment Date
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(date_added).format("LL")}
              </p>

              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Adjustment Details
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {detail}
              </p>
              <br />
              <hr />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Adjusted By
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {adjusted_by}
              </p>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Batch
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Adjustment Type
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products_adjusted ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products_adjusted.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.related_product.product_name}{" "}
                          {row.related_product.description}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.batch}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.adjustment_type}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ padding: "6px 6px", border: "1px solid" }}
                        >
                          {row.amount}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL ADJUSTMENT VALUE</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{total_adjustment_value}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL ADJUSTMENT VALUE
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {total_adjustment_value}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />

          <hr />
        </Box>
      </Paper>
      <ReactToPrint
        documentTitle={`Stock Adjustment #${adjustment_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              my: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
    </>
  );
};

export default StockAdujstmentViewForm;
