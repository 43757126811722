import {Box,Grid,Button,Paper,MenuItem,TextField,Breadcrumbs,Link,Typography,useMediaQuery,Menu} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useemployeeList } from "api/hooks/inventory";
import { useEmployeesList } from "FetchData";
import { useItemSalesReportList } from "api/hooks/reports";
import { useSelector } from "react-redux";
import { useState } from "react";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemSalesReportPdf from "scenes/reports/pdfForms/sales/itemSalesReportPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

export default function ItemSalesDetailReport({ setIsSuccess }) {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const user = useSelector((state) => state.user);
  const {itemSaleReportList, isLoadingItemSaleReportList, error, setFilter, setInvoicedBy} = useItemSalesReportList(1, business_branch_id)
  const {employeeList} = useEmployeesList(1, business_branch_id);

  const [openExportOptions, setOpenExportOptions] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenExportOptions(true);
  };

  const handleClose = () => {
    setOpenExportOptions(false);
  };

  const handleChangeSupplierFilter = async (e) => {
    setInvoicedBy(e.target.value);
  };

  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));

  let report_period = startDate + " to " + endDate

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/reports")}
            >
              <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Reports
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Item Sales Detail Report</b>
            </Typography>
          </Breadcrumbs>
          <Box>
            <Box p="1rem" m="1rem" borderRadius="0.5rem">
              <Grid container spacing={4} className="alignHorizantal">
                <Grid xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      // m: "4px",
                    }}
                  >
                    <h4>REPORT PERIOD:</h4>

                    <Paper
                      sx={{
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 130,
                        maxWidth: 200,
                      }}
                    >
                      <TextField
                        label="PERIOD"
                        select
                        defaultValue="THIS MONTH"
                        size="small"
                        onChange={(e) => {
                          handleChangePeriodFilter(e);
                        }}
                        sx={{
                          minWidth: 50,
                          width:'100%'
                          // m: "10px",
                          // backgroundColor: "white",
                        }}
                      >
                        <MenuItem value="TODAY">TODAY</MenuItem>
                        <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                        <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                        <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                      </TextField>
                    </Paper>
                    
                    {showFiter ? (
                      <>
                        <Paper
                          sx={{
                            m: "10px",
                            display: "flex",
                            alignItems: "center",
                            minWidth: 130,
                            maxWidth: 200,
                          }}
                        >
                          <TextField
                            label="Start Date"
                            type="date"
                            defaultValue={startDate}
                            size="small"
                            onChange={(e) => setStartDate(e.target.value)}
                            // sx={{
                            //   m: "10px",
                            //   backgroundColor: "white",
                            // }}
                            inputProps={{
                              max: endDate,
                            }}
                          />
                        </Paper>
                        
                        <Paper
                          sx={{
                            m: "10px",
                            display: "flex",
                            alignItems: "center",
                            minWidth: 130,
                            maxWidth: 200,
                          }}
                        >
                          <TextField
                            label="End Date"
                            type="date"
                            defaultValue={endDate}
                            size="small"
                            onChange={(e) => setEndDate(e.target.value)}
                            // sx={{
                            //   m: "10px",
                            //   backgroundColor: "white",
                            // }}
                            inputProps={{
                              min: startDate,
                            }}
                          />
                        </Paper>
                        
                      </>
                    ) : null}
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        m: "9px",
                      }}
                      onClick={() => handleClickFilter()}
                    >
                      FILTER
                    </Button>
                  </Box>
                </Grid>

                <Grid xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      // m: "4px",
                    }}
                  >
                    <h4>FILTER BY EMPLOYEE:</h4>
                    <Paper
                      sx={{
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        minWidth: 130,
                        maxWidth: 200,
                      }}
                    >
                      <TextField
                        label="EMPLOYEES"
                        select
                        defaultValue="all"
                        size="small"
                        onChange={(e) => {
                          handleChangeSupplierFilter(e);
                        }}
                        sx={{
                          minWidth: 50,
                          width:'100%'
                        }}
                      >
                        <MenuItem key={"DEFAULT"} value="all">
                          ALL
                        </MenuItem>
                        {employeeList.map((row, index) => {
                          return (
                            <MenuItem key={index} value={row.id}>
                              {row.first_name} {row.last_name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Paper>
                    
                  </Box>
                </Grid>
                <Grid xs={2} sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    anchorE1={anchorE1}
                    endIcon={<DownloadIcon />}
                  >
                    EXPORT
                  </Button>
                  <Menu
                    style={{ marginRight: "30px" }}
                    anchorEl={anchorE1}
                    open={openExportOptions}
                    onClose={handleClose}
                  >
                    <MenuItem
                      style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                      onClick={() => {handleClose();}}
                    >
                      <PDFDownloadLink document={<ItemSalesReportPdf viewData={{ report_detail: itemSaleReportList, report_period: report_period}}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Item Sales Detail Report for ${report_period}`}>
                        PDF
                      </PDFDownloadLink>
                    </MenuItem>    
                    <MenuItem
                      style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                      onClick={() => {handleClose();}}
                    >
                      EXCEL
                    </MenuItem>  
                  </Menu>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    maxHeight="100vh"
                  >
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell style={{ width: 120 }}>
                          Invoice Date
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 300 }}>Item</StyledTableCell>
                        <StyledTableCell style={{ width: 300 }}>Description</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>Quantity Sold</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>Unit Price</StyledTableCell>
                        <StyledTableCell> Batch</StyledTableCell>
                        <StyledTableCell>Unit</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>Total Price</StyledTableCell>
                        <StyledTableCell>Customer Name</StyledTableCell>
                        <StyledTableCell>Invoiced By</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {itemSaleReportList.length === 0 ? (
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={12}
                            style={{ textAlign: "center" }}
                          >
                            <h3>No Data found</h3>
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        itemSaleReportList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{moment(row.date_created).format("LL")}</StyledTableCell>
                                <StyledTableCell>{row.related_product.product_name}</StyledTableCell>
                                <StyledTableCell>{row.related_product.description}</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }}>{row.quantity_as_smallest_unit}</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }}>{withoutSymbolFormatter(parseFloat(Number(row.sale_price)))}</StyledTableCell>
                                <StyledTableCell>{row.batch}</StyledTableCell>
                                <StyledTableCell>{row.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                                <StyledTableCell style={{ textAlign: "center" }}>{currencyFormatter(parseFloat(Number(row.total)))}</StyledTableCell>
                                <StyledTableCell>{row.related_invoice.created_by.first_name} {row.related_invoice.created_by.last_name}</StyledTableCell>
                                <StyledTableCell>{row.related_invoice.created_by.first_name} {row.related_invoice.created_by.last_name}</StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={itemSaleReportList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
