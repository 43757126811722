import { Box,  Grid, Paper, InputBase, Button, MenuItem, TextField, Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useStockList, useItemTypeList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CurrentStockPdf from "scenes/reports/pdfForms/inventory/currentStockPdf";

export default function Stock () {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {stockList, isLoadingStockList, setSearch, setSearchFilter} = useStockList(1, business_branch_id);
  const user = useSelector((state) => state.user);
  const {typeList,isLoadingItemTypeList} = useItemTypeList(1, business_branch_id);
  
  const[searchContent, setSearchContent] = useState("");

  const [openExportOptions, setOpenExportOptions] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenExportOptions(true);
  };

  const handleClose = () => {
    setOpenExportOptions(false);
  };
  
  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };

  const handleChangeFilter = async (e)=> {
    setSearchFilter(e.target.value);
  }

  const handleClickView = (e)=>{
    navigate("/productLogList", { state: {productList: [e]} });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    
  return (
    <Box>
      <h4>Item Stock List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Paper
              sx={{
                my: "4px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Item Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "8px",
              }}
            >
              <h4>FILTER BY ITEM CATEGORY:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="ITEM CATEGORIES"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {typeList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.category_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={4} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate("/productLogList", { state: { productList: [] } })
              }
            >
              VIEW ALL LOGS
            </Button>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              anchorE1={anchorE1}
              endIcon={<DownloadIcon />}
              sx={{ mx: "5px" }}
            >
              EXPORT
            </Button>
            <Menu
              style={{ marginRight: "30px" }}
              anchorEl={anchorE1}
              open={openExportOptions}
              onClose={handleClose}
            >
              <MenuItem
                style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                onClick={() => {handleClose();}}
              >
                <PDFDownloadLink document={<CurrentStockPdf viewData={{ report_detail: stockList,}}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Item Current Stock Report`}>
                  PDF
                </PDFDownloadLink>
              </MenuItem>    
              <MenuItem
                style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                onClick={() => {handleClose();}}
              >
                EXCEL
              </MenuItem>  
            </Menu>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ padding: "15px 6px" }}>
                    No.
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "15px 6px" }}>
                    Item Name
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 100, padding: "15px 6px" }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 100, padding: "15px 6px" }}
                  >
                    Item Units
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      minWidth: 150,
                      textAlign: "center",
                      padding: "15px 6px",
                    }}
                  >
                    Avalable Stock
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      minWidth: 100,
                      padding: "15px 6px",
                    }}
                  >
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stockList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={12}
                      style={{ textAlign: "center" }}
                    >
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  stockList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell style={{ padding: "15px 6px" }}>
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "15px 6px" }}>
                            {row.product_name}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "15px 6px" }}>
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "15px 6px" }}>
                            {row.unit}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", padding: "15px 6px" }}
                          >
                            {row.available_stock}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              textAlign: "center",
                              minWidth: 100,
                              padding: "15px 6px",
                            }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={() => handleClickView(row)}
                            >
                              View Log
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={stockList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};