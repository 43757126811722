import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween, FlexEnd } from "components/FlexBox";
import InvoiceDetailsConfirmTable from "../tables/invoiceDetailsConfirmTable";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";


const InvoiceConfirmView = ({invoiceDescription, invoiceDetails, total, discount}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <FlexBetween>
                  <Typography variant="h4">Invoice Description - {invoiceDescription}</Typography>
                </FlexBetween>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                  <Typography variant="h5">Products</Typography>
                  <Grid container spacing={2} marginTop={"10px"}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                          <InvoiceDetailsConfirmTable products={invoiceDetails} />
                      </Stack>
                      <Stack spacing={2}>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <FlexEnd>
                    <Grid>
                        <Typography variant="h5">Sub Total      - {currencyFormatter(parseFloat(total))}</Typography>
                        <Typography variant="h5">Total Discount - {currencyFormatter(parseFloat(discount))}</Typography>
                        <Typography variant="h5">Grand Total    - {currencyFormatter(parseFloat(total))}</Typography>
                    </Grid>
                </FlexEnd>
            </Grid>
          </Grid>
   );
};

export default InvoiceConfirmView;