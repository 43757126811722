import {Box,Button,TextField,useMediaQuery,Typography,useTheme,MenuItem, Alert} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadButton from "components/loadingButton";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";
import { useDepartmentsList, useEmployeeRolesList } from "api/hooks/humanResource";
import moment from "moment";

const EmployeeCreateForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {departmentList} = useDepartmentsList(1, business_branch_id);
  const {employeeRoleList} = useEmployeeRolesList(1, business_branch_id)
  const [isFailed, setIsFailed] = useState(false);

  const [isUser, setIsUser] = useState(false)
  const [isSubmittingData, setIsSubmittingData] = useState(false)

  const handleChangeIsUser = (event) => {
    setIsUser(event.target.checked)
  }

  const initialValuesItem = {
    first_name: "",
    last_name: "",
    id_number: "",
    phone_number: "",
    related_department: "",
    next_of_kin_name: "",
    next_of_kin_phone_number: "",
    related_role:"",
    image: "",
    date_employed: moment().format("YYYY-MM-DD"),
  };

  const itemSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    id_number: yup.string().required("required"),
    phone_number: yup.string().required("required"),
    related_department: yup.number().required("required"),
    related_role: yup.number().required("required"),
    next_of_kin_name: yup.string().required("required"),
    next_of_kin_phone_number: yup.string().required("required"),
    date_employed: yup.date(),
  });

  const saveItem = async (values, onSubmitProps) => {
    setIsSubmittingData(true)
    const savedItemResponse = await requester.post(`/human_resource/employees`, values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      setIsSubmittingData(false)
      props.setOpen(false);
      props.setNewEmployeeData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }

    if (savedItemResponse.data.status === 400) {
      // // props.setOpen(false);
      // props.setNewUserData(true);
      // onSubmitProps.resetForm();
      setIsFailed(true);
      setIsSubmittingData(false)
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {

    let formData = new FormData();

    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.is_login_user = isUser

    for (let value in values) {
      formData.append(value, values[value]);
    }
    saveItem(formData, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isFailed ? (
              <Alert sx={{ m: "15px" }} variant="filled" severity="error">
                Operation was not successful!
              </Alert>
            ) : null}

            <TextField
              label="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              name="first_name"
              error={Boolean(touched.first_name) && Boolean(errors.first_name)}
              helperText={touched.first_name && errors.first_name}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              name="last_name"
              error={Boolean(touched.last_name) && Boolean(errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Phone Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone_number}
              name="phone_number"
              error={
                Boolean(touched.phone_number) && Boolean(errors.phone_number)
              }
              helperText={touched.phone_number && errors.phone_number}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="ID Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id_number}
              name="id_number"
              error={Boolean(touched.id_number) && Boolean(errors.id_number)}
              helperText={touched.id_number && errors.id_number}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              name="address"
              error={Boolean(touched.address) && Boolean(errors.address)}
              helperText={touched.address && errors.address}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Department"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.related_department}
              select
              name="related_department"
              error={
                Boolean(touched.related_department) &&
                Boolean(errors.related_department)
              }
              helperText={
                touched.related_department && errors.related_department
              }
              sx={{ gridColumn: "span 2" }}
            >
              {departmentList.map((row, index) => {
                return (
                  <MenuItem value={row.id}>{row.department_name}</MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Next of Kin Names"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.next_of_kin_name}
              name="next_of_kin_name"
              error={
                Boolean(touched.next_of_kin_name) &&
                Boolean(errors.next_of_kin_name)
              }
              helperText={touched.next_of_kin_name && errors.next_of_kin_name}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Next of Kin Contact"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.next_of_kin_phone_number}
              name="next_of_kin_phone_number"
              error={
                Boolean(touched.next_of_kin_phone_number) &&
                Boolean(errors.next_of_kin_phone_number)
              }
              helperText={
                touched.next_of_kin_phone_number &&
                errors.next_of_kin_phone_number
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Employement Date"
              onBlur={handleBlur}
              type="date"
              onChange={handleChange}
              value={values.date_employed}
              name="date_employed"
              error={Boolean(touched.date_employed) && Boolean(errors.date_employed)}
              helperText={touched.date_employed && errors.date_employed}
              sx={{ gridColumn: "span 2" }}
            />

            <FormControlLabel 
              control={<Checkbox />} 
              label="Is the Employee a User?" 
              onChange={handleChangeIsUser}
            />

            {isUser && (
              <>
                <TextField
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={
                    Boolean(touched.username) &&
                    Boolean(errors.username)
                  }
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 2" }}
                />

              <TextField
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={
                    Boolean(touched.password) &&
                    Boolean(errors.paswword)
                  }
                  helperText={
                    touched.password &&
                    errors.password
                  }
                  sx={{ gridColumn: "span 2" }}
              />
              <TextField
                label="System User Group"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.related_role}
                select
                name="related_role"
                error={
                  Boolean(touched.related_role) &&
                  Boolean(errors.related_role)
                }
                helperText={
                  touched.related_role && errors.related_role
                }
                sx={{ gridColumn: "span 2" }}
              >
                {employeeRoleList.map((row, index) => {
                  return (
                    <MenuItem value={row.id}>{row.role_name}</MenuItem>
                  );
                })}
              </TextField>
            </>
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            {isSubmittingData ? (
              <LoadButton name={"Saving Details"} loading={isSubmittingData}/>
            ) : (
              <Button
                fullWidth
                size="small"
                type="submit"
                sx={{
                  m: "10px 0",
                  p: "10px",
                  backgroundColor: palette.primary.main,
                  color: palette.background.alt,
                  "&:hover": { color: palette.primary.main },
                  width: "20%",
                }}
              >
                Save Employee Details
              </Button>
            )}
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EmployeeCreateForm;
