import {
  Box,Grid,Button,Paper,InputBase,MenuItem,TextField, useTheme} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePurchaseList, useSupplierList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { inventoryApi } from "api/pesabackend";
import FlexBetween from "components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";


export default function InventorySettings({ setIsSuccess }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const business_branch_id = useSelector((state) => state.business_branch_id);
    const { purchaseList, isLoadingPurchaseList, setSearch, setSupplierFilter } =
        usePurchaseList(1, business_branch_id);
    const { supplierList } = useSupplierList(1, business_branch_id);

    const [openRenumeration, setOpenRenumeration] = useState(false);
    const [openEditRenumeration, setOpenEditRenumeration] = useState(false);
    const [openDeleteRenumeration, setOpenDeleteRenumeration] = useState(false);

    const [openDeduction, setOpenDeduction] = useState(false);
    const [openEditDeduction, setOpenEditDeduction] = useState(false);
    const [openDeleteDeduction, setOpenDeleteDeduction] = useState(false);

    const handleClickOpenRenumeration = async () => {
        setOpenRenumeration(true);
    }

    const handleClickCloseRenumeration = async () => {
        setOpenRenumeration(false);
    }

    const handleClickEditOpenRenumeration = async (e) => {
        const response = await inventoryApi.get(
        `/product_purchase/get/${e.target.value}`
        );
        if (response.status === 200) {
        navigate("/editPurchaseForm", { state: response.data.data });
        }
    };

    const handleClickEditCloseRenumeration = () => {
        setOpenEditRenumeration(false);
    };

    const handleClickDeleteOpenRenumeration = async (e) => {
        const response = await inventoryApi.get(
        `/product_purchase/get/${e.target.value}`
        );
        if (response.status === 200) {
        setOpenDeleteRenumeration(true);
        }
    };

    const handleClickDeleteCloseRenumeration = () => {
        setOpenDeleteRenumeration(false);
    };

    // const handleClickDeleteRenumeration = async () => {
    // const response = await inventoryApi.post(`/product_purchase/delete/${clickedItem}`);
    // setIsSuccess(true)
    // setNewPurchaseData(true)
    // setOpenDeleteRenumeration(false);
    // };


    const handleClickOpenDeduction = async () => {
        setOpenDeduction(true);
    }

    const handleClickCloseDeduction = async () => {
        setOpenDeduction(false);
    }

    const handleClickEditOpenDeduction = async (e) => {
        const response = await inventoryApi.get(
        `/product_purchase/get/${e.target.value}`
        );
        if (response.status === 200) {
        navigate("/editPurchaseForm", { state: response.data.data });
        }
    };

    const handleClickEditCloseDeduction = () => {
        setOpenEditDeduction(false);
    };

    const handleClickDeleteOpenDeduction = async (e) => {
        const response = await inventoryApi.get(
        `/product_purchase/get/${e.target.value}`
        );
        if (response.status === 200) {
        setOpenDeleteDeduction(true);
        }
    };

    const handleClickDeleteCloseDeduction = () => {
        setOpenDeleteDeduction(false);
    };

    // const handleClickDeleteDeduction = async () => {
    // const response = await inventoryApi.post(`/product_purchase/delete/${clickedItem}`);
    // setIsSuccess(true)
    // setNewPurchaseData(true)
    // setOpenDeleteDeduction(false);
    // };





    return (
      <Box>
        <Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                {/* <TableHead>
                    <StyledTableRow>
                    <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 150 }}>Date Added</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Invoice Number</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 150 }}>Invoice Date</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Grand Total</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Discount</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Supplier</StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                        Actions
                    </StyledTableCell>
                    </StyledTableRow>
                </TableHead> */}
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#C5C6C7",
                        padding: "6px 6px",
                        border: "1px solid",
                      }}
                      colSpan={12}
                    >
                      <FlexBetween>
                        <h3>MULTIPLE UNITS</h3>
                      </FlexBetween>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <Box width={"100%"} padding={"5px"} margin={"5px"}>
                        <TableContainer sx={{ maxHeight: "100vh" }}>
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            maxHeight="100vh"
                          >
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell colSpan={3}>
                                  <h3>
                                    No Data found
                                  </h3>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    );
}
