import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";



export default function InvoiceDetailsTable ({products}) {

  
  return (
    <TableContainer sx={{ maxHeight: "100vh" }}>
    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
        <TableHead>
        <StyledTableRow>
            <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
            <StyledTableCell style={{ minWidth: 100 }}>Product Name</StyledTableCell>
            <StyledTableCell style={{ minWidth: 100 }}>Product Description</StyledTableCell>
            <StyledTableCell style={{ minWidth: 50 }}>Unit</StyledTableCell>
            <StyledTableCell style={{ minWidth: 50 }}>Quantity</StyledTableCell>
            <StyledTableCell style={{ minWidth: 50 }}>Price</StyledTableCell>
            <StyledTableCell style={{ minWidth: 50 }}>Total</StyledTableCell>
        </StyledTableRow>
        </TableHead>
        <TableBody>
        {products.length === 0 ? (
            <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                <h3>No Data found</h3>
            </TableCell>
            </TableRow>
        ) : (
            products
            .map((row, index) => {
                return (
                <StyledTableRow key={row.id}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.related_product.product_name}</StyledTableCell>
                    <StyledTableCell>{row.related_product.description}</StyledTableCell>
                    <StyledTableCell>{row.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                    <StyledTableCell>{row.quantity}</StyledTableCell>
                    <StyledTableCell>{withoutSymbolFormatter(parseFloat(row.sale_price))}</StyledTableCell>
                    <StyledTableCell>{withoutSymbolFormatter(parseFloat(row.total))}</StyledTableCell>
                </StyledTableRow>
                );
            })
        )}
        </TableBody>
    </Table>
    </TableContainer>
  );
};