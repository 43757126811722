import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";

import { useChartOfAccountsList } from "api/hooks/accounting";


export default function ChartOfAccounts () {
  
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {chartList, isLoadingChartList, errorChartList} = useChartOfAccountsList(1, business_branch_id)

  
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INACTIVE" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="ACTIVE" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const handleClickDeleteOpen = async (e) => {
//   const response = await inventoryApi.get(`/product_purchase/get/${e.target.value}`);
//   if (response.status===200){
//     setOpenDelete(true);
//   }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };


  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h3>Chart of Accounts</h3>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <InputBase
            placeholder="Search by Account Name"
            value={searchContent}
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
          />
          {/* <IconButton onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton> */}

        {/* <Button
          variant="contained"
          onClick={() => navigate("/invoiceForm", { formType: "create" })}
        >
          CREATE INVOICE
        </Button> */}
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
        {isLoadingChartList ? (
            <DummyTable />
        ) : (
          <>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Account Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Account Code</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Category</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Status</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {chartList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  chartList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.ledger_name}</StyledTableCell>
                          <StyledTableCell>{row.ledger_code}</StyledTableCell>
                          <StyledTableCell>{row.related_accounting_category.accounting_category_name}</StyledTableCell>
                          <StyledTableCell>{renderStatusPills(row.is_active)}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 100 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => navigate("/chartNextAccounts", { state: { id: row.id, name: row.ledger_name }})}
                            >
                              View Ledgers
                            </Button>
                            {/* <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              View Ledger Details
                            </Button> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={chartList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
        )}
        </Paper>
      </Box>
    </Box>
  );
};