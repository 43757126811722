import React, {useState, useEffect} from "react";
import {Box,Button,TextField, MenuItem, Autocomplete} from "@mui/material";
import { useSelector } from "react-redux";
import { FlexRow } from "components/FlexBox";
import requester from "api/pesabackend";

const InvoiceDetailForm = (props) => {

    const {index, data, handleRemoveProductDetailRow, handleItemAmountChange, handleItemDiscountChange, name, errors, touched, handleBlur, handleChange, setFieldValue, handleGrandTotal} = props
    
	const [error, setError] = useState(false); 
    const [productList, setProductList] = useState([]);
    const business_branch_id = useSelector((state) => state.business_branch_id);
    
    const [unitsForGivenItemList, setUnitsForGivenItemList] = useState(data.product_units_list ? data.product_units_list : []);
    const [batchesForProduct, setBatchesForProduct] = useState(data.batches ? data.batches : []);

    //individual state modifiers
    const [isSelectedProduct, setIsSelectedProduct] = useState(false);
    const [isAvailableBatches, setIsAvailableBatches] = useState(true);
    const [totalAmount, setTotalAmount] = useState(data.total);


    const fetchUnitsForGivenItemList = async (productId) => {
        try {
            const response = await requester.get(`/billing/products/details?product_id=${productId}`);
            setUnitsForGivenItemList(response.data.data.units);
            setBatchesForProduct(response.data.data.batches);
        } catch (e) {
            setError(true);
        }
    };
     
    const fetchItemList = async (search) => {
        try {
            if (search){
                if(search.length >= 3){
                    const response = await requester.get(
                        `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
                      );
                      setProductList(response.data.data);
                }  
            } else {
                // const response = await requester.get(
                //   `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
                // );
                // setProductList(response.data.data);
            }
            
        } catch (e) {
            setError(true);
        }
    };

    const onChangeAutoComplete = async (value) => {
        if (value) {
            setIsSelectedProduct(true)
            data.product_name = value.product_name
            await fetchUnitsForGivenItemList(value.id);
        } else {
            setIsSelectedProduct(false)
        };
        setFieldValue(
            `${name}.related_product`,
            value ? value.id : null
        );
    }

    const onChangeUnit = (price) => {
        data.sale_price = price.props.price
        data.product_unit = price.props.name
    } 

    const onChangeQuantity = (quantity) => {
        const calculatedAmount = parseFloat(quantity) * parseFloat(data.sale_price)
        setTotalAmount(calculatedAmount.toFixed(2));
        handleItemAmountChange(data.sale_price, calculatedAmount, quantity, data.batch, data.product_name, data.product_unit, data.related_product, data.related_product_unit, index)
    }
    
    return (

        <FlexRow>
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="10px"
                p="0.5rem"
                gridTemplateColumns="repeat(18, minmax(0, 1fr))"
                marginTop="1rem"
            >  
                {!isSelectedProduct ? (
                    <>
                        <Autocomplete
                        size="medium"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        name={`${name}.related_product`}
                        onChange={(e, value) => onChangeAutoComplete(value)}
                        sx={{ gridColumn: "span 17" }}
                        options={productList}
                        defaultValue = {data.related_product!=='' ? data.related_product : null}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id} >
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{
                                    ...params.inputProps
                                }}
                                label="Type a Product Name to search, 3 letters minimum"
                                value={data.related_product ? data.related_product ?? null : ''}
                                error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.related_product) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.related_product)}
                                helperText={ touched?.invoice_products && touched?.invoice_products[index]?.related_product && errors?.invoice_products && errors?.invoice_products[index]?.related_product}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                        />
                        {index > 0 ? 
                            (<Button
                                value={index}
                                onClick={() => {
                                    handleRemoveProductDetailRow(index);
                                }}                                                     
                                size="small"
                                variant="contained"
                            >
                                Remove
                            </Button>) 
                            : (<Button sx={{ gridColumn: "span 1" }}
                            >-</Button>)
                    }
                    </>
                ) : (
                <>
                    <Autocomplete
                        size="small"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        name={`${name}.related_product`}
                        onChange={(e, value) => onChangeAutoComplete(value)}
                        sx={{ gridColumn: "span 4" }}
                        options={productList}
                        defaultValue = {data.related_product!=='' ? data.related_product : null}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id} >
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{
                                    ...params.inputProps
                                }}
                                label="Select Product Name"
                                value={data.related_product ? data.related_product ?? null : ''}
                                error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.related_product) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.related_product)}
                                helperText={ touched?.invoice_products && touched?.invoice_products[index]?.related_product && errors?.invoice_products && errors?.invoice_products[index]?.related_product}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                    />
                    

                    <TextField
                        label="Select Product Unit"
                        size="small"
                        onBlur={handleBlur}
                        onChange={(e, price) => {
                            handleChange(e);
                            onChangeUnit(price)
                            }
                        }
                        select
                        value={data.related_product_unit}
                        name={`${name}.related_product_unit`}
                        error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.related_product_unit) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.related_product_unit)}
                        helperText={ touched?.invoice_products && touched?.invoice_products[index]?.related_product_unit && errors?.invoice_products && errors?.invoice_products[index]?.related_product_unit}
                        sx={{ gridColumn: "span 3" }}
                    >
                        {unitsForGivenItemList.map((oneUnit, index)=>{
                            return(
                                <MenuItem key={index} value={oneUnit.id} price={oneUnit.unit_selling_price} name={oneUnit.related_business_product_unit.name} selected={oneUnit.id===data.related_product_unit ? true :false }>{oneUnit.related_business_product_unit.name}</MenuItem>
                            );
                        })}
                        
                    </TextField>
                    
                    <TextField
                        label="Select Batch"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={data.batch}
                        name={`${name}.batch`}
                        error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.batch) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.batch)}
                        helperText={ touched?.invoice_products && touched?.invoice_products[index]?.batch && errors?.invoice_products && errors?.invoice_products[index]?.batch}
                        sx={{ gridColumn: "span 3" }}
                    >
                        {batchesForProduct.map((batch, index)=>{
                            return(
                                <MenuItem key={index} value= {batch.batch} selected={batch.batch===data.batch ? true :false }>{`${batch.batch} - ${batch.available_stock}`}</MenuItem>
                            );
                        })}
                        
                    </TextField>

                    <TextField
                        label="Quantity"
                        size="small"
                        type="number"
                        inputProps={{min: 1}}
                        onBlur={handleBlur}
                        onChange={(e, index) => 
                            {handleChange(e); 
                            onChangeQuantity(e.target.value, index);
                            }}
                        value={data.quantity}
                        name={`${name}.quantity`}
                        error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.quantity) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.quantity)}
                        helperText={ touched?.invoice_products && touched?.invoice_products[index]?.quantity && errors?.invoice_products && errors?.invoice_products[index]?.quantity}
                        sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                        label="Sale Price"
                        size="small"
                        type="number"
                        value={data.sale_price}
                        disabled
                        name={`${name}.sale_price`}
                        sx={{ gridColumn: "span 2" }}
                    />    

                    <TextField
                        label="Disount"
                        size="small"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleItemDiscountChange(e,index);
                        }}
                        value={data.discount}
                        type="number"
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.discount`}
                        error={Boolean( touched?.invoice_products && touched?.invoice_products[index]?.discount) && Boolean(errors?.invoice_products && errors?.invoice_products[index]?.discount)}
                        helperText={ touched?.invoice_products && touched?.invoice_products[index]?.discount && errors?.invoice_products && errors?.invoice_products[index]?.discount}
                        sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                        label="Total"
                        size="small"
                        type="number"
                        disabled
                        onChange={handleChange}
                        value={data.total=totalAmount}
                        name={`${name}.total`}
                        sx={{ gridColumn: "span 2" }}
                    /> 
                    {index > 0 ? 
                        <Button
                            value={index}
                            onClick={() => {
                                handleRemoveProductDetailRow(index);
                            }}                                                     
                            size="small"
                            variant="contained"
                        >
                            Remove
                        </Button> : null
                    }
                </>
                )}
            </Box>
        </FlexRow>               
                    
    );
  };
  
  export default InvoiceDetailForm;