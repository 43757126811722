
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, useTheme} from '@mui/material';
import ResponsiveDialog from './responsiveDialog';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function ColumnGroupingTable(props) {
  const theme = useTheme();
  const{
          columnData, 
          tableData, 
          deleteButton,
          editButton,
          viewButton,
          viewButtonName,
          actionColumn
          } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: "100vh" }}>
        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
          <TableHead>
            <StyledTableRow>
              {columnData.map((column) => (
                <StyledTableCell
                  key={column.id}
                  // align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              {!actionColumn ? (
                <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
              ) : null}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableData.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                  <h3>No Data found</h3>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columnData.map((column) => {
                        const value = row[column.id];
                        return (
                          <StyledTableCell key={column.id} /*align={column.align*/>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                      {!actionColumn ? (
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {editButton ? (
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => props.handleClickEditOpen(e)}
                            >
                              Edit{" "}
                            </Button>
                          ) : null}

                          {deleteButton ? (
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => props.handleClickDeleteOpen(e)}
                            >
                              Delete
                            </Button>
                          ) : null}

                          {viewButton ? (
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => props.handleClickView(e)}
                            >
                              {viewButtonName}
                            </Button>
                          ) : null}

                          <ResponsiveDialog
                            dialogHeading="Edit"
                            open={props.openEdit}
                            handleClose={props.handleClickEditClose}
                            negativeResponse="Cancel"
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                {props.editContent}
                              </Box>
                            }
                          />

                          <ResponsiveDialog
                            dialogHeading="Are you sure you want to delete?"
                            open={props.openDelete}
                            handleClose={props.handleClickDeleteClose}
                            negativeResponse="Cancel"
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                {props.deleteContent}
                              </Box>
                            }
                          />

                          <ResponsiveDialog
                            dialogHeading="Are you sure you want to delete?"
                            open={props.openView}
                            handleClose={props.handleClickViewClose}
                            negativeResponse="Cancel"
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                {props.ViewContent}
                              </Box>
                            }
                          />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}