import { Padding } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const FlexCenter = styled(Box)({
	display: "flex",
	alignItems: "center",
});

export const FlexStart = styled(Box)({
	display: "flex",
	alignItems: "flex-start",
});

export const FlexEnd = styled(Box)({
	display: "flex",
	alignItems: "flex-end",
	justifyContent: "flex-end",
});

export const FlexRow = styled(Box)({
	display: "flex",
    flexDirection: "row",
});

export const FlexCol = styled(Box)({
	display: "flex",
    flexDirection: "column",
});

export const FlexJustifyStart = styled(Box)({
	display: "flex",
	justifyContent: "start",
	alignItems: "center",
});

export const FlexBetween = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const FlexRowAround = styled(Box)({
	display: "flex",
    flexDirection: "row",
	justifyContent: "space-around",
    marginBottom: "1rem",
});

export const FlexColCenter = styled(Box)({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
});

export const FlexRowCenter = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	flexDirection: "row",
});

export const BorderedFlexCol = styled(Box)({
    display: "flex",
    flexDirection: "column",
    border:"1px solid #000",
    borderRadius:"2px",
    padding:"0.8rem"
})

export const BorderedFlexRow = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    border:"1px solid #000",
    borderRadius:"2px",
    padding:"0.8rem"
})