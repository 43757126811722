import { Box, Grid, Paper, Fab, useTheme, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from "react-redux";
import FixedSizeDialog from "components/fixedSizeDialog";
import BusinessDetailEditForm from "./businessDetailsEdit";
import BusinessLogoEditForm from "./businessLogoEdit";
import { useState } from "react";
import {  useGetBusiness } from "api/hooks/business";

const BusinessSetupViewForm = ({setIsSuccess, setIsFailed}) => {
  const theme = useTheme();
  const business_id = useSelector((state) => state.business_id);

  const [open, setOpen] = useState(false);
  const [openBusinessDetailEdit, setOpenBusinessDetailEdit] = useState(false);

  const {business, isLoadingBusiness, error, setNewBusinessData} = useGetBusiness(business_id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenBusinessDetailEdit = () => {
    setOpenBusinessDetailEdit(true);
  };

  const handleCloseBusinessDetailEdit = () => {
    setOpenBusinessDetailEdit(false);
  };

  const business_name = business ? business.business_name : '';
  const business_slogan = business ? business.business_slogan : '';
  const picture = business? business.business_logo : '';
  const email = business ? business.email : '';
  const address=business.business_location;

  return (
    <Box
      sx={{
        paddingX: "20px",
        marginX: "20px",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={4} className="alignHorizantal">
        <Grid xs={3} sx={{ mx: "8px", my: "15px" }}>
          <Paper
            sx={{ width: "100%", padding: "40px", margin: "40px" }}
            elevation={3}
          >
            <FlexBetween>
              <Box sx={{ width: "70%" }}>
                <h3 style={{ margin: "0px", padding: "0px" }}>BUSINESS LOGO</h3>
                <br />
                <img
                  alt="Image"
                  src={`http://localhost:8000${picture}`}
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Fab size="small" onClick={handleClickOpen}>
                <EditOutlinedIcon />
              </Fab>
            </FlexBetween>
            <FixedSizeDialog
              dialogButtonName="Edit Business Logo "
              dialogHeading="Edit Business Logo"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              maxWidth={"md"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <BusinessLogoEditForm
                    setIsSuccess={setIsSuccess}
                    setOpen={setOpen}
                    setNewBusinessData={setNewBusinessData}
                  />
                </Box>
              }
            />
          </Paper>
        </Grid>
        <Grid xs={8} sx={{ mx: "8px", my: "15px" }}>
          <Paper
            sx={{ width: "100%", padding: "40px", margin: "40px" }}
            elevation={3}
          >
            <FlexBetween>
              <h2 style={{ margin: "0px", padding: "0px" }}>
                BUSINESS DETAILS
              </h2>
              <Fab onClick={handleClickOpenBusinessDetailEdit} size="small">
                <EditOutlinedIcon />
              </Fab>
            </FlexBetween>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {" "}
              <b> BUSINESS NAME: </b> {business_name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {" "}
              <b>SLOGAN: </b> {business_slogan}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {" "}
              <b>ADDRESS: </b> {address}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {" "}
              <b>EMAIL: </b> {email}
            </Typography>
            <FixedSizeDialog
              dialogButtonName="Edit Business Details "
              dialogHeading="Edit  Business Details"
              open={openBusinessDetailEdit}
              handleClickOpen={handleClickOpenBusinessDetailEdit}
              handleClose={handleCloseBusinessDetailEdit}
              negativeResponse="Cancel"
              maxWidth={"md"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <BusinessDetailEditForm
                    editData={business}
                    setOpen={setOpenBusinessDetailEdit}
                    setIsSuccess={setIsSuccess}
                    setNewBusinessData={setNewBusinessData}
                  />
                </Box>
              }
            />
          </Paper>
        </Grid>
      </Grid>

      <hr />
    </Box>
  );
};

export default BusinessSetupViewForm;
