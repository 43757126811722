import { Box, Button, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const AllowanceTypeSchema = yup.object().shape({
  allowance_name: yup.string().required("required"),
});

const initialValuesAllowanceType = {
  allowance_name: "",
};

const AllowanceTypeForm = (props) => {
  const pageType = props.formType;
  let { id, editData } = props;
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNew = pageType === "create";
  const isEdit = pageType === "edit";

  const initialEditValuesAllowanceType = {
    allowance_name: editData.allowance_name,
  };

  const saveAllowanceType = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedResponse = await requester.post(
      `/human_resource/allowance`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewAllowanceTypeData(true);
      props.setOpen(false);
    }
  };

  const editAllowanceType = async (values, onSubmitProps) => {
    const savedResponse = await requester.post(
      `/human_resource/allowance/edit/${editData.id}`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewAllowanceTypeData(true);
      props.setOpen(false);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isNew) await saveAllowanceType(values, onSubmitProps);
    if (isEdit) await editAllowanceType(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={
        isNew
          ? initialValuesAllowanceType
          : initialEditValuesAllowanceType
      }
      validationSchema={AllowanceTypeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Allowance Type Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.allowance_name}
              name="allowance_name"
              error={
                Boolean(touched.allowance_name) &&
                Boolean(errors.allowance_name)
              }
              helperText={touched.allowance_name && errors.allowance_name}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default AllowanceTypeForm;
