import { Box, Button, TextField, useMediaQuery, useTheme, Rating, Typography, FormControlLabel, Switch, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";
import { useState } from "react";
import { useCustomerList } from "api/hooks/customer";


const labels = {
  0.5: 'Very Disappointing',
  1: 'Disappointing',
  1.5: 'Very Poor',
  2: 'Poor',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};


const EditCustomerFeedbackForm = (props) => {
  
  let { editData } = props;
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {customerList, isLoadingCustomerList} = useCustomerList(1, business_branch_id);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  

  const itemTypeSchema = yup.object().shape({
    registeredCustomer: yup.boolean(),
    related_customer: yup.number().when("registeredCustomer", {
        is: true,
        then: yup.number().required("required")
      }),
    rating: yup.number().required("required"),
    follow_up_appointed_date: yup.string().required("required"),
    feedback: yup.string(),
    phone_number: yup.string().when("registeredCustomer", {
        is: false,
        then: yup.string().required("required")
      }),
  });

  const initialValuesItemUnit = {
    registeredCustomer: editData.related_customer ? true : false,
    related_customer:editData.related_customer?editData.related_customer:"",
    follow_up_appointed_date: editData.follow_up_appointed_date,
    rating: editData.rating,
    feedback: editData.feedback,
    phone_number: editData.phone_number
  };

  const[isRegisteredCustomer, setIsRegisteredCustomer]= useState(initialValuesItemUnit.registeredCustomer);
  const [value, setValue] = useState(initialValuesItemUnit.rating);
  const [hover, setHover] = useState(-1);

  const saveItem = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;

    const savedUnitResponse = await requester.post(
      `/customers/feedback/edit/${editData.id}`,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (savedUnitResponse.status===201){
      props.setOpen(false);
      props.setNewCustomerFeedbackData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveItem(values, onSubmitProps);
  };

  const handleAddingRegisteredCustomer = (e) => {
    
    if (e.target.checked === true ) {
      setIsRegisteredCustomer(true)
    }
    if (e.target.checked === false ) {
      setIsRegisteredCustomer(false)
    }
  }; 


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItemUnit}
      validationSchema={itemTypeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Box sx={{ gridColumn: "span 4", display:"flex"}}>
              <Rating
              label="Customer rating"
              onBlur={handleBlur}
              size="large" 
              onChange={(event, newValue) => {
                handleChange(event);
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              value={values.rating}
              name="rating"
              precision={0.5}
              error={Boolean(touched.rating) && Boolean(errors.rating)}
              helperText={touched.rating && errors.rating}
              
            />
               {value !== null && (
                  <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                )}
            </Box>
            

            <TextField
              label="Add a feedback here"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={4}
              value={values.feedback}
              name="feedback"
              error={Boolean(touched.feedback) && Boolean(errors.feedback)}
              helperText={touched.feedback && errors.feedback}
              sx={{ gridColumn: "span 4" }}
            />
            <Box sx={{ gridColumn: "span 4", display:"flex", justifyContent:"center" }}>
              <FormControlLabel
                value={values.registeredCustomer = isRegisteredCustomer}
                name="registeredCustomer"
                // defaultValue={isRegisteredCustomer}
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleAddingRegisteredCustomer(e)
                    }}
                  />
                }
                label="Is this a client stored in the system:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>

            </Box>

            { isRegisteredCustomer === false ? (
              <Box sx={{ gridColumn: "span 4", display:"flex", justifyContent:"center" }}>
                <p>Please add the customer's contact for follow-up :</p>
                <TextField
                  label="Contact"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_number}
                  name="phone_number"
                  error={Boolean(touched.phone_number) && Boolean(errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  sx={{ marginX:'5px' }}
                />
              </Box>
            ) : (
              <Box sx={{ gridColumn: "span 4", display:"flex", justifyContent:"center" }}>
                <TextField
                  label="Select Customer"
                  onBlur={handleBlur}
                  onChange={(e, phoneNumber)=>{
                    console.log(phoneNumber)
                    handleChange(e);
                    setFieldValue(`phone_number`, phoneNumber.props.phoneNumber)
                  }}
                  value={values.related_customer}
                  fullWidth
                  select
                  name="related_customer"
                  error={Boolean(touched.related_customer) && Boolean(errors.related_customer)}
                  helperText={touched.related_customer && errors.related_customer}
                >
                  {customerList.map((customer, index)=>{
                    return (
                      <MenuItem value={customer.id} phoneNumber={customer.related_user.phone_number} selected={customer.id===values.related_customer}>{customer.related_user.first_name} {customer.related_user.last_name}</MenuItem>
                    )
                  })}
                  
                </TextField>
              </Box>
            )
            }
            <Box sx={{ gridColumn: "span 4", display:"flex", justifyContent:"center" }}>
              <p>Add a Follow-up Date:</p>
              <TextField
                label="Follow-up Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.follow_up_appointed_date}
                name="follow_up_appointed_date"
                type="Date"
                error={Boolean(touched.follow_up_appointed_date) && Boolean(errors.follow_up_appointed_date)}
                helperText={touched.follow_up_appointed_date && errors.follow_up_appointed_date}
                sx={{ marginX:'5px' }}
              />
            </Box>
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditCustomerFeedbackForm;
