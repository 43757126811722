import axios from 'axios';
const URL = "https://api.pesatrack.iolabz.ug";
// const URL = "http://localhost:8000";

// const getStore = async () => {
//   const {store} = await import('../state/index.js')
//   return store
// }

const refreshToken = async () => {
	const refresh_token = sessionStorage.getItem('refresh_token');
	try {
	  const resp = await axios.post(`${URL}/accounts/token/refresh`, {"refresh": refresh_token});
	  return resp.data;
	} catch (e) {
    //handle the logout of the application
	  console.log("Error", e);
	  }
  };

const inventoryApi = axios.create({
    baseURL: `${URL}/inventory`,
    headers: {

    }  
})

const customerApi = axios.create({
    baseURL: `${URL}/customers`,
    headers: {

    }  
})

const humanResourceApi = axios.create({
  baseURL: `${URL}/human_resource`,
  headers: {},
});

const requester = axios.create({})

requester.defaults.timeout = 120000;

requester.interceptors.request.use(
  async function (config) {
    const access_token = sessionStorage.getItem('access_token');
    const token = access_token

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.credentials = "same-origin";
    config.baseURL = URL;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

requester.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const res = await refreshToken();

      const access_token = res.access;
      sessionStorage.setItem('access_token', access_token)

      requester.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      return requester(originalRequest);
    }
    return Promise.reject(error);
  }
);

// requester.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   (error) => {
//     if (error?.response?.status === 403) {
//       // Handle forbidden error
//     }
//     if (error?.response?.status === 401) {
//       // Handle unauthorized error (e.g., log out the user)
//     }
//     throw error; // Propagate the error
//   }
// );

export default requester;

export { inventoryApi, customerApi, humanResourceApi, URL, requester };
    