
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    MenuItem,
    Autocomplete
  } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";


const AdjustmentDetailForm = (props) => {


  const {
    index,
    data,
    handleRemoveProductDetailRow,
    setPurchaseDetailFormSubmit,
    name,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [batchesForGivenItemList, setBatchesForGivenItemList] =React.useState([]);
  const [isLoadingBatchesForGivenItemList, setLoadingBatchesForGivenItemList] = React.useState(true);
  const [batchesForGivenItemError, setBatchesForGivenItemError] = React.useState(false);
  const [batchQuatity, setBatchQuantity] =React.useState(0);
  const [hasSelectedAdjustmentType, setHasSelectedAdjustmentType] = React.useState(true);
  const [hasSelectedBatch, setHasSelectedBatch] = React.useState(true);
  const [batchExpiry, setBatchExpiry] = React.useState(0);
  const [batchSmallestUnit, setBatchSmallestUnit] = React.useState("");

  const [error, setError] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
	const [isLoadingItemList, setLoadingItemList] = React.useState(true);

  const business_branch_id = useSelector((state) => state.business_branch_id);

  const fetchBatchesForGivenItemList = async (productId) => {
      try {
          const response = await requester.get(`/inventory/products/batch_list/${productId}`);
          setBatchesForGivenItemList(response.data.data);
          setLoadingBatchesForGivenItemList(false);
      } catch (e) {
          setBatchesForGivenItemError(true);
          setLoadingBatchesForGivenItemList(false);
      }
  };

  const handleBatchSelected = (e) => {
    var specificBatch = batchesForGivenItemList.find(
      (x) => x.batch === e.target.value
    );
    setBatchQuantity(specificBatch.available_stock);
    setBatchSmallestUnit(specificBatch.smallest_unit_detail.related_business_product_unit.name);
    setBatchExpiry(specificBatch.expiry_date);
  };

  
  const fetchItemList = async (search) => {
    try {
      if (search) {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
        );
        setProductList(response.data.data);
      } else {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
        );
        setProductList(response.data.data);
      }
    } catch (e) {
      setError(true);
      setLoadingItemList(false);
    }
  };
  

  const handleFormSubmit = async (values, onSubmitProps) => {
      setPurchaseDetailFormSubmit(values);
      
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <p>#{index + 1}</p>
      </Box>
      <Box
        display="grid"
        gap="5px"
        p="0.5rem"
        gridTemplateColumns="repeat(17, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        <>
          <Autocomplete
            size="small"
            onOpen={() => {
              fetchItemList(null);
            }}
            onBlur={handleBlur}
            name={`${name}.related_product`}
            
            onChange={(e, value) => {
              setFieldValue(`${name}.related_product`, value ? value.id : "");
              if (value) {
                fetchBatchesForGivenItemList(value.id);
              }
            }}
            sx={{ gridColumn: "span 4" }}
            options={productList}
            getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
            renderOption={(props, productList) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={productList.id}
              >
                {productList.product_name} {productList.description}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                label="Item Name"
                value={
                  data.related_product ? data.related_product ?? null : ""
                }
                error={Boolean( touched?.products_adjusted && touched?.products_adjusted[index]?.related_product) && Boolean(errors?.products_adjusted && errors?.products_adjusted[index]?.related_product)}
                helperText={ touched?.products_adjusted && touched?.products_adjusted[index]?.related_product && errors?.products_adjusted && errors?.products_adjusted[index]?.related_product}
                onChange={(e) => {
                  fetchItemList(e.currentTarget.value);
                }}
              />
            )}
          />

          <TextField
            label="Available Batches"
            size="small"
            onBlur={handleBlur}
            onChange={(e, value) => {
              handleChange(e);
              handleBatchSelected(e);
              setHasSelectedBatch(false);
            }}
            select
            value={data.batch}
            name={`${name}.batch`}
            error={Boolean( touched?.products_adjusted && touched?.products_adjusted[index]?.batch) && Boolean(errors?.products_adjusted && errors?.products_adjusted[index]?.batch)}
            helperText={ touched?.products_adjusted && touched?.products_adjusted[index]?.batch && errors?.products_adjusted && errors?.products_adjusted[index]?.batch}
            sx={{ gridColumn: "span 2" }}
          >
            {batchesForGivenItemList.map((oneRecord) => {
              return (
                <MenuItem value={oneRecord.batch}>{oneRecord.batch}</MenuItem>
              );
            })}
          </TextField>

          <TextField
            label="Item Unit"
            size="small"
            disabled
            value={(data.related_product_unit = batchSmallestUnit)}
            name={`${name}.related_product_unit`}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Expiry Date"
            size="small"
            type="date"
            disabled
            value={( batchExpiry)}
            name={`${name}.expiry_date`}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="System Qty"
            size="small"
            type="number"
            disabled
            onBlur={handleBlur}
            onChange={handleChange}
            value={(data.system_quantity = batchQuatity)}
            name={`${name}.system_quantity`}
            error={Boolean( touched?.products_adjusted && touched?.products_adjusted[index]?.system_quantity) && Boolean(errors?.products_adjusted && errors?.products_adjusted[index]?.system_quantity)}
            helperText={ touched?.products_adjusted && touched?.products_adjusted[index]?.system_quantity && errors?.products_adjusted && errors?.products_adjusted[index]?.system_quantity}
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Adjustment Reason"
            size="small"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setHasSelectedAdjustmentType(false);
            }}
            select
            disabled={hasSelectedBatch}
            value={data.adjustment_type}
            name={`${name}.adjustment_type`}
            error={Boolean( touched?.products_adjusted && touched?.products_adjusted[index]?.adjustment_type) && Boolean(errors?.products_adjusted && errors?.products_adjusted[index]?.adjustment_type)}
            helperText={ touched?.products_adjusted && touched?.products_adjusted[index]?.adjustment_type && errors?.products_adjusted && errors?.products_adjusted[index]?.adjustment_type}
            sx={{ gridColumn: "span 2" }}
          >
            <MenuItem value="WASTAGE">WASTAGE</MenuItem>
            <MenuItem value="DAMAGE">DAMAGE</MenuItem>
            <MenuItem value="EXPIRY">EXPIRY</MenuItem>
            <MenuItem value="INCREMENT">INCREMENT</MenuItem>
            <MenuItem value="DECREMENT">DECREMENT</MenuItem>
          </TextField>

          <TextField
            label="Physical Qty"
            size="small"
            type="number"
            inputProps={{
              min: 0,
            }}
            disabled={hasSelectedAdjustmentType}
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.quantity}
            name={`${name}.quantity`}
            error={Boolean( touched?.products_adjusted && touched?.products_adjusted[index]?.quantity) && Boolean(errors?.products_adjusted && errors?.products_adjusted[index]?.quantity)}
            helperText={ touched?.products_adjusted && touched?.products_adjusted[index]?.quantity && errors?.products_adjusted && errors?.products_adjusted[index]?.quantity}
            sx={{ gridColumn: "span 2" }}
          />

          <Button
            value={index}
            onClick={() => {
              handleRemoveProductDetailRow(index);
            }}
            size="small"
            variant="contained"
          >
            Remove
          </Button>
        </>
        {/* )} */}
      </Box>
    </Box>
  );
};

export default AdjustmentDetailForm;
  