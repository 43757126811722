import React, {useEffect, useState} from "react";
import { setRefreshToken } from "state";
import { inventoryApi, customerApi, humanResourceApi, requester, URL } from "api/pesabackend";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// ######################################### LIST CALLS ####################################################

const useRefreshToken = async () => {
	const dispatch = useDispatch()
	const refresh_token = sessionStorage.getItem('refresh_token');
	try {
	  const resp = await axios.post(URL, "/accounts/token/refresh", {"refresh": refresh_token});
	  dispatch(setRefreshToken(resp.data.access))
	  sessionStorage.setItem('access_token', resp.data.access)
	  return resp.data;
	} catch (e) {
	  console.log("Error",e);   
	}
  };

const useItemList = (pageNumber, business_branch_id) => {
    const [productList, setProductList] = useState([]);
	const [isLoadingItemList, setLoadingItemList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemData, setNewItemData] = useState(true);
	const [searchItem, setSearchItem] = useState(null);
	const [itemTypeFilter, setItemTypeFilter] = useState("all")

	let urlString;

	if (searchItem) {
		urlString = `/products/list?business_branch=${business_branch_id}&search=${searchItem}&typeFilter=${itemTypeFilter}`;
	} else {
		urlString = `/products/list?business_branch=${business_branch_id}&typeFilter=${itemTypeFilter}`;
	}
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setProductList(response.data.data);
				setLoadingItemList(false);
				setNewItemData(false)
			} catch (e) {
				setError(true);
				setLoadingItemList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemList, newItemData, searchItem, itemTypeFilter]);
	return { productList, isLoadingItemList, error, setNewItemData, setSearchItem, setItemTypeFilter };
};


const useItemUnitList = (pageNumber, business_branch_id) => {
    const [unitList, setUnitList] = useState([]);
	const [isLoadingItemUnitList, setLoadingItemUnitList] =useState(true);
	const [error, setError] = useState(false);
	const [newItemUnitData, setNewItemUnitData] = useState(true);
	const [searchUnit, setUnitSearch] = useState(false);
	
	useEffect(() => {

		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/business_product_units/list?business_branch=${business_branch_id}&search=${searchUnit}`);
				setUnitList(response.data.data);
				setLoadingItemUnitList(false);
				setNewItemUnitData(false)
			} catch (e) {
				setError(true);
				setLoadingItemUnitList(false);
			}
		};
		fetchData();
		
	}, [pageNumber,isLoadingItemUnitList, newItemUnitData, searchUnit]);
	return { unitList, isLoadingItemUnitList, error, setNewItemUnitData, setUnitSearch };
};

// TYPE TO BE CHANGED TO CATEGORY
const useItemTypeList = (pageNumber, business_branch_id) => {
    const [typeList, setTypeList] = useState([]);
	const [isLoadingItemTypeList, setLoadingItemTypeList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemTypeData, setNewItemTypeData] = useState(true);
	const [searchItemType, setItemTypeSearch] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/product_categories/list?business_branch=${business_branch_id}&search=${searchItemType}`);
				setTypeList(response.data.data);
				setLoadingItemTypeList(false);
				setNewItemTypeData(false)
			} catch (e) {
				setError(true);
				setLoadingItemTypeList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemTypeList,newItemTypeData, searchItemType]);
	return { typeList, isLoadingItemTypeList, error, setNewItemTypeData, setItemTypeSearch };
};


const useItemSubCategoryList = (pageNumber, business_branch_id) => {
    const [subCategoryList, setSubCategoryList] = useState([]);
	const [isLoadingItemSubCategoryList, setLoadingItemSubCategoryList] = useState(true);
	const [error, setError] = useState(false);
	const [newItemSubCategoryData, setNewItemSubCategoryData] = useState(true);
	const [searchItemSubCategory, setItemSubCategorySearch] = useState(false);
	const [itemCategoryFilter, setItemCategoryFilter] = useState("all");

	let urlString;

	if (searchItemSubCategory) {
		urlString = `/product_sub_categories/list?business_branch=${business_branch_id}&search=${searchItemSubCategory}&categoryFilter=${itemCategoryFilter}`;
	} else {
		urlString = `/product_sub_categories/list?business_branch=${business_branch_id}&categoryFilter=${itemCategoryFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setSubCategoryList(response.data.data);
				setLoadingItemSubCategoryList(false);
				setNewItemSubCategoryData(false)
			} catch (e) {
				setError(true);
				setLoadingItemSubCategoryList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemSubCategoryList,newItemSubCategoryData, searchItemSubCategory, itemCategoryFilter]);
	return { subCategoryList, isLoadingItemSubCategoryList, error, setNewItemSubCategoryData, setItemSubCategorySearch , setItemCategoryFilter};
};


const useSupplierList = (pageNumber, business_branch_id) => {
    const [supplierList, setSupplierList] = useState([]);
	const [isLoadingSupplierList, setLoadingSupplierList] = useState(true);
	const [error, setError] = useState(false);
	const [newSupplierData, setNewSupplierData] = useState(true);
	const [search, setSearch] = useState(false);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/suppliers/list?business_branch=${business_branch_id}&search=${search}`);
				setSupplierList(response.data.data);
				setLoadingSupplierList(false);
				setNewSupplierData(false)
			} catch (e) {
				setError(true);
				setLoadingSupplierList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingSupplierList, newSupplierData, search]);
	return { supplierList, isLoadingSupplierList, error, setNewSupplierData, setSearch };
};


const useCustomerList = (pageNumber, business_branch_id) => {
    const [customerList, setCustomerList] = useState([]);
	const [isLoadingCustomerList, setLoadingCustomerList] = useState(true);
	const [error, setError] = useState(false);
	const [newCustomerData, setNewCustomerData] = useState(true);
	const [search, setSearch] = useState(false);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await customerApi.get(`/list?business_branch=${business_branch_id}&search=${search}`);
				setCustomerList(response.data.data);
				setLoadingCustomerList(false);
				setNewCustomerData(false)
			} catch (e) {
				setError(true);
				setLoadingCustomerList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingCustomerList, newCustomerData, search]);
	return { customerList, isLoadingCustomerList, error, setNewCustomerData, setSearch };
};


const usePurchaseList = (pageNumber, business_branch_id) => {
    const [purchaseList, setPurchaseList] = useState([]);
	const [isLoadingPurchaseList, setLoadingPurchaseList] = useState(true);
	const [error, setError] = useState(false);
	const [newPurchaseData, setNewPurchaseData] = useState(true);
	const [search, setSearch] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState('all');

	let urlString;

	if (search) {
		urlString = `/product_purchase/list?business_branch=${business_branch_id}&search=${search}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/product_purchase/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setPurchaseList(response.data.data);
				setLoadingPurchaseList(false);
				setNewPurchaseData(false)
			} catch (e) {
				setError(true);
				setLoadingPurchaseList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseList, newPurchaseData, search, supplierFilter ]);
	return { purchaseList, isLoadingPurchaseList, error, setNewPurchaseData, setSearch, setSupplierFilter };
};

const useStockList = (pageNumber, business_branch_id) => {
    const [stockList, setStockList] = useState([]);
	const [isLoadingStockList, setLoadingStockList] = useState(true);
	const [error, setError] = useState(false);
	const [newStockData, setNewStockData] = useState(true);
	const [search, setSearch] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/stock/list?business_branch=${business_branch_id}&search=${search}`);
				setStockList(response.data.data);
				setLoadingStockList(false);
				setNewStockData(false)
			} catch (e) {
				setError(true);
				setLoadingStockList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingStockList, newStockData, search ]);
	return { stockList, isLoadingStockList, error, setNewStockData, setSearch };
};


const useOutOfStockList = (pageNumber, business_branch_id) => {
  const [outOfStockList, setOutOfStockList] = useState([]);
  const [isLoadingOutOfStockList, setLoadingOutOfStockList] = useState(true);
  const [error, setError] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);

  let urlString 

  if (searchFilter) {
	urlString = `/stock/out_of_stock?business_branch=${business_branch_id}&searchFilter=${searchFilter}`
  } else {
	urlString = `/stock/out_of_stock?business_branch=${business_branch_id}`
  }
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await inventoryApi.get(urlString);
        setOutOfStockList(response.data.data);
        setLoadingOutOfStockList(false);
      } catch (e) {
        setError(true);
        setLoadingOutOfStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingOutOfStockList, searchFilter]);
  return { outOfStockList, isLoadingOutOfStockList, error, setSearchFilter };
};


const useExpiredStockList = (pageNumber, business_branch_id) => {
  const [expiredStockList, setExpiredStockList] = useState([]);
  const [isLoadingExpiredStockList, setLoadingExpiredStockList] = useState(true);
  const [error, setError] = useState(false);
  const [newPeriod, setNewPeriod] = useState("TODAY");
  const [expiryStartDate, setExpiryStartDate] = useState(1);

  let urlString

  if (newPeriod==="TODAY") {
    urlString = `/stock/expired_stock?business_branch=${business_branch_id}&period=${newPeriod}`;
  } else {
    urlString = `/stock/expired_stock?business_branch=${business_branch_id}&period=${newPeriod}&expiry_start_date=${expiryStartDate}`;
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await inventoryApi.get(urlString);
        setExpiredStockList(response.data.data);
        setLoadingExpiredStockList(false);
      } catch (e) {
        setError(true);
        setLoadingExpiredStockList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingExpiredStockList, newPeriod, expiryStartDate]);
  return { expiredStockList, isLoadingExpiredStockList, error, setNewPeriod, setExpiryStartDate };
};


const useProductLogList = (pageNumber, business_branch_id) => {
    const [productLogList, setProductLogList] = React.useState([]);
	const [isLoadingProductLogList, setLoadingProductLogList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [search, setSearch] = React.useState(false);
	const [filter, setFilter] = React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});
	const [action, setAction] = React.useState("all");

	let urlString;

	if (search) {
		urlString = `/stock_log/list?business_branch=${business_branch_id}&action=${action}&search=${search}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/stock_log/list?business_branch=${business_branch_id}&action=${action}&start=${filter.start}&end=${filter.end}`;
  }

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setProductLogList(response.data.data);
				setLoadingProductLogList(false);
			} catch (e) {
				setError(true);
				setLoadingProductLogList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingProductLogList, search, action, filter ]);
	return { productLogList, isLoadingProductLogList, error, setSearch, setAction, setFilter };
};


const usePurchaseOrderList = (pageNumber, business_branch_id) => {
    const [purchaseOrderList, setPurchaseOrderList] = useState([]);
	const [isLoadingPurchaseOrderList, setLoadingPurchaseOrderList] = useState(true);
	const [errorPurchaseOrders, setErrorPurchaseOrders] = useState(false);
	const [searchPurchaseOrder, setSearchPurchaseOrder] = useState(false);
	const [supplierFilter, setSupplierFilter] = useState("all");

	let urlString;

	if (searchPurchaseOrder) {
    urlString = `/purchase_order/list?business_branch=${business_branch_id}&search=${searchPurchaseOrder}&supplierFilter=${supplierFilter}`;
	} else {
		urlString = `/purchase_order/list?business_branch=${business_branch_id}&supplierFilter=${supplierFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setPurchaseOrderList(response.data.data);
				setLoadingPurchaseOrderList(false);
			} catch (e) {
				setErrorPurchaseOrders(true);
				setLoadingPurchaseOrderList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPurchaseOrderList, searchPurchaseOrder, supplierFilter ]);
	return { purchaseOrderList, isLoadingPurchaseOrderList, errorPurchaseOrders, setSearchPurchaseOrder, setSupplierFilter };
};


const useAdjustmentsList = (pageNumber, business_branch_id) => {
    const [adjustmentList, setAdjustmentList] = React.useState([]);
	const [isLoadingAdjustmentList, setLoadingAdjustmentList] = React.useState(true);
	const [errorAdjustmentList, setErrorAdjustmentList] = React.useState(false);
	const [filter, setFilter] =  React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/stock_adjustment/list?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`);
				setAdjustmentList(response.data.data);
				setLoadingAdjustmentList(false);
			} catch (e) {
				setErrorAdjustmentList(true);
				setLoadingAdjustmentList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingAdjustmentList, filter ]);
	return { adjustmentList, isLoadingAdjustmentList, errorAdjustmentList, setFilter };
};


const useDepartmentsList = (pageNumber, business_branch_id) => {
    const [departmentList, setDepartmentList] = useState([]);
	const [isLoadingDepartmentList, setLoadingDepartmentList] = useState(true);
	const [errorDepartmentList, setErrorDepartmentList] = useState(false);
	const [newDepartmentData, setNewDepartmentData] = useState(true);
	const [searchDepartments, setSearchDepartments] = useState(false);

	let urlString;

	if (searchDepartments) {
		urlString = `/human_resouce/departments?business_branch=${business_branch_id}&search=${searchDepartments}`;
	} else {
		urlString = `/human_resource/departments?business_branch=${business_branch_id}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setDepartmentList(response.data.data);
				setLoadingDepartmentList(false);
				setNewDepartmentData(false);
			} catch (e) {
				setErrorDepartmentList(true);
				setLoadingDepartmentList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingDepartmentList, searchDepartments, newDepartmentData ]);
	return { departmentList, isLoadingDepartmentList, errorDepartmentList, setSearchDepartments, setNewDepartmentData };
};


const useEmployeesList = (pageNumber, business_branch_id, token) => {
    const [employeeList, setEmployeeList] = useState([]);
	const [isLoadingEmployeeList, setLoadingEmployeeList] = useState(true);
	const [errorEmployeeList, setErrorEmployeeList] = useState(false);
	const [searchEmployees, setSearchEmployees] = useState(false);
	const [newEmployeeData, setNewEmployeeData] = useState(true);
	const [departmentFilter, setDepartmentFilter] = useState("all");

	let urlString;

	if (searchEmployees) {
		urlString = `/human_resource/employees?business_branch=${business_branch_id}&search=${searchEmployees}&departmentFilter=${departmentFilter}`;
	} else {
		urlString = `/human_resource/employees?business_branch=${business_branch_id}&departmentFilter=${departmentFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setEmployeeList(response.data.data);
				setLoadingEmployeeList(false);
				setNewEmployeeData(false);
			} catch (e) {
				setErrorEmployeeList(true);
				setLoadingEmployeeList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeeList, searchEmployees, newEmployeeData, departmentFilter ]);
	return { employeeList, isLoadingEmployeeList, errorEmployeeList, setSearchEmployees, setNewEmployeeData, setDepartmentFilter };
};


const useShiftsList = (pageNumber, business_branch_id) => {
    const [shiftList, setShiftList] = useState([]);
	const [isLoadingShiftList, setLoadingShiftList] = useState(true);
	const [errorShiftList, setErrorShiftList] = useState(false);
	const [newShiftData, setNewShiftData] = useState(true);
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await humanResourceApi.get(`/shifts?business_branch=${business_branch_id}`);
				setShiftList(response.data.data);
				setLoadingShiftList(false);
				setNewShiftData(false);
			} catch (e) {
				setErrorShiftList(true);
				setLoadingShiftList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingShiftList, newShiftData ]);
	return { shiftList, isLoadingShiftList, errorShiftList, setNewShiftData };
};


const useEmployeeDocumentsList = (pageNumber, employeeId) => {
  const [employeeDocumentList, setEmployeeDocumentList] = useState([]);
  const [isLoadingEmployeeDocumentList, setLoadingEmployeeDocumentList] = useState(true);
  const [errorEmployeeDocumentList, setErrorEmployeeDocumentList] = useState(false);
  const [newEmployeeDocumentData, setNewEmployeeDocumentData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/employees/documents?employee=${employeeId}`
        );
        setEmployeeDocumentList(response.data.data);
        setLoadingEmployeeDocumentList(false);
        setNewEmployeeDocumentData(false);
      } catch (e) {
        setErrorEmployeeDocumentList(true);
        setLoadingEmployeeDocumentList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeDocumentList, newEmployeeDocumentData]);
  return { employeeDocumentList, isLoadingEmployeeDocumentList, errorEmployeeDocumentList, setNewEmployeeDocumentData };
};


const useRenumerationTypesList = (pageNumber, business_branch_id) => {
  const [renumerationTypeList, setRenumerationTypeList] = useState([]);
  const [isLoadingRenumerationTypeList, setLoadingRenumerationTypeList] = useState(true);
  const [errorRenumerationTypeList, setErrorRenumerationTypeList] = useState(false);
  const [newRenumerationTypeData, setNewRenumerationTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/renumeration?business_branch=${business_branch_id}`
        );
        setRenumerationTypeList(response.data.data);
        setLoadingRenumerationTypeList(false);
        setNewRenumerationTypeData(false);
      } catch (e) {
        setErrorRenumerationTypeList(true);
        setLoadingRenumerationTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingRenumerationTypeList, newRenumerationTypeData]);
  return { renumerationTypeList, isLoadingRenumerationTypeList, errorRenumerationTypeList, setNewRenumerationTypeData };
};


const useDeductionTypesList = (pageNumber, business_branch_id) => {
  const [deductionTypeList, setDeductionTypeList] = useState([]);
  const [isLoadingDeductionTypeList, setLoadingDeductionTypeList] = useState(true);
  const [errorDeductionTypeList, setErrorDeductionTypeList] = useState(false);
  const [newDeductionTypeData, setNewDeductionTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/deduction?business_branch=${business_branch_id}`
        );
        setDeductionTypeList(response.data.data);
        setLoadingDeductionTypeList(false);
        setNewDeductionTypeData(false);
      } catch (e) {
        setErrorDeductionTypeList(true);
        setLoadingDeductionTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingDeductionTypeList, newDeductionTypeData]);
  return { deductionTypeList, isLoadingDeductionTypeList, errorDeductionTypeList, setNewDeductionTypeData };
};


const useAllowanceTypesList = (pageNumber, business_branch_id) => {
  const [allowanceTypeList, setAllowanceTypeList] = useState([]);
  const [isLoadingAllowanceTypeList, setLoadingAllowanceTypeList] = useState(true);
  const [errorAllowanceTypeList, setErrorAllowanceTypeList] = useState(false);
  const [newAllowanceTypeData, setNewAllowanceTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/allowance?business_branch=${business_branch_id}`
        );
        setAllowanceTypeList(response.data.data);
        setLoadingAllowanceTypeList(false);
        setNewAllowanceTypeData(false);
      } catch (e) {
        setErrorAllowanceTypeList(true);
        setLoadingAllowanceTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingAllowanceTypeList, newAllowanceTypeData]);
  return { allowanceTypeList, isLoadingAllowanceTypeList, errorAllowanceTypeList, setNewAllowanceTypeData };
};


const useEmployeeRenumerationsList = (pageNumber, employee) => {
  const [employeeRenumerationList, setEmployeeRenumerationList] = useState([]);
  const [isLoadingEmployeeRenumerationList, setLoadingEmployeeRenumerationList] = useState(true);
  const [errorEmployeeRenumerationList, setErrorEmployeeRenumerationList] = useState(false);
  const [newEmployeeRenumerationData, setNewEmployeeRenumerationData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/employees/payroll?payroll_type=renumeration&employee=${employee}`
        );
        setEmployeeRenumerationList(response.data.data);
        setLoadingEmployeeRenumerationList(false);
        setNewEmployeeRenumerationData(false);
      } catch (e) {
        setErrorEmployeeRenumerationList(true);
        setLoadingEmployeeRenumerationList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeRenumerationList, newEmployeeRenumerationData]);
  return { employeeRenumerationList, isLoadingEmployeeRenumerationList, errorEmployeeRenumerationList, setNewEmployeeRenumerationData };
};


const useEmployeeDeductionsList = (pageNumber, employee) => {
  const [employeeDeductionList, setEmployeeDeductionList] = useState([]);
  const [isLoadingEmployeeDeductionList, setLoadingEmployeeDeductionList] = useState(true);
  const [errorEmployeeDeductionList, setErrorEmployeeDeductionList] = useState(false);
  const [newEmployeeDeductionData, setNewEmployeeDeductionData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/employees/payroll?payroll_type=deduction&employee=${employee}`
        );
        setEmployeeDeductionList(response.data.data);
        setLoadingEmployeeDeductionList(false);
        setNewEmployeeDeductionData(false);
      } catch (e) {
        setErrorEmployeeDeductionList(true);
        setLoadingEmployeeDeductionList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeDeductionList, newEmployeeDeductionData]);
  return { employeeDeductionList, isLoadingEmployeeDeductionList, errorEmployeeDeductionList, setNewEmployeeDeductionData };
};


const useEmployeeAllowancesList = (pageNumber, employee) => {
  const [employeeAllowanceList, setEmployeeAllowanceList] = useState([]);
  const [isLoadingEmployeeAllowanceList, setLoadingEmployeeAllowanceList] = useState(true);
  const [errorEmployeeAllowanceList, setErrorEmployeeAllowanceList] = useState(false);
  const [newEmployeeAllowanceData, setNewEmployeeAllowanceData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(
          `/employees/payroll?payroll_type=allowance&employee=${employee}`
        );
        setEmployeeAllowanceList(response.data.data);
        setLoadingEmployeeAllowanceList(false);
        setNewEmployeeAllowanceData(false);
      } catch (e) {
        setErrorEmployeeAllowanceList(true);
        setLoadingEmployeeAllowanceList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeAllowanceList, newEmployeeAllowanceData]);
  return { employeeAllowanceList, isLoadingEmployeeAllowanceList, errorEmployeeAllowanceList, setNewEmployeeAllowanceData };
};


const usePayslipsList = (pageNumber, business_branch_id) => {
    const [payslipList, setPayslipList] = React.useState([]);
	const [isLoadingPayslipList, setLoadingPayslipList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newPayslipData, setNewPayslipData] = React.useState(true);
	const [search, setSearch] = React.useState(false);
	const [filter, setFilter] = React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (search) {
		urlString = `/payslip?business_branch=${business_branch_id}&search=${search}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/payslip?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await humanResourceApi.get(urlString);
				setPayslipList(response.data.data);
				setLoadingPayslipList(false);
				setNewPayslipData(false)
			} catch (e) {
				setError(true);
				setLoadingPayslipList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPayslipList, newPayslipData, search, filter ]);
	return { payslipList, isLoadingPayslipList, error, setNewPayslipData, setSearch, setFilter };
};


const useEmployeePayslipsList = (pageNumber, employee) => {
    const [employeePayslipList, setEmployeePayslipList] = useState([]);
	const [isLoadingEmployeePayslipList, setLoadingEmployeePayslipList] = useState(true);
	const [error, setError] = useState(false);
	const [filterRange, setFilterRange] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")})


	let urlString  = `/employees/payslip?employee=${employee}&start=${filterRange.start}&end=${filterRange.end}`;
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await humanResourceApi.get(urlString);
				setEmployeePayslipList(response.data.data);
				setLoadingEmployeePayslipList(false);
			} catch (e) {
				setError(true);
				setLoadingEmployeePayslipList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeePayslipList, filterRange ]);
	return { employeePayslipList, isLoadingEmployeePayslipList, error, setFilterRange };
};



const useEmployeeShiftsList = (pageNumber, business_branch_id) => {
    const [employeeShiftList, setEmployeeShiftList] = React.useState([]);
	const [isLoadingEmployeeShiftList, setLoadingEmployeeShiftList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newEmployeeShiftData, setNewEmployeeShiftData] = React.useState(true);
	const [employee, setEmployee] = React.useState(false);
	const [filter, setFilter] = React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (employee) {
		urlString = `/employees/shifts?business_branch=${business_branch_id}&employee=${employee}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/employees/shifts?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await humanResourceApi.get(urlString);
				setEmployeeShiftList(response.data.data);
				setLoadingEmployeeShiftList(false);
				setNewEmployeeShiftData(false)
			} catch (e) {
				setError(true);
				setLoadingEmployeeShiftList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeeShiftList, newEmployeeShiftData, employee, filter ]);
	return { employeeShiftList, isLoadingEmployeeShiftList, error, setNewEmployeeShiftData, setEmployee, setFilter };
};


const useGivenPeriodShiftsList = (business_branch_id) => {
    const [givenPeriodShiftList, setGivenPeriodShiftList] = React.useState([]);
	const [isLoadingGivenPeriodShiftList, setLoadingGivenPeriodShiftList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newGivenPeriodShiftData, setNewGivenPeriodShiftData] = React.useState(true);
	const [filter, setFilter] = React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString =`/shifts/calendar?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await humanResourceApi.get(urlString);
				setGivenPeriodShiftList(response.data.data);
				setLoadingGivenPeriodShiftList(false);
				setNewGivenPeriodShiftData(false)
			} catch (e) {
				setError(true);
				setLoadingGivenPeriodShiftList(false);
			}
		};
		fetchData();
	}, [isLoadingGivenPeriodShiftList, newGivenPeriodShiftData, filter ]);
	return { givenPeriodShiftList, isLoadingGivenPeriodShiftList, error, setNewGivenPeriodShiftData, setFilter };
};




// ######################################### SINGLE RECORD CALLS ####################################################

const useGetItem = (id) => {
    const [products, setProducts] = useState("");
	const [isLoadingProducts, setLoadingProducts] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/products/get/${id}`);
				setProducts(response.data.data);
				setLoadingProducts(false);
			} catch (e) {
				setError(true);
				setLoadingProducts(false);
			}
		};
		fetchData();
	}, [id,isLoadingProducts]);
	return { products, isLoadingProducts, error };
};



const useGetItemType = (id) => {
    const [itemType, setItemType] = useState("");
	const [isLoadingItemType, setLoadingItemType] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/product_categories/get/${id}`);
				setItemType(response.data.data);
				setLoadingItemType(false);
			} catch (e) {
				setError(true);
				setLoadingItemType(false);
			}
		};
		fetchData();
	}, [id,isLoadingItemType]);
	return { itemType, isLoadingItemType, error };
};


const useGetItemUnit = (id) => {
    const [itemUnit, setItemUnit] = useState("");
	const [isLoadingitemUnit, setLoadingitemUnit] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/business_product_units/get/${id}`);
				setItemUnit(response.data.data);
				setLoadingitemUnit(false);
			} catch (e) {
				setError(true);
				setLoadingitemUnit(false);
			}
		};
		fetchData();
	}, [id,isLoadingitemUnit]);
	return { itemUnit, isLoadingitemUnit, error };
};


const useGetUnitsForGivenItem = (id) => {
    const [unitsForGivenItemList, setUnitsForGivenItemList] = useState("");
	const [isLoadingUnitsForGivenItemList, setLoadingUnitsForGivenItemList] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/products/unit_list/${id}`);
				setUnitsForGivenItemList(response.data.data);
				setLoadingUnitsForGivenItemList(false);
			} catch (e) {
				setError(true);
				setLoadingUnitsForGivenItemList(false);
			}
		};
		fetchData();
	}, [id,isLoadingUnitsForGivenItemList]);
	return { unitsForGivenItemList, isLoadingUnitsForGivenItemList, error };
};

const useGetBatchesForGivenItem = (id) => {
    const [batchList, setBatchList] = useState([]);
	const [isLoadingItemBatchList, setLoadingItemBatchList] = useState(true);
	const [batchesForGivenItemError, setBatchesForGivenItemError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/products/batch_list?id=${id}`);
				setBatchList(response.data.data);
				setLoadingItemBatchList(false);
			} catch (e) {
				setBatchesForGivenItemError(true);
				setLoadingItemBatchList(false);
			}
		};
		fetchData();
	}, [id,isLoadingItemBatchList]);
	return { batchList, isLoadingItemBatchList, batchesForGivenItemError };
};


const useGetSupplier = (id) => {
    const [supplier, setSupplier] = useState("");
	const [isLoadingSupplier, setLoadingSupplier] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/suppliers/get/${id}`);
				setSupplier(response.data.data);
				setLoadingSupplier(false);
			} catch (e) {
				setError(true);
				setLoadingSupplier(false);
			}
		};
		fetchData();
	}, [id,isLoadingSupplier]);
	return { supplier, isLoadingSupplier, error };
};

const useGetCustomer = (id) => {
    const [customer, setCustomer] = useState("");
	const [isLoadingCustomer, setLoadingCustomer] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/customers/get/${id}`);
				setCustomer(response.data.data);
				setLoadingCustomer(false);
			} catch (e) {
				setError(true);
				setLoadingCustomer(false);
			}
		};
		fetchData();
	}, [id,isLoadingCustomer]);
	return { customer, isLoadingCustomer, error };
};


const useGetPurchase = (id) => {
    const [purchase, setPurchase] = useState("");
	const [isLoadingPurchase, setLoadingPurchase] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(`/product_purchase/get/${id}`);
				setPurchase(response.data.data);
				setLoadingPurchase(false);
			} catch (e) {
				setError(true);
				setLoadingPurchase(false);
			}
		};
		fetchData();
	}, [id,isLoadingPurchase]);
	return { purchase, isLoadingPurchase, error };
};


const useGetEmployee = (id) => {
  const [employee, setEmployee] = useState("");
  const [isLoadingEmployee, setLoadingEmployee] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await humanResourceApi.get(`/employees/get/${id}`);
        setEmployee(response.data.data);
        setLoadingEmployee(false);
      } catch (e) {
        setError(true);
        setLoadingEmployee(false);
      }
    };
    fetchData();
  }, [id, isLoadingEmployee]);
  return { employee, isLoadingEmployee, error };
};






// ################################# REPORT CALLS ##########################################################



const useItemPurchaseReportList = (pageNumber, business_branch_id) => {
    const [itemPurchaseReportList, setItemPurchaseReportList] = React.useState([]);
	const [isLoadingItemPurchaseReportList, setLoadingItemPurchaseReportList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [supplier, setSupplier] = React.useState('all');
	const [filter, setFilter] = React.useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});


	let urlString;

	if (supplier !==('all')) {
		urlString = `/reports/item_purchase?business_branch=${business_branch_id}&supplier=${supplier}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/reports/item_purchase?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await inventoryApi.get(urlString);
				setItemPurchaseReportList(response.data.data);
				setLoadingItemPurchaseReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemPurchaseReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemPurchaseReportList, supplier, filter ]);
	return { itemPurchaseReportList, isLoadingItemPurchaseReportList, error, setSupplier, setFilter };
};
 
export {useItemList, useItemUnitList, useItemTypeList, useSupplierList, usePurchaseList, useStockList, useGivenPeriodShiftsList, useEmployeeShiftsList, useCustomerList, useProductLogList, usePurchaseOrderList, useAdjustmentsList, useOutOfStockList, useRenumerationTypesList, useDeductionTypesList, useAllowanceTypesList, useExpiredStockList, useDepartmentsList, useEmployeesList, useShiftsList, useItemSubCategoryList, useEmployeeDocumentsList, useEmployeeAllowancesList, useEmployeeDeductionsList,useEmployeeRenumerationsList, usePayslipsList, useEmployeePayslipsList, useGetItem, useGetItemType, useGetItemUnit, useGetPurchase, useGetSupplier, useGetCustomer, useGetUnitsForGivenItem, useGetBatchesForGivenItem, useGetEmployee, useItemPurchaseReportList, useRefreshToken}
