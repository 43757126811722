import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePurchaseRequisitionList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import moment from "moment";
import PurchaseRequisitionViewForm from "../forms/purchaseRequisitionForms/purchaseRequisitionViewForm";
import { requester, } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

     

export default function PurchaseRequisition ({setIsSuccess}) {
  
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {purchaseRequisitionList, isLoadingPurchaseRequisitionList, setFilter, setSearch, setNewPurchaseRequisitionData} = usePurchaseRequisitionList(1, business_branch_id);
  
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")
  const [clickedItem, setClickedItem] = useState('')

   const [showFiter, setShowFilter] = useState(false);
   const [startDate, setStartDate] = useState(
     moment().startOf("month").format("YYYY-MM-DD")
   );
   const [endDate, setEndDate] = useState(
     moment().endOf("month").format("YYYY-MM-DD")
   );

   const handleChangePeriodFilter = async (e) => {
     if (e.target.value === "THIS MONTH") {
       setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
       setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
       setShowFilter(false);
     }
     if (e.target.value === "THIS WEEK") {
       setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
       setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
       setShowFilter(false);
     }
     if (e.target.value === "TODAY") {
       setStartDate(moment().format("YYYY-MM-DD"));
       setEndDate(moment().format("YYYY-MM-DD"));
       setShowFilter(false);
     }
     if (e.target.value === "CUSTOM") {
       setShowFilter(true);
     }
   };

   const handleClickFilter = () => {
     setFilter({ start: startDate, end: endDate });
   };

  const handleClickEditOpen = async (e) => {
  const response = await requester.get(`/inventory/purchase_requisition/get/${e.target.value}`);
  if (response.status === 200) {
    navigate("/editPurchaseRequisitionForm", { state: response.data.data });
  } 
  };

  const handleClickEditClose = () => {
  setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
  const response = await requester.get(`/inventory/purchase_requisition/get/${e.target.value}`);
  if (response.status===200){
    setClickedItem(e.target.value);
    setOpenDelete(true);
  }
  };

  const handleClickDeleteClose = () => {
  setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/inventory/purchase_requisition/delete/${clickedItem}`);
    if (response.status===201){
      setIsSuccess(true)
      setNewPurchaseRequisitionData(true)
      setOpenDelete(false);
    }
  };


  const handleClickApprove = async () => {
    const response = await requester.post(`/inventory/purchase_requisition/approve/${clickedItem}`);
    if (response.status === 201) {
      setIsSuccess(true);
      setNewPurchaseRequisitionData(true);
      setOpenView(false);
    }
  };

  const handleClickView = async (e) => {
    const response = await requester.get(`/inventory/purchase_requisition/get_form/${e.target.value}`);
    if (response.status === 200) {
      setClickedItem(e.target.value);
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h4>Purchase Requisition List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Requisition Number"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY REQUISITION PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS WEEK"
                  size="small"
                  onChange={(e) => {
                    handleChangePeriodFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>
              
              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                        label="Start Date"
                        type="date"
                        defaultValue={startDate}
                        size="small"
                        onChange={(e) => setStartDate(e.target.value)}
                        // sx={{
                        //   m: "10px",
                        //   backgroundColor: "white",
                        // }}
                      />
                  </Paper>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                        label="End Date"
                        type="date"
                        defaultValue={endDate}
                        size="small"
                        onChange={(e) => setEndDate(e.target.value)}
                        // sx={{
                        //   m: "10px",
                        //   backgroundColor: "white",
                        // }}
                      />
                  </Paper>
                  
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={2} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{m: "10px",}} 
              onClick={() =>
                navigate("/purchaseRequisitionForm", { formType: "create" })
              }
            >
              ADD REQUISITION
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Date Added</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>
                    Requisition Number
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>
                    Requisition Date
                  </StyledTableCell>
                  {/* <StyledTableCell style={{ minWidth: 50 }}>Grand Total</StyledTableCell> */}
                  <StyledTableCell style={{ minWidth: 50 }}>Created by</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Approved by</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {purchaseRequisitionList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  purchaseRequisitionList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date_added).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>{row.requisition_date}</StyledTableCell>
                          {/* <StyledTableCell>{row.grand_total}</StyledTableCell> */}
                          <StyledTableCell>
                            {row.added_by.first_name} {row.added_by.last_name}
                          </StyledTableCell>
                          <StyledTableCell>{row.approved_by? row.approved_by.first_name +" " +row.approved_by.last_name: null}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            {row.status === 0 ? (
                              <>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickView(e)}
                                >
                                  View & Approve
                                </Button>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickEditOpen(e)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickDeleteOpen(e)}
                                >
                                  Delete
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickView(e)}
                                >
                                  View
                                </Button>
                                {/* <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickView(e)}
                                >
                                  Use Template
                                </Button> */}
                              </>
                            )}
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Order"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"lg"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <PurchaseRequisitionViewForm
                                  viewData={viewData}
                                  handleClickApprove={handleClickApprove}
                                />
                              </Box>
                            }
                          />

                          <FixedSizeDialog
                            dialogHeading="Are  you sure you want to delete this requisition?"
                            open={openDelete}
                            handleClose={handleClickDeleteClose}
                            negativeResponse="Cancel"
                            maxWidth={"sm"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  onClick={handleClickDelete}
                                  sx={{ m: "1rem 0", p: "1rem" }}
                                >
                                  Yes, Delete
                                </Button>
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={purchaseRequisitionList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};