
import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete} from "@mui/material";
import {useState} from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";




const PurchaseDraftDetailForm = (props) => {


    const {index, 
        data,
        handleRemoveProductDetailRow, 
        handleItemAmountChange,
        handleItemDiscountChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue
        } = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    
	const [error, setError] = useState(false);
    const [productList, setProductList] = useState(data.product_info ? [data.product_info] : []);
    const business_branch_id = useSelector((state) => state.business_branch_id);
    
    const [ unitCost, setUnitCost] = useState(0)
    const [unitsForGivenItemList, setUnitsForGivenItemList] = useState(data.product_units_list ? data.product_units_list : []);

    const fetchUnitsForGivenItemList = async (productId) => {
        try {
            const response = await requester.get(`/inventory/products/unit_list/${productId}`);
            setUnitsForGivenItemList(response.data.data);
        } catch (e) {
            setError(true);
        }
    };

    const handleUnitCostChange = (Quantity, Amount) => {
      setUnitCost((Number(Amount)/Number(Quantity)).toFixed(2));
    };
    

    const fetchItemList = async (search) => {
        try {
            if (search){
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
                );
                setProductList(response.data.data);
            } else {
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
                );
                setProductList(response.data.data);
            }
            
        } catch (e) {
            setError(true);
        }
    };
    
    return (

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="5px"
                p="0.5rem"
                gridTemplateColumns="repeat(18, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>  
                    <Autocomplete
                        size="small"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        name={`${name}.related_product`}
                        onChange={(e, value)=>{
                            if (value) {fetchUnitsForGivenItemList(value.id);};
                            setFieldValue(
                                `${name}.related_product`,
                                value ? value.id : ''
                              );
                            setFieldValue(`${name}.related_product_unit`, '');
                        }}
                        sx={{ gridColumn: "span 3" }}
                        options={productList}
                        defaultValue = {productList[0]}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id}>
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{
                                    ...params.inputProps
                                }}
                                label="Item Name"
                                value={data.related_product ? data.related_product ?? null : ''}
                                error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.related_product) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.related_product)}
                                helperText={ touched?.products_purchased && touched?.products_purchased[index]?.related_product && errors?.products_purchased && errors?.products_purchased[index]?.related_product}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                    />
                    
                    



                    <TextField
                        label="Item Unit"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={data.related_product_unit}
                        name={`${name}.related_product_unit`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.related_product_unit) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.related_product_unit)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.related_product_unit && errors?.products_purchased && errors?.products_purchased[index]?.related_product_unit}
                        sx={{ gridColumn: "span 2" }}
                    >
                        {unitsForGivenItemList.map((oneUnit, index)=>{
                            return(
                                <MenuItem key={index} value= {oneUnit.id} selected={oneUnit.id===data.related_product_unit ? true :false }>{oneUnit.related_business_product_unit.name}</MenuItem>
                            );
                        })}
                        
                    </TextField>
                    
                    <TextField
                        label="Batch"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.batch}
                        name={`${name}.batch`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.batch) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.batch)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.batch && errors?.products_purchased && errors?.products_purchased[index]?.batch}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Expiry Date"
                        size="small"
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.expiry_date}
                        name={`${name}.expiry_date`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.expiry_date) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.expiry_date)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.expiry_date && errors?.products_purchased && errors?.products_purchased[index]?.expiry_date}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Quantity"
                        size="small"
                        type="number"
                        inputProps={{
                            min: 0
                        }}
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleUnitCostChange(e.target.value, data.amount);
                        }}
                        value={data.quantity_purchased}
                        name={`${name}.quantity_purchased`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.quantity_purchased) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.quantity_purchased)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.quantity_purchased && errors?.products_purchased && errors?.products_purchased[index]?.quantity_purchased}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Unit Cost"
                        size="small"
                        type="number"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.unit_cost=unitCost}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.unit_cost`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.unit_cost) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.unit_cost)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.unit_cost && errors?.products_purchased && errors?.products_purchased[index]?.unit_cost}
                        sx={{ gridColumn: "span 2" }}
                    /> 
                    <TextField
                        label="Amount"
                        size="small"
                        type="number"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleItemAmountChange(e,index);
                            handleUnitCostChange(data.quantity_purchased,e.target.value);
                        }}
                        value={data.amount}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.amount`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.amount) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.amount)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.amount && errors?.products_purchased && errors?.products_purchased[index]?.amount}
                        sx={{ gridColumn: "span 2" }}
                    />                                      
                    <TextField
                        label="Disount"
                        size="small"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleItemDiscountChange(e,index);
                        }}
                        value={data.discount}
                        type="number"
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.discount`}
                        error={Boolean( touched?.products_purchased && touched?.products_purchased[index]?.discount) && Boolean(errors?.products_purchased && errors?.products_purchased[index]?.discount)}
                        helperText={ touched?.products_purchased && touched?.products_purchased[index]?.discount && errors?.products_purchased && errors?.products_purchased[index]?.discount}
                        sx={{ gridColumn: "span 2" }}
                    />
                    {index > 0 ? 
                        <Button
                            value={index}
                            onClick={() => {
                                handleRemoveProductDetailRow(index);
                            }}                                                     
                            size="small"
                            variant="contained"
                            sx={{ 
                                width: "20%",
                            }}
                        >
                            Remove
                        </Button> : null
                    }
                </>
            </Box>
        </Box>               
                    
    );
  };
  
  export default PurchaseDraftDetailForm;
  