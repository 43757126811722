import { useEffect, useState } from "react";
import requester from "api/pesabackend";

const useGetBusinessBranchList = (pageNumber, business) => {
  const [businessBranchList, setBusinessBranchList] = useState([]);
  const [isLoadingBusinessBranchList, setLoadingBusinessBranchList] = useState(true);
  const [errorBusinessBranchList, setErrorBusinessBranchList] = useState(false);
  const [newBusinessBranchData, setNewBusinessBranchData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(`/tenants/business_branch/list?business=${business}`);
        setBusinessBranchList(response.data.data);
        setLoadingBusinessBranchList(false);
        setErrorBusinessBranchList(false);
        setNewBusinessBranchData(false);
      } catch (e) {
        setErrorBusinessBranchList(true);
        setLoadingBusinessBranchList(false);
      }
    };
    fetchData();
  }, [pageNumber, business, newBusinessBranchData]);
  return { businessBranchList, isLoadingBusinessBranchList, errorBusinessBranchList, setNewBusinessBranchData };
};



const useGetBusinessBranch = (id) => {
  const [businessBranch, setBusinessBranch] = useState("");
	const [isLoadingBusinessBranch, setLoadingBusinessBranch] = useState(true);
	const [error, setError] = useState(false);
  const [newBusinessData, setNewBusinessData] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/tenants/business_branch/get/${id}`);
				setBusinessBranch(response.data.data);
				setLoadingBusinessBranch(false);
        		setNewBusinessData(false);
			} catch (e) {
				setError(true);
				setLoadingBusinessBranch(false);
			}
		};
		fetchData();
	}, [id,isLoadingBusinessBranch, newBusinessData]);
	return { businessBranch, isLoadingBusinessBranch, error, setNewBusinessData };
};


const useGetBusiness = (id) => {
  const [business, setBusiness] = useState("");
	const [isLoadingBusiness, setLoadingBusiness] = useState(true);
	const [error, setError] = useState(false);
  const [newBusinessData, setNewBusinessData] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/tenants/business/get/${id}`);
				setBusiness(response.data.data);
				setLoadingBusiness(false);
        setNewBusinessData(false);
			} catch (e) {
				setError(true);
				setLoadingBusiness(false);
			}
		};
		fetchData();
	}, [id,isLoadingBusiness, newBusinessData]);
	return { business, isLoadingBusiness, error, setNewBusinessData };
};

export { useGetBusinessBranchList, useGetBusinessBranch, useGetBusiness };
