
import {Box,Button,TextField,useMediaQuery,MenuItem} from "@mui/material";
import * as React from "react";


const ItemUnitDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveItemUnitRow,  
        handleItemUnitChange,
        handleNumberOfSmallestUnitChange,
        handleSellingPriceChange,
        unitList,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <p>#{index + 1}</p>
        </Box>
        <Box
          display="grid"
          gap="5px"
          p="0.5rem"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
          }}
        >
          <>
            <TextField
              label="Unit Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={data.related_business_product_unit.id}
              name={`${name}.related_business_product_unit`}
              select
              size="small"
              error={
                Boolean(
                  touched?.product_units_list &&
                    touched?.product_units_list[index]?.related_business_product_unit
                ) &&
                Boolean(
                  errors?.product_units_list &&
                    errors?.product_units_list[index]?.related_business_product_unit
                )
              }
              helperText={
                touched?.product_units_list &&
                touched?.product_units_list[index]?.related_business_product_unit &&
                errors?.product_units_list &&
                errors?.product_units_list[index]?.related_business_product_unit
              }
              sx={{ gridColumn: "span 4" }}
            >
              {unitList.map((row, index) => {
                return (
                  <MenuItem key={index} value={row.id} selected={row.id===data.related_business_product_unit.id ? true :false }>
                    {row.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Qty in Smallest Units"
              size="small"
              type="number"
              inputProps={{
                min: 0,
              }}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                handleNumberOfSmallestUnitChange(e, index);
              }}
              value={data.number_of_smallest_units}
              name={`${name}.number_of_smallest_units`}
              error={
                Boolean(
                  touched?.product_units_list &&
                    touched?.product_units_list[index]?.number_of_smallest_units
                ) &&
                Boolean(
                  errors?.product_units_list &&
                    errors?.product_units_list[index]?.number_of_smallest_units
                )
              }
              helperText={
                touched?.product_units_list &&
                touched?.product_units_list[index]?.number_of_smallest_units &&
                errors?.product_units_list &&
                errors?.product_units_list[index]?.number_of_smallest_units
              }
              sx={{ gridColumn: "span 3" }}
            />

            <TextField
              label="Selling Price"
              size="small"
              type="number"
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                handleSellingPriceChange(e, index);
              }}
              value={data.unit_selling_price}
              inputProps={{
                min: 0,
              }}
              name={`${name}.unit_selling_price`}
              error={
                Boolean(
                  touched?.product_units_list &&
                    touched?.product_units_list[index]?.unit_selling_price
                ) &&
                Boolean(
                  errors?.product_units_list &&
                    errors?.product_units_list[index]?.unit_selling_price
                )
              }
              helperText={
                touched?.product_units_list &&
                touched?.product_units_list[index]?.unit_selling_price &&
                errors?.product_units_list &&
                errors?.product_units_list[index]?.unit_selling_price
              }
              sx={{ gridColumn: "span 3" }}
            />

            <Button
              value={index}
              onClick={() => {
                handleRemoveItemUnitRow(data.data_id);
              }}
              size="small"
              variant="contained"
            >
              Remove
            </Button>
          </>
          {/* )} */}
        </Box>
      </Box>
    );
  };
  
  export default ItemUnitDetailForm;
  