import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import {  PdfRegular,  PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    marginVertical: 10,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#3AB9EA",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const PayslipPdf = ({data, viewData, printedBy}) => {

  const id = viewData.id;
  const payslip_for = viewData.payslip_for;
  const renumeration_detail = viewData.renumeration_detail;
  const allowance_detail = viewData.allowance_detail;
  const deduction_detail = viewData.deduction_detail;
  const total_renumeration = currencyFormatter(parseFloat(viewData.total_renumeration));
  const total_deduction = currencyFormatter(parseFloat(viewData.total_deduction));
  const total_allowance = currencyFormatter(parseFloat(viewData.total_allowance));
  const net_earnings = currencyFormatter(parseFloat(Number(viewData.total_renumeration) + Number(viewData.total_allowance) - Number(viewData.total_deduction)));
  const payment_date = viewData.payment_date;
  const related_employee = viewData.related_employee;

  const earningsFields = [
    {title: "#",width: "3",},
    {title: "Earnings",width: "50",},
    {title: "Amount",width: "47",},
  ];

  const allowanceFields = [
    { title: "#", width: "3" },
    { title: "Allowance", width: "50" },
    { title: "Amount", width: "47" },
  ];

  const deductionFields = [
    { title: "#", width: "3" },
    { title: "Deductions", width: "50" },
    { title: "Amount", width: "47" },
  ];

  const EarningsTable = ({ headerFixed = false, earningsFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {earningsFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      {renumeration_detail?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <PdfBorderView key={index} style={{ width: earningsFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: earningsFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_renumeration_type.renumeration_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: earningsFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.amount}</PdfSmall>
          </PdfBorderView>
        </View>
      ))}
      <View style={styles.tableRow}>
        <PdfBorderView key={0} style={{   width: earningsFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]}>TOTAL EARNINGS</Text>
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]} >{total_renumeration}</Text>
        </PdfBorderView>
      </View>
    </View>
  ); 

  const AllowancesTable = ({ headerFixed = false, allowanceFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {allowanceFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      {allowance_detail?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <PdfBorderView key={index} style={{ width: deductionFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: deductionFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_allowance_type.allowance_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: deductionFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.amount}</PdfSmall>
          </PdfBorderView>
        </View>
      ))}
      <View style={styles.tableRow}>
        <PdfBorderView key={0} style={{   width: earningsFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]}>TOTAL ALLOWANCES</Text>
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]} >{total_allowance}</Text>
        </PdfBorderView>
      </View>
    </View>
  ); 

  const DeductionsTable = ({ headerFixed = false, deductionFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {deductionFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      {deduction_detail?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <PdfBorderView key={index} style={{ width: deductionFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: deductionFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_deduction_type.deduction_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: deductionFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.amount}</PdfSmall>
          </PdfBorderView>
        </View>
      ))}
      <View style={styles.tableRow}>
        <PdfBorderView key={0} style={{   width: earningsFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]}>TOTAL DEDUCTIONS</Text>
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: earningsFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]} >{total_deduction}</Text>
        </PdfBorderView>
      </View>
    </View>
  ); 


  return (
    <Document title={`Payslip #${id}`}>
      <Page size="A4" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed>
          {/* <PdfHeading>JOLILABS PHARMACY</PdfHeading> */}
        </PdfHeaderView>
        <PdfSubHeaderView fixed>
          <PdfSmall>+256 761 780 800/ +256 704 912 888</PdfSmall>
          <PdfSmall>jolilabspharmacy@gmail.com</PdfSmall>
          <PdfSmall>www.jolilabspharmacy.com</PdfSmall>
          <PdfSmall>Located in Mukono Town Next to Bank of Baroda.</PdfSmall>
        </PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubHeading>PAYSLIP</PdfSubSubHeading>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>#{id}</PdfSubSubSubHeading>
          </View>
        </PdfView>

        {/* ADDRESS */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 1 }}>
            <PdfSubSubSubHeading>EMPLOYEE INFORMATION</PdfSubSubSubHeading>
            <PdfRegular>{related_employee.first_name} {related_employee.last_name}</PdfRegular>
            <PdfRegular>Address: {related_employee.address}</PdfRegular>
            <PdfRegular>Phone: {related_employee.phone_number}</PdfRegular>
          </View>
          {/* DATE */}
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfView flexDirection={"column"}>
              <PdfSubSubSubHeading>Payment Date</PdfSubSubSubHeading>
              <PdfRegular>{moment(payment_date).format("LL")}</PdfRegular>
            </PdfView>
          </View>
        </PdfView>

        {/* DOCUMENT DETAILS */}
        <PdfBorderView mv={10}>
          <PdfSubSubSubHeading>Payslip For</PdfSubSubSubHeading>
          <PdfRegular>{payslip_for}</PdfRegular>
        </PdfBorderView>
       

        {/* TABLES */}

        <EarningsTable headerFixed earningsFields={earningsFields} />

        <AllowancesTable headerFixed allowanceFields={allowanceFields} />

        <DeductionsTable headerFixed deductionFields={deductionFields} />

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <PdfBorderView  style={{  width:"53%",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
              <Text style={[styles.tableCellHeader]}>NET PAY</Text>
            </PdfBorderView>
            <PdfBorderView  style={{ width:"47%",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
              <Text style={[styles.tableCellHeader]} >{net_earnings}</Text>
            </PdfBorderView>
          </View>
        </View>
        

        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>Here to make you feel better</PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>Printed By: {printedBy} {moment().format("LLL")}</PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default PayslipPdf;