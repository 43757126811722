import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import requester from "api/pesabackend";

const BusinessBranchDetailEditForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id, editData } = props;

  const initialValuesItem = {
    branch_name: editData.branch_name,
    branch_location: editData.branch_location,
    branch_contact: editData.branch_contact,
  };

  const itemSchema = yup.object().shape({
    branch_name: yup.string().required("required"),
    branch_location: yup.string().required("required"),
    branch_contact: yup.number().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {
    const savedItemResponse = await requester.post(
      `/tenants/business_branch/edit/${id}`,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.status === 201) {
      props.setOpen(false);
      props.setNewBusinessBranchData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Business Branch Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.branch_name}
              name="branch_name"
              error={
                Boolean(touched.branch_name) && Boolean(errors.branch_name)
              }
              helperText={touched.branch_name && errors.branch_name}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Contact"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.branch_contact}
              name="branch_contact"
              error={Boolean(touched.branch_contact) && Boolean(errors.branch_contact)}
              helperText={touched.branch_contact && errors.branch_contact}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.branch_location}
              name="branch_location"
              error={Boolean(touched.branch_location) && Boolean(errors.branch_location)}
              helperText={touched.branch_location && errors.branch_location}
              sx={{ gridColumn: "span 2" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default BusinessBranchDetailEditForm;
