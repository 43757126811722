import React, {useEffect, useState} from "react";
import requester from "api/pesabackend"; 

const resource_tag = 'accounting';

const useChartOfAccountsList = (pageNumber, business_branch_id) => {
    const [chartList, setChartList] = useState([]);
	const [isLoadingChartList, setLoadingChartList] = useState(true);
	const [errorChartList, setErrorChartList] = useState(false);

	const url = `/${resource_tag}/chart_of_accounts?business_branch=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setChartList(response.data.data);
				setLoadingChartList(false);
				setErrorChartList(false);
			} catch (e) {
				setErrorChartList(true);
				setLoadingChartList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { chartList, isLoadingChartList, errorChartList };
};

const useChartOfAccountsNextAccountsList = (pageNumber, parent_id) => {
    const [chartNextList, setChartNextList] = useState([]);
	const [isLoadingChartNextList, setLoadingChartNextList] = useState(true);
	const [errorChartNextList, setErrorChartNextList] = useState(false);

	const url = `/${resource_tag}/chart_of_accounts/next_level?parent_id=${parent_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setChartNextList(response.data.data);
				setLoadingChartNextList(false);
				setErrorChartNextList(false);
			} catch (e) {
				setErrorChartNextList(true);
				setLoadingChartNextList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { chartNextList, isLoadingChartNextList, errorChartNextList };
};

const useGetProfitAndLoss = () => {
    const [profitReport, setProfitReport] = useState([]);
	const [isLoadingProfitReport, setLoadingProfitReport] = useState(true);
	const [errorProfitReport, setErrorProfitReport] = useState(false);

	const url = `/${resource_tag}/chart/pl`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setProfitReport(response.data.data);
				setLoadingProfitReport(false);
				setErrorProfitReport(false);
			} catch (e) {
				setErrorProfitReport(true);
				setLoadingProfitReport(false);
			}
		};
		fetchData();
	}, []);
	return { profitReport, isLoadingProfitReport, errorProfitReport };
};

const useGetBalanceMonths = () => {
    const [balanceMonths, setBalanceMonths] = useState([]);
	const [isLoadingBalanceMonths, setLoadingBalanceMonths] = useState(true);
	const [errorBalanceMonths, setErrorBalanceMonths] = useState(false);

	const url = `/${resource_tag}/chart/bs/months`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setBalanceMonths(response.data.data);
				setLoadingBalanceMonths(false);
				setErrorBalanceMonths(false);
			} catch (e) {
				setErrorBalanceMonths(true);
				setLoadingBalanceMonths(false);
			}
		};
		fetchData();
	}, []);
	return { balanceMonths, isLoadingBalanceMonths, errorBalanceMonths };
};

const useGetBalanceSheet = () => {
    const [profitReport, setProfitReport] = useState([]);
	const [isLoadingProfitReport, setLoadingProfitReport] = useState(true);
	const [errorProfitReport, setErrorProfitReport] = useState(false);

	const url = `/${resource_tag}/chart/bs`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setProfitReport(response.data.data);
				setLoadingProfitReport(false);
				setErrorProfitReport(false);
			} catch (e) {
				setErrorProfitReport(true);
				setLoadingProfitReport(false);
			}
		};
		fetchData();
	}, []);
	return { profitReport, isLoadingProfitReport, errorProfitReport };
};

export {useChartOfAccountsList, useChartOfAccountsNextAccountsList, useGetProfitAndLoss, useGetBalanceMonths}