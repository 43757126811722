import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import "react-pro-sidebar/dist/css/styles.css";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from "@mui/icons-material/Tune";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BadgeIcon from "@mui/icons-material/Badge";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';


const MyProSidebar = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const modules = useSelector((state) => state.modules)
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const sub_main = theme.palette.secondary.sub_main
  const main = theme.palette.secondary.main
  const white = theme.palette.secondary.white
  const medium = theme.palette.secondary.medium
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        bottom: 0,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${medium} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${medium} !important`,
          backgroundColor: `${medium} !important`,
        },
        position: "sticky",
        top: 0,
      }}
    >
      <Sidebar collapsed={isCollapsed} backgroundColor={medium}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "1px 0 1px 0",
              color: "darkblue",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography variant="h2" color={white}>
                  Menu
                </Typography> */}
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  style={{ color: "darkblue" }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (

            <Box mb="25px">
              <MenuItem
                active={location.pathname.includes("dashboard")}
                icon={<DashboardIcon />}
                style={{
                  color: "darkblue",
                  margin: "1px 0 1px 0",
                  fontSize: "16px",
                }}
                key={"dashboard"}
                onClick={() => navigate("/dashboards")}
              >
                Dashboard
              </MenuItem>

              {modules.billing === 1 && (
                <MenuItem
                  icon={<StoreIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"sales"}
                  onClick={() => navigate("/billing")}
                >
                  Sales
                </MenuItem>
              )}

              {modules.inventory === 1 && (
                <>
                  <MenuItem
                    active={location.pathname.includes("purchase")}
                    icon={<ReceiptIcon />}
                    style={{
                      color: "darkblue",
                      margin: "1px 0 1px 0",
                      fontSize: "16px",
                    }}
                    key={"purchase"}
                    onClick={() => navigate("/purchase")}
                  >
                    Purchases
                  </MenuItem>

                  <MenuItem
                    active={location.pathname.includes("purchase")}
                    icon={<AssignmentReturnIcon />}
                    style={{
                      color: "darkblue",
                      margin: "1px 0 1px 0",
                      fontSize: "16px",
                    }}
                    key={"transfers"}
                    onClick={() => navigate("/transfers")}
                  >
                    Stock Transfers
                  </MenuItem>

                  <MenuItem
                    active={location.pathname.includes("inventory")}
                    icon={<InventoryIcon />}
                    style={{
                      color: "darkblue",
                      margin: "1px 0 1px 0",
                      fontSize: "16px",
                    }}
                    key={"inventory"}
                    onClick={() => navigate("/inventory")}
                  >
                    Inventory
                  </MenuItem>

                  <MenuItem
                    active={location.pathname.includes("inventorySetup")}
                    icon={<FolderIcon />}
                    style={{
                      color: "darkblue",
                      margin: "1px 0 1px 0",
                      fontSize: "16px",
                    }}
                    key={"inventorySetup"}
                    onClick={() => navigate("/inventorySetup")}
                  >
                    Setup Inventory
                  </MenuItem>

                  <MenuItem
                    active={location.pathname.includes("supplier")}
                    icon={<LocalShippingIcon />}
                    style={{
                      color: "darkblue",
                      margin: "1px 0 1px 0",
                      fontSize: "16px",
                    }}
                    key={"supplier"}
                    onClick={() => navigate("/supplier")}
                  >
                    Suppliers
                  </MenuItem>
                  </>
              )}
              
              {modules.customers === 1 && (
                <MenuItem
                  active={location.pathname.includes("customer")}
                  icon={<PeopleIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"customer"}
                  onClick={() => navigate("/customer")}
                >
                  Customers
                </MenuItem>
              )}
              
              {modules.accounting === 1 && (
                <MenuItem
                  icon={<AccountBalanceIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  onClick={() => navigate("/accounting")}
                >
                  Accounting
                </MenuItem>
              )}

              {modules.human_resource === 1 && (
                <MenuItem
                  icon={<BadgeIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  onClick={() => navigate("/humanResource")}
                >
                  Human Resource
                </MenuItem>
              )}

            {modules.admin === 1 && (
              <>
                <MenuItem
                  active={location.pathname.includes("reports")}
                  icon={<AssessmentIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"reports"}
                  onClick={() => navigate("/reports")}
                >
                  Reporting
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes("userManagement")}
                  icon={<ManageAccountsIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"userManagement"}
                  onClick={() => navigate("/userManagement")}
                >
                  User Management
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("fileUploads")}
                  icon={<DriveFolderUploadIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"fileUploads"}
                  onClick={() => navigate("/fileUploads")}
                >
                  File Uploads
                </MenuItem>

                <MenuItem
                  active={location.pathname.includes("settings")}
                  icon={<TuneIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"settings"}
                  onClick={() => navigate("/settings")}
                >
                  Settings
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("business")}
                  icon={<TuneIcon />}
                  style={{
                    color: "darkblue",
                    margin: "1px 0 1px 0",
                    fontSize: "16px",
                  }}
                  key={"business"}
                  onClick={() => navigate("/business")}
                >
                  Business
                </MenuItem>
              </>
            )}
            </Box>
          )}
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
