import {
  Box,
  Grid,
  Button,
  Paper,
  InputBase,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEmployeePayslipsList } from "api/hooks/humanResource";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
import moment from "moment";
import PayslipViewForm from "../forms/payslipForms/payslipViewForm";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

export default function EmployeePayslipHistoryTable({ employeeId }) {
  const navigate = useNavigate();
  const { employeePayslipList, isLoadingEmployeePayslipList,  setFilterRange } = useEmployeePayslipsList(1, employeeId);

  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf('month').format("YYYY-MM-DD"));
  

 

  const handleClickView = async (e) => {
    const response = await requester.get(
      `/human_resource/payslip/get/${e.target.value}`
    );
    if (response.status === 200) {
      // navigate("/PayslipViewForm", { state: response.data.data });
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleClickFilter = () => {
    setFilterRange({ start: startDate, end: endDate });
  };

  

  const handleChangeFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", padding: "40px", margin: "20px" }}>
      <h4>Employee Payslip History List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>

              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        max: endDate,
                      }}
                    />
                  </Paper>

                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        min: startDate,
                      }}
                    />
                  </Paper>
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Payroll Number</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Payment Date</StyledTableCell>
                  <StyledTableCell>Total Renumeration</StyledTableCell>
                  <StyledTableCell>Total Allowance</StyledTableCell>
                  <StyledTableCell>Total Deduction</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Overall Total</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Created By</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeePayslipList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  employeePayslipList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.payment_date).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell>{withoutSymbolFormatter(parseFloat(row.total_renumeration))}</StyledTableCell>
                          <StyledTableCell>{withoutSymbolFormatter(parseFloat(row.total_allowance))}</StyledTableCell>
                          <StyledTableCell>{withoutSymbolFormatter(parseFloat(row.total_deduction))}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(Number(row.total_renumeration) + Number(row.total_allowance) - Number(row.total_deduction)))}</StyledTableCell>
                          <StyledTableCell>
                            {row.added_by.first_name} {row.added_by.last_name}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Order"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <PayslipViewForm viewData={viewData} />
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeePayslipList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
