
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {  requester } from "api/pesabackend";

const itemUnitSchema = yup.object().shape({
  name: yup.string().required("required"),
  
});



const initialValuesItemUnit = {
  name: "",
};


const ItemUnitForm = (props) => {

  const pageType = props.formType;
  let {id, editData} = props;
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNew = pageType === "create";
  const isEdit = pageType === "edit";

  const initialEditValuesItemUnit = {
    name: editData.name,
  };

  const saveItemUnit = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedUnitResponse = await requester.post(`/inventory/business_product_units/add`, JSON.stringify(values), {
      headers: { 'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json" },
    });


    if (savedUnitResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewItemUnitData(true);
      props.setOpen(false);
    }
  };

  const editItemUnit = async (values, onSubmitProps) => {
    const savedUnitResponse = await requester.post(
      `/inventory/business_product_units/edit/${id}`, JSON.stringify(values),
      {
        headers: { 
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json" 
        },
      }
    );

    if (savedUnitResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewItemUnitData(true);
      props.setOpen(false);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isNew) await saveItemUnit(values, onSubmitProps);
    if (isEdit) await editItemUnit(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isNew ? initialValuesItemUnit : initialEditValuesItemUnit}
      validationSchema={itemUnitSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Item Unit Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              name="name"
              error={Boolean(touched.name) && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ItemUnitForm;
