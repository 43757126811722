import {Box, Paper, Button} from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useRef } from "react";
// import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import TableFooter from "@mui/material/TableFooter";
// import ReactToPrint from "react-to-print";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseRequisitionPdf from "./purchaseRequisitionPdf";

const PurchaseRequisitionViewForm = (props) => {

  // const [isSidebar, setIsSidebar] = useState(true);
  const user = useSelector((state) => state.user);
  const { viewData, handleClickApprove } = props;
  // const location = useLocation();
  const componentRef = useRef();

  const requisition_number = viewData.id;
  const detail= viewData.detail;
  const addedBy = viewData.added_by
  const approvedBy = viewData.approved_by
  const requisitionStatus = viewData.status
  // const grand_total= viewData.grand_total;
  const requisition_date= viewData.requisition_date;
  const products_requisitioned=viewData.products_requisitioned;


  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>PURCHASE REQUISITION</h1>
            <h2># {requisition_number}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              {/* <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Invoice From
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {related_supplier.name}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address: {related_supplier.address}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {related_supplier.mobile}
              </p> */}

              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Requisition Date
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(requisition_date).format("LL")}
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4
                style={{ textAlign: "right", margin: "0px", padding: "0px" }}
              ></h4>
            </Box>
          </Box>
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Requisition Details
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              {detail}
            </p>
            <br />
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      width: 25,
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity on Hand
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity Sold in 30 days
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Requisition Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Requisition Units
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products_requisitioned ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products_requisitioned.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{index + 1}</StyledTableCell>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{row.related_product.product_name} {row.related_product.description}</StyledTableCell>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{row.quantity_remaining_at_requisition}</StyledTableCell>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{row.quantity_sold_in_30_days}</StyledTableCell>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{row.quantity_requisitioned}</StyledTableCell>
                        <StyledTableCell style={{  padding: "6px 6px",  border: "1px solid",}}>{row.related_product_unit.related_business_product_unit.name}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>
                    CREATED BY {addedBy.first_name} {addedBy.last_name}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3}>
                    APPROVED BY {approvedBy ? approvedBy.first_name + " " + approvedBy.last_name : null}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter>
            </Table>
          </Box>
          <br />
        </Box>
      </Paper>
      {/*<ReactToPrint
        documentTitle={`Purchase Requisition #${requisition_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              m: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<PurchaseRequisitionPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Purchase Requisition #${requisition_number}`}>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: "20%",
            my: "15px",
            mx: "5px",
          }}
        >
          Download
        </Button>
      </PDFDownloadLink>
      {requisitionStatus === 0 ? (
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={handleClickApprove}
          sx={{
            width: "20%",
            m: "15px",
          }}
        >
          APPROVE
        </Button>
      ) : null}
    </>
  );

}

export default PurchaseRequisitionViewForm;