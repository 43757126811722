import {
  Box,
  useMediaQuery,
  Tab,
  Tabs,
  Typography,
  Alert,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import ChartOfAccounts from "../tables/chartOfAccountsTable";
import ProfitAndLossTable from "../tables/profitAndLossTable";
import BalanceSheetMonthsTable from "../tables/balanceSheetMonthsTable";
import PaymentTypesTable from "../tables/paymentTypesTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountingTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['accountingTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "accountingTab", tab: newValue })); 
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        container
        spacing={2}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Operation was successful!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} severity="error">
              Operation was not successful!
            </Alert>
          ) : null}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Chart of Accounts" {...a11yProps(0)} />
              <Tab label="Balance Sheet" {...a11yProps(1)} />
              <Tab label="Profit and Loss" {...a11yProps(2)} />
              <Tab label="Payment Types" {...a11yProps(3)} />
              <Tab label="Banking and Cash Transfers" {...a11yProps(4)} />
              <Tab label="Configurations" {...a11yProps(5)} />
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={value} index={0}><ChartOfAccounts/></TabPanel>
            <TabPanel value={value} index={1}><BalanceSheetMonthsTable/></TabPanel>
            <TabPanel value={value} index={2}><ProfitAndLossTable/></TabPanel>
            <TabPanel value={value} index={3}><PaymentTypesTable/></TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountingTab;
