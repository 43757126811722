import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import * as React from "react";
import { useDeductionTypesList } from "api/hooks/humanResource";

const DeductionDetailForm = (props) => {
  const {
    index,
    data,
    handleRemoveProductDetailRow,
    handleItemAmountChange,
    name,
    errors,
    touched,
    handleBlur,
    handleChange,
    businessBranchId,
  } = props;
  const isNonMobile = useMediaQuery("(min-width:600px)");


  const { deductionTypeList } = useDeductionTypesList(1, businessBranchId);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <p>#{index + 1}</p>
      </Box>
      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        <>
          <TextField
            label="Deduction"
            size="small"
            onBlur={handleBlur}
            onChange={handleChange}
            select
            value={data.related_deduction_type}
            name={`${name}.related_deduction_type`}
            error={
              Boolean(
                touched?.deduction_detail &&
                  touched?.deduction_detail[index]
                    ?.related_deduction_type
              ) &&
              Boolean(
                errors?.deduction_detail &&
                  errors?.deduction_detail[index]
                    ?.related_deduction_type
              )
            }
            helperText={
              touched?.deduction_detail &&
              touched?.deduction_detail[index]
                ?.related_deduction_type &&
              errors?.deduction_detail &&
              errors?.deduction_detail[index]?.related_deduction_type
            }
            sx={{ gridColumn: "span 2" }}
          >
            {deductionTypeList.map((oneUnit, index) => {
              return (
                <MenuItem
                  key={index}
                  value={oneUnit.id}
                  selected={oneUnit.id === data.related_deduction_type  ? true  : false}
                >
                  {oneUnit.deduction_name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            label="Amount"
            size="small"
            type="number"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              handleItemAmountChange(e, index);
            }}
            value={data.amount}
            inputProps={{
              min: 0,
            }}
            name={`${name}.amount`}
            error={
              Boolean(
                touched?.deduction_detail &&
                  touched?.deduction_detail[index]?.amount
              ) &&
              Boolean(
                errors?.deduction_detail &&
                  errors?.deduction_detail[index]?.amount
              )
            }
            helperText={
              touched?.deduction_detail &&
              touched?.deduction_detail[index]?.amount &&
              errors?.deduction_detail &&
              errors?.deduction_detail[index]?.amount
            }
            sx={{ gridColumn: "span 2" }}
          />

          {index > 0 ? (
            <Button
              value={index}
              onClick={() => {
                handleRemoveProductDetailRow(index);
              }}
              size="small"
              variant="contained"
              sx={{
                width: "20%",
              }}
            >
              Remove
            </Button>
          ) : null}
        </>
      </Box>
    </Box>
  );
};

export default DeductionDetailForm;
