import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import {  PdfRegular,  PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    marginVertical: 10,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#3AB9EA",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const PurchaseOrderPdf = ({data, viewData, printedBy}) => {


  const purchase_order_number = viewData.id;
  const detail = viewData.detail;
  const fob= viewData.fob;
  const terms =  viewData.terms
  const ship_via =  viewData.ship_via
  const grand_total =  viewData.grand_total
  const delivery_date =  viewData.delivery_date
  const related_supplier =  viewData.related_supplier
  const products_ordered = viewData.products_ordered
  const requisition_by = viewData.added_by
  const date_created = viewData.date_added

  const productFields = [
    {title: "#",width: "3",},
    {title: "Description",width: "40",},
    {title: "Quantity",width: "17",},
    {title: "Unit",width: "20",},
    {title: "Amount",width: "20",},
  ];

  const otherInfoFields = [
    {title: "Delivery Date",width: "15",},
    {title: "F.O.B",width: "19",},
    {title: "Terms",width: "18",},
    {title: "Requisitioned By",width: "30",},
    {title: "Ship Via",width: "18",},
  ];

  const ProductTable = ({ headerFixed = false, productFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {productFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      {products_ordered?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <PdfBorderView key={index} style={{ width: productFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: productFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_product.product_name} {row.related_product.description} </PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: productFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.quantity}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: productFields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_product_unit.related_business_product_unit.name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={index} style={{   width: productFields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{withoutSymbolFormatter(parseFloat(row.amount))}</PdfSmall>
          </PdfBorderView>
        </View>
      ))}
      <View style={styles.tableRow}>
          
        <PdfBorderView key={0} style={{   width: productFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: productFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: productFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: productFields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]}>TOTAL</Text>
        </PdfBorderView>
        <PdfBorderView key={0} style={{   width: productFields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <Text style={[styles.tableCellHeader]} >{currencyFormatter(parseFloat(grand_total))}</Text>
        </PdfBorderView>
      </View>
    </View>
  ); 

  const OtherInfoTable = ({ headerFixed = false, otherInfoFields, style }) => (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.headerBg]}>
        {otherInfoFields.map((item, index) => (
          <View key={index} style={[{ width: item.width + "%" }]}>
            <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
          </View>
        ))}
      </View>
      <View style={styles.tableRow}>
        <PdfBorderView style={{   width: otherInfoFields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <PdfSmall style={{ textAlign: 'center' }}>{moment(delivery_date).format("LL")}</PdfSmall>
        </PdfBorderView>
        <PdfBorderView style={{   width: otherInfoFields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <PdfSmall style={{ textAlign: 'center' }}>{fob}</PdfSmall>
        </PdfBorderView>
        <PdfBorderView style={{   width: otherInfoFields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <PdfSmall style={{ textAlign: 'center' }}>{terms}</PdfSmall>
        </PdfBorderView>
        <PdfBorderView style={{   width: otherInfoFields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <PdfSmall style={{ textAlign: 'center' }}>{requisition_by}</PdfSmall>
        </PdfBorderView>
        <PdfBorderView style={{   width: otherInfoFields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          <PdfSmall style={{ textAlign: 'center' }}>{ship_via}</PdfSmall>
        </PdfBorderView>
      </View>
    </View>
  ); 


  return (
    <Document title={`Purchase Order #${purchase_order_number}`}>
      <Page size="A4" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed>
          {/* <PdfHeading>JOLILABS PHARMACY</PdfHeading> */}
        </PdfHeaderView>
        <PdfSubHeaderView fixed>
          <PdfSmall>+256 761 780 800/ +256 704 912 888</PdfSmall>
          <PdfSmall>jolilabspharmacy@gmail.com</PdfSmall>
          <PdfSmall>www.jolilabspharmacy.com</PdfSmall>
          <PdfSmall>Located in Mukono Town Next to Bank of Baroda.</PdfSmall>
        </PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubHeading>PURCHASE ORDER</PdfSubSubHeading>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>#{purchase_order_number}</PdfSubSubSubHeading>
          </View>
        </PdfView>

        {/* ADDRESS */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 1 }}>
            <PdfSubSubSubHeading>Purchase Order From</PdfSubSubSubHeading>
            <PdfRegular>{related_supplier.name}</PdfRegular>
            <PdfRegular>Address: {related_supplier.address}</PdfRegular>
            <PdfRegular>Phone: {related_supplier.mobile}</PdfRegular>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <View>
              <PdfSubSubSubHeading>Purchase Order To</PdfSubSubSubHeading>
              <PdfRegular>{related_supplier.name}</PdfRegular>
              <PdfRegular>Address: {related_supplier.address}</PdfRegular>
              <PdfRegular>Phone: {related_supplier.mobile}</PdfRegular>
            </View>
          </View>
        </PdfView>

        {/* DATE */}
        <PdfView flexDirection={"column"} style={{ margin: 5 }}>
          <PdfSubSubSubHeading>Date Created</PdfSubSubSubHeading>
          <PdfRegular>{moment(date_created).format("LL")}</PdfRegular>
        </PdfView>

        {/* TABLES */}

        <OtherInfoTable headerFixed otherInfoFields={otherInfoFields} />

        <ProductTable headerFixed productFields={productFields} />

        {/* DOCUMENT DETAILS */}
        <PdfBorderView mv={10}>
          <PdfSubSubSubHeading>Notes</PdfSubSubSubHeading>
          <PdfRegular>{detail}</PdfRegular>
        </PdfBorderView>

        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>Here to make you feel better</PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>Printed By: {printedBy} {moment().format("LLL")}</PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default PurchaseOrderPdf;