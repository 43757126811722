import { useEffect, useState } from "react";
import requester from "api/pesabackend";
import moment from "moment";



const usePurchaseDashboard = (business_branch_id) => {
    const [purchaseDashboard, setPurchaseDashboard] = useState([]);
	const [isLoadingPurchaseDashboard, setLoadingPurchaseDashboard] = useState(true);
	const [error, setError] = useState(false);
	const [periodType, setPeriodType] = useState("DAY");
  	const [period, setPeriod] = useState(moment().format("YYYY-MM-DD"));


	let urlString = `/inventory/dashboard/purchase?business_branch=${business_branch_id}&period_type=${periodType}&period=${period}`;
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPurchaseDashboard(response.data.data);
				setLoadingPurchaseDashboard(false);
			} catch (e) {
				setError(true);
				setLoadingPurchaseDashboard(false);
			}
		};
		fetchData();
	}, [isLoadingPurchaseDashboard, period ]);
	return { purchaseDashboard, isLoadingPurchaseDashboard, error,  setPeriodType, setPeriod };
};


const useItemDashboard = (business_branch_id) => {
    const [itemDashboard, setItemDashboard] = useState([]);
	const [isLoadingItemDashboard, setLoadingItemDashboard] = useState(true);
	const [error, setError] = useState(false);
	const [periodType, setPeriodType] = useState("DAY");
  	const [period, setPeriod] = useState(moment().format("YYYY-MM-DD"));



	let urlString = `/inventory/dashboard/item?business_branch=${business_branch_id}&period_type=${periodType}&period=${period}`;
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemDashboard(response.data.data);
				setLoadingItemDashboard(false);
			} catch (e) {
				setError(true);
				setLoadingItemDashboard(false);
			}
		};
		fetchData();
	}, [isLoadingItemDashboard, period ]);
	return { itemDashboard, isLoadingItemDashboard, error,  setPeriodType, setPeriod };
};

const useSalesDashboard = (business_branch_id) => {
    const [salesDashboard, setSalesDashboard] = useState([]);
	const [isLoadingSalesDashboard, setLoadingSalesDashboard] = useState(true);
	const [error, setError] = useState(false);
	const [periodType, setPeriodType] = useState("DAY");
  	const [period, setPeriod] = useState(moment().format("YYYY-MM-DD"));



	let urlString = `/billing/dashboard/sales?business_branch=${business_branch_id}&period_type=${periodType}&period=${period}`;
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setSalesDashboard(response.data.data);
				setLoadingSalesDashboard(false);
			} catch (e) {
				setError(true);
				setLoadingSalesDashboard(false);
			}
		};
		fetchData();
	}, [isLoadingSalesDashboard, period ]);
	return { salesDashboard, isLoadingSalesDashboard, error,  setPeriodType, setPeriod };
};

const useBusinessBranchDashboard = () => {
    const [businessBranchDashboard, setBusinessBranchDashboard] = useState([]);
	const [isLoadingBusinessBranchDashboard, setLoadingBusinessBranchDashboard] = useState(true);
	const [error, setError] = useState(false);
	const [businessBranch, setBusinessBranch] = useState(false)
	const [period, setPeriod] = useState(moment().format("YYYY"));

	let urlString

	if (businessBranch) {
		urlString = `/tenants/dashboard/business_branch?business_branch=${businessBranch}&period=${period}`;
	} else {
		urlString = `/tenants/dashboard/business_branch?period=${period}`;
	}
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setBusinessBranchDashboard(response.data.data);
				setLoadingBusinessBranchDashboard(false);
			} catch (e) {
				setError(true);
				setLoadingBusinessBranchDashboard(false);
			}
		};
		fetchData();
	}, [isLoadingBusinessBranchDashboard, urlString ]);
	return { businessBranchDashboard, isLoadingBusinessBranchDashboard, error, setBusinessBranch, setPeriod};
};


export {usePurchaseDashboard, useItemDashboard, useSalesDashboard, useBusinessBranchDashboard};