import { Box, Button, TextField, useMediaQuery, useTheme, MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useRenumerationTypesList } from "api/hooks/humanResource";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const RenumerationTypeSchema = yup.object().shape({
  related_renumeration_type: yup.string().required("required"),
  frequency: yup.string().required("required"),
  amount: yup.number().required("required"),
});


const EmployeeRenumerationForm = (props) => {
  const pageType = props.formType;
  let { employeeId, editData } = props;
  const userId = useSelector((state) => state.user.userId);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNew = pageType === "create";
  const isEdit = pageType === "edit";
  const {renumerationTypeList} = useRenumerationTypesList(1, business_branch_id)

  const initialValuesRenumerationType = isNew ? {
    related_renumeration_type: "",
    amount: 0,
    frequency: "",
  } : {
    related_renumeration_type: editData.related_renumeration_type.id,
    amount: editData.amount,
    frequency: editData.frequency,
  };


  const saveRenumerationType = async (values, onSubmitProps) => {
    values.added_by = userId;
    values.related_employee = employeeId;

    const savedResponse = await requester.post(
      `/human_resource/employees/payroll?payroll_type=renumeration`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewEmployeeRenumerationData(true);
      props.setOpen(false);
    }
  };

  const editRenumerationType = async (values, onSubmitProps) => {
    const savedResponse = await requester.post(
      `/human_resource/employees/payroll/edit/${editData.id}`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewEmployeeRenumerationData(true);
      props.setOpen(false);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isNew) await saveRenumerationType(values, onSubmitProps);
    if (isEdit) await editRenumerationType(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesRenumerationType}
      validationSchema={RenumerationTypeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Renumeration Type"
              onBlur={handleBlur}
              select
              disabled={isEdit}
              onChange={handleChange}
              value={values.related_renumeration_type}
              name="related_renumeration_type"
              error={
                Boolean(touched.related_renumeration_type) &&
                Boolean(errors.related_renumeration_type)
              }
              helperText={
                touched.related_renumeration_type &&
                errors.related_renumeration_type
              }
              sx={{ gridColumn: "span 2" }}
            >
              {renumerationTypeList.map((row, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={row.id}
                    selected={
                      row.id === values.related_renumeration_type.id
                        ? true
                        : false
                    }
                  >
                    {row.renumeration_name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Amount"
              onBlur={handleBlur}
              type="number"
              onChange={handleChange}
              value={values.amount}
              name="amount"
              error={Boolean(touched.amount) && Boolean(errors.amount)}
              helperText={touched.amount && errors.amount}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Frequency"
              onBlur={handleBlur}
              select
              defaultValue={"MONTHLY"}
              onChange={handleChange}
              value={values.frequency}
              name="frequency"
              error={Boolean(touched.frequency) && Boolean(errors.frequency)}
              helperText={touched.frequency && errors.frequency}
              sx={{ gridColumn: "span 2" }}
            >
              <MenuItem value="DAILY">DAILY</MenuItem>
              <MenuItem value="WEEKLY">WEEKLY</MenuItem>
              <MenuItem value="BI-WEEKLY">BI-WEEKLY</MenuItem>
              <MenuItem value="MONTHLY">MONTHLY</MenuItem>
              <MenuItem value="ANNUALLY">ANNUALLY</MenuItem>
            </TextField>
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EmployeeRenumerationForm;
