import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert,Breadcrumbs,Link,Typography,Paper,Autocomplete} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePurchaseList } from "api/hooks/inventory";
import { requester } from "api/pesabackend";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import SupplierReturnDetailForm from "./supplierReturnDetailForm";



const SupplierReturnForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {purchaseList} = usePurchaseList(1, business_branch_id)
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const [invoiceSelect, setInvoiceSelect] = useState(true);

  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesPayslip = {
    related_purchase: location.state.id,
    related_supplier: location.state.data.related_supplier.id,
    grand_total: 0,
    total_discount: location.state.data.discount,
    total_purchase: location.state.data.grand_total,
    return_date: moment().format("YYYY-MM-DD"),
    detail: "",
    return_detail: location.state.data.products_purchased,
  };

  const [totalReturn, setTotalReturn] = useState(0);


  const [returnDetailFormData, setReturnDetailFormData] = useState(
    initialValuesPayslip.return_detail
  );


  const returnSchema = yup.object().shape({
    related_purchase: yup.number().required("required"),
    grand_total: yup.number().required("required"),
    detail: yup.string().required("required"),
    return_date: yup.date().required("required"),
    return_detail: yup.array().of(
      yup.object().shape({
        related_purchase_detail: yup.number(),
        related_product: yup.number().required("required"),
        related_product_unit: yup.number().required("required"),
        is_return: yup.boolean(),
        quantity_returned: yup
          .number()
          .min(1, "A return must be at least 1")
          // .max(this.current_quantity_at_return, "A return must be at least 1")
          ,
        return_amount: yup.number(),
        current_quantity_at_return: yup.number(),
      })
    ),
  });

 

  const savePayslip = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post(
      `/inventory/purchase_return/add`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/purchase");
      }, 1500);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };
  

  const handleTotalReturn = (newArray) => {
    const totalReturnCalculation = newArray.reduce((total, newArray) => {
      return Number(total) + Number(newArray.return_amount);
    }, 0);
    setTotalReturn(totalReturnCalculation);
  };


  const handleReturnAmountChange = (event, index) => {
    const newArray = returnDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, return_amount: Number(event) };
      }
      return item;
    });
    handleTotalReturn(newArray);
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePayslip(values, onSubmitProps);
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Payslip Invoice recorded successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/purchase")}
            >
              <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Purchase
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Record Supplier Return Form</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValuesPayslip}
                validationSchema={returnSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "45%",
                          }}
                        >
                          <label>Return Date: </label>
                          <TextField
                            label=""
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={values.return_date}
                            name="return_date"
                            type="date"
                            error={
                              Boolean(touched.return_date) &&
                              Boolean(errors.return_date)
                            }
                            helperText={
                              touched.return_date && errors.return_date
                            }
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>

                        <TextField
                          label="Selected Invoice"
                          disabled
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.related_purchase}
                          name="related_purchase"
                          select
                          error={
                            Boolean(touched.related_purchase) &&
                            Boolean(errors.related_purchase)
                          }
                          helperText={
                            touched.related_purchase && errors.related_purchase
                          }
                          sx={{
                            width: "45%",
                            margin: " 20px 0px",
                          }}
                        >
                          {purchaseList.map((row, index) => {
                            return (
                              <MenuItem key={index} value={row.id}>
                                {row.invoice_number} - {row.related_supplier.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Box>

                      <TextField
                        label="Return Details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        value={values.detail}
                        name="detail"
                        error={
                          Boolean(touched.detail) && Boolean(errors.detail)
                        }
                        helperText={touched.detail && errors.detail}
                        sx={{
                          width: "95%",
                          margin: "10px 40px",
                        }}
                      />
                    </Box>
                    {invoiceSelect ? (
                      <>
                        <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          my="10px"
                        >
                          <h4>Input the Return Details here</h4>
                          <p style={{ color: "red" }}>* Select the checkbox for the product and batch you would like to retun</p>

                          <FieldArray
                            name="return_detail"
                            render={(arrayHelpers) => {
                              const return_detail = values.return_detail;

                              return (
                                <>
                                  {return_detail.map((row, index) => {
                                    return (
                                      <SupplierReturnDetailForm
                                        index={index}
                                        data={row}
                                        handleChange={handleChange}
                                        handleReturnAmountChange={
                                          handleReturnAmountChange
                                        }
                                        errors={arrayHelpers.form.errors}
                                        touched={arrayHelpers.form.touched}
                                        name={`return_detail[${index}]`}
                                      />
                                    );
                                  })}
                                </>
                              );
                            }}
                          />
                        </Box>

                        <Box
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Purchase Amount: </label>
                            <TextField
                              label=""
                              disabled
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.total_purchase}
                              name="total_purchase"
                              type="number"
                              size="small"
                              error={
                                Boolean(touched.total_purchase) &&
                                Boolean(errors.total_purchase)
                              }
                              helperText={
                                touched.total_purchase && errors.total_purchase
                              }
                              inputProps={{
                                min: 0,
                              }}
                              sx={{
                                gridColumn: "span 1",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Discount Amount: </label>
                            <TextField
                              label=""
                              disabled
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.total_discount}
                              name="total_discount"
                              type="number"
                              size="small"
                              error={
                                Boolean(touched.total_discount) &&
                                Boolean(errors.total_discount)
                              }
                              helperText={
                                touched.total_discount && errors.total_discount
                              }
                              inputProps={{
                                min: 0,
                              }}
                              sx={{
                                gridColumn: "span 1",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mx: "2px",
                            }}
                          >
                            <label>Total Return Amount: </label>
                            <TextField
                              label=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={(values.grand_total = totalReturn)}
                              name="grand_total"
                              disabled
                              size="small"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              error={
                                Boolean(touched.grand_total) &&
                                Boolean(errors.grand_total)
                              }
                              helperText={
                                touched.grand_total && errors.grand_total
                              }
                              sx={{
                                // width: "100%",
                                marginBottom: "15px",
                              }}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <h3
                        style={{
                          textAlign: "center",
                          color: "red",
                          margin: "40px 0px",
                        }}
                      >
                        PLEASE INPUT THE PURCHASE INVOICE NUMBER FOR THE RETURN
                        YOU WANT TO RECORD.
                      </h3>
                    )}

                    {/* BUTTONS */}
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Save Return
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          navigate(`/humanResource`);
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierReturnForm;
