import { useEffect, useState } from "react";
import requester from "api/pesabackend";
import moment from "moment";


const useDepartmentsList = (pageNumber, business_branch_id) => {
    const [departmentList, setDepartmentList] = useState([]);
	const [isLoadingDepartmentList, setLoadingDepartmentList] = useState(true);
	const [errorDepartmentList, setErrorDepartmentList] = useState(false);
	const [newDepartmentData, setNewDepartmentData] = useState(true);
	const [searchDepartments, setSearchDepartments] = useState(false);

	let urlString;

	if (searchDepartments) {
		urlString = `/human_resouce/departments?business_branch=${business_branch_id}&search=${searchDepartments}`;
	} else {
		urlString = `/human_resource/departments?business_branch=${business_branch_id}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setDepartmentList(response.data.data);
				setLoadingDepartmentList(false);
				setNewDepartmentData(false);
			} catch (e) {
				setErrorDepartmentList(true);
				setLoadingDepartmentList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingDepartmentList, searchDepartments, newDepartmentData ]);
	return { departmentList, isLoadingDepartmentList, errorDepartmentList, setSearchDepartments, setNewDepartmentData };
};


const useEmployeesList = (pageNumber, business_branch_id, token) => {
    const [employeeList, setEmployeeList] = useState([]);
	const [isLoadingEmployeeList, setLoadingEmployeeList] = useState(true);
	const [errorEmployeeList, setErrorEmployeeList] = useState(false);
	const [searchEmployees, setSearchEmployees] = useState(false);
	const [newEmployeeData, setNewEmployeeData] = useState(true);
	const [departmentFilter, setDepartmentFilter] = useState("all");

	let urlString;

	if (searchEmployees) {
		urlString = `/human_resource/employees?business_branch=${business_branch_id}&search=${searchEmployees}&departmentFilter=${departmentFilter}`;
	} else {
		urlString = `/human_resource/employees?business_branch=${business_branch_id}&departmentFilter=${departmentFilter}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setEmployeeList(response.data.data);
				setLoadingEmployeeList(false);
				setNewEmployeeData(false);
			} catch (e) {
				setErrorEmployeeList(true);
				setLoadingEmployeeList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeeList, searchEmployees, newEmployeeData, departmentFilter ]);
	return { employeeList, isLoadingEmployeeList, errorEmployeeList, setSearchEmployees, setNewEmployeeData, setDepartmentFilter };
};


const useEmployeeRolesList = (pageNumber, business_branch_id) => {
    const [employeeRoleList, setEmployeeRoleList] = useState([]);
	const [isLoadingEmployeeRoleList, setLoadingEmployeeRoleList] = useState(true);
	const [errorEmployeeRoleList, setErrorEmployeeRoleList] = useState(false);
	const [newEmployeeRoleData, setNewEmployeeRoleData] = useState(true);
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/human_resource/employee_roles?business_branch=${business_branch_id}`);
				setEmployeeRoleList(response.data.data);
				setLoadingEmployeeRoleList(false);
				setNewEmployeeRoleData(false);
			} catch (e) {
				setErrorEmployeeRoleList(true);
				setLoadingEmployeeRoleList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeeRoleList, newEmployeeRoleData ]);
	return { employeeRoleList, isLoadingEmployeeRoleList, errorEmployeeRoleList, setNewEmployeeRoleData };
};


const useShiftsList = (pageNumber, business_branch_id) => {
    const [shiftList, setShiftList] = useState([]);
	const [isLoadingShiftList, setLoadingShiftList] = useState(true);
	const [errorShiftList, setErrorShiftList] = useState(false);
	const [newShiftData, setNewShiftData] = useState(true);
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(`/human_resource/shifts?business_branch=${business_branch_id}`);
				setShiftList(response.data.data);
				setLoadingShiftList(false);
				setNewShiftData(false);
			} catch (e) {
				setErrorShiftList(true);
				setLoadingShiftList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingShiftList, newShiftData ]);
	return { shiftList, isLoadingShiftList, errorShiftList, setNewShiftData };
};


const useEmployeeDocumentsList = (pageNumber, employeeId) => {
  const [employeeDocumentList, setEmployeeDocumentList] = useState([]);
  const [isLoadingEmployeeDocumentList, setLoadingEmployeeDocumentList] = useState(true);
  const [errorEmployeeDocumentList, setErrorEmployeeDocumentList] = useState(false);
  const [newEmployeeDocumentData, setNewEmployeeDocumentData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/employees/documents?employee=${employeeId}`
        );
        setEmployeeDocumentList(response.data.data);
        setLoadingEmployeeDocumentList(false);
        setNewEmployeeDocumentData(false);
      } catch (e) {
        setErrorEmployeeDocumentList(true);
        setLoadingEmployeeDocumentList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeDocumentList, newEmployeeDocumentData]);
  return { employeeDocumentList, isLoadingEmployeeDocumentList, errorEmployeeDocumentList, setNewEmployeeDocumentData };
};


const useRenumerationTypesList = (pageNumber, business_branch_id) => {
  const [renumerationTypeList, setRenumerationTypeList] = useState([]);
  const [isLoadingRenumerationTypeList, setLoadingRenumerationTypeList] = useState(true);
  const [errorRenumerationTypeList, setErrorRenumerationTypeList] = useState(false);
  const [newRenumerationTypeData, setNewRenumerationTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/renumeration?business_branch=${business_branch_id}`
        );
        setRenumerationTypeList(response.data.data);
        setLoadingRenumerationTypeList(false);
        setNewRenumerationTypeData(false);
      } catch (e) {
        setErrorRenumerationTypeList(true);
        setLoadingRenumerationTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingRenumerationTypeList, newRenumerationTypeData]);
  return { renumerationTypeList, isLoadingRenumerationTypeList, errorRenumerationTypeList, setNewRenumerationTypeData };
};


const useDeductionTypesList = (pageNumber, business_branch_id) => {
  const [deductionTypeList, setDeductionTypeList] = useState([]);
  const [isLoadingDeductionTypeList, setLoadingDeductionTypeList] = useState(true);
  const [errorDeductionTypeList, setErrorDeductionTypeList] = useState(false);
  const [newDeductionTypeData, setNewDeductionTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/deduction?business_branch=${business_branch_id}`
        );
        setDeductionTypeList(response.data.data);
        setLoadingDeductionTypeList(false);
        setNewDeductionTypeData(false);
      } catch (e) {
        setErrorDeductionTypeList(true);
        setLoadingDeductionTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingDeductionTypeList, newDeductionTypeData]);
  return { deductionTypeList, isLoadingDeductionTypeList, errorDeductionTypeList, setNewDeductionTypeData };
};


const useAllowanceTypesList = (pageNumber, business_branch_id) => {
  const [allowanceTypeList, setAllowanceTypeList] = useState([]);
  const [isLoadingAllowanceTypeList, setLoadingAllowanceTypeList] = useState(true);
  const [errorAllowanceTypeList, setErrorAllowanceTypeList] = useState(false);
  const [newAllowanceTypeData, setNewAllowanceTypeData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/allowance?business_branch=${business_branch_id}`
        );
        setAllowanceTypeList(response.data.data);
        setLoadingAllowanceTypeList(false);
        setNewAllowanceTypeData(false);
      } catch (e) {
        setErrorAllowanceTypeList(true);
        setLoadingAllowanceTypeList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingAllowanceTypeList, newAllowanceTypeData]);
  return { allowanceTypeList, isLoadingAllowanceTypeList, errorAllowanceTypeList, setNewAllowanceTypeData };
};


const useEmployeeRenumerationsList = (pageNumber, employee) => {
  const [employeeRenumerationList, setEmployeeRenumerationList] = useState([]);
  const [isLoadingEmployeeRenumerationList, setLoadingEmployeeRenumerationList] = useState(true);
  const [errorEmployeeRenumerationList, setErrorEmployeeRenumerationList] = useState(false);
  const [newEmployeeRenumerationData, setNewEmployeeRenumerationData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/employees/payroll?payroll_type=renumeration&employee=${employee}`
        );
        setEmployeeRenumerationList(response.data.data);
        setLoadingEmployeeRenumerationList(false);
        setNewEmployeeRenumerationData(false);
      } catch (e) {
        setErrorEmployeeRenumerationList(true);
        setLoadingEmployeeRenumerationList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeRenumerationList, newEmployeeRenumerationData]);
  return { employeeRenumerationList, isLoadingEmployeeRenumerationList, errorEmployeeRenumerationList, setNewEmployeeRenumerationData };
};


const useEmployeeDeductionsList = (pageNumber, employee) => {
  const [employeeDeductionList, setEmployeeDeductionList] = useState([]);
  const [isLoadingEmployeeDeductionList, setLoadingEmployeeDeductionList] = useState(true);
  const [errorEmployeeDeductionList, setErrorEmployeeDeductionList] = useState(false);
  const [newEmployeeDeductionData, setNewEmployeeDeductionData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/employees/payroll?payroll_type=deduction&employee=${employee}`
        );
        setEmployeeDeductionList(response.data.data);
        setLoadingEmployeeDeductionList(false);
        setNewEmployeeDeductionData(false);
      } catch (e) {
        setErrorEmployeeDeductionList(true);
        setLoadingEmployeeDeductionList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeDeductionList, newEmployeeDeductionData]);
  return { employeeDeductionList, isLoadingEmployeeDeductionList, errorEmployeeDeductionList, setNewEmployeeDeductionData };
};


const useEmployeeAllowancesList = (pageNumber, employee) => {
  const [employeeAllowanceList, setEmployeeAllowanceList] = useState([]);
  const [isLoadingEmployeeAllowanceList, setLoadingEmployeeAllowanceList] = useState(true);
  const [errorEmployeeAllowanceList, setErrorEmployeeAllowanceList] = useState(false);
  const [newEmployeeAllowanceData, setNewEmployeeAllowanceData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(
          `/human_resource/employees/payroll?payroll_type=allowance&employee=${employee}`
        );
        setEmployeeAllowanceList(response.data.data);
        setLoadingEmployeeAllowanceList(false);
        setNewEmployeeAllowanceData(false);
      } catch (e) {
        setErrorEmployeeAllowanceList(true);
        setLoadingEmployeeAllowanceList(false);
      }
    };
    fetchData();
  }, [pageNumber, isLoadingEmployeeAllowanceList, newEmployeeAllowanceData]);
  return { employeeAllowanceList, isLoadingEmployeeAllowanceList, errorEmployeeAllowanceList, setNewEmployeeAllowanceData };
};


const usePayslipsList = (pageNumber, business_branch_id) => {
    const [payslipList, setPayslipList] = useState([]);
	const [isLoadingPayslipList, setLoadingPayslipList] = useState(true);
	const [error, setError] = useState(false);
	const [newPayslipData, setNewPayslipData] = useState(true);
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (search) {
		urlString = `/human_resource/payslip?business_branch=${business_branch_id}&search=${search}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/human_resource/payslip?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setPayslipList(response.data.data);
				setLoadingPayslipList(false);
				setNewPayslipData(false)
			} catch (e) {
				setError(true);
				setLoadingPayslipList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPayslipList, newPayslipData, search, filter ]);
	return { payslipList, isLoadingPayslipList, error, setNewPayslipData, setSearch, setFilter };
};


const useEmployeePayslipsList = (pageNumber, employee) => {
    const [employeePayslipList, setEmployeePayslipList] = useState([]);
	const [isLoadingEmployeePayslipList, setLoadingEmployeePayslipList] = useState(true);
	const [error, setError] = useState(false);
	const [filterRange, setFilterRange] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")})

	let urlString = `/human_resource/employees/payslip?employee=${employee}&start=${filterRange.start}&end=${filterRange.end}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setEmployeePayslipList(response.data.data);
				setLoadingEmployeePayslipList(false);
			} catch (e) {
				setError(true);
				setLoadingEmployeePayslipList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeePayslipList, filterRange ]);
	return { employeePayslipList, isLoadingEmployeePayslipList, error, setFilterRange };
};



const useEmployeeShiftsList = (pageNumber, business_branch_id) => {
    const [employeeShiftList, setEmployeeShiftList] = useState([]);
	const [isLoadingEmployeeShiftList, setLoadingEmployeeShiftList] = useState(true);
	const [error, setError] = useState(false);
	const [newEmployeeShiftData, setNewEmployeeShiftData] = useState(true);
	const [employee, setEmployee] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString;

	if (employee) {
		urlString = `/human_resource/employees/shifts?business_branch=${business_branch_id}&employee=${employee}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/human_resource/employees/shifts?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setEmployeeShiftList(response.data.data);
				setLoadingEmployeeShiftList(false);
				setNewEmployeeShiftData(false)
			} catch (e) {
				setError(true);
				setLoadingEmployeeShiftList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingEmployeeShiftList, newEmployeeShiftData, employee, filter ]);
	return { employeeShiftList, isLoadingEmployeeShiftList, error, setNewEmployeeShiftData, setEmployee, setFilter };
};


const useGivenPeriodShiftsList = (business_branch_id) => {
    const [givenPeriodShiftList, setGivenPeriodShiftList] = useState([]);
	const [isLoadingGivenPeriodShiftList, setLoadingGivenPeriodShiftList] = useState(true);
	const [error, setError] = useState(false);
	const [newGivenPeriodShiftData, setNewGivenPeriodShiftData] = useState(true);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});

	let urlString =`/human_resource/shifts/calendar?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setGivenPeriodShiftList(response.data.data);
				setLoadingGivenPeriodShiftList(false);
				setNewGivenPeriodShiftData(false)
			} catch (e) {
				setError(true);
				setLoadingGivenPeriodShiftList(false);
			}
		};
		fetchData();
	}, [isLoadingGivenPeriodShiftList, newGivenPeriodShiftData, filter ]);
	return { givenPeriodShiftList, isLoadingGivenPeriodShiftList, error, setNewGivenPeriodShiftData, setFilter };
};




// ######################################### SINGLE RECORD CALLS ####################################################


const useGetEmployee = (id) => {
  const [employee, setEmployee] = useState("");
  const [isLoadingEmployee, setLoadingEmployee] = useState(true);
  const [newEmployeeData, setNewEmployeeData] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(`/human_resource/employees/get/${id}`);
        setEmployee(response.data.data);
        setLoadingEmployee(false);
		setNewEmployeeData(false);
      } catch (e) {
        setError(true);
        setLoadingEmployee(false);
      }
    };
    fetchData();
  }, [id, isLoadingEmployee, newEmployeeData]);
  return { employee, isLoadingEmployee, error, setNewEmployeeData };
};


export {useGivenPeriodShiftsList, useEmployeeShiftsList,  useRenumerationTypesList, useDeductionTypesList, useAllowanceTypesList, useDepartmentsList, useEmployeesList, useEmployeeRolesList, useShiftsList, useEmployeeDocumentsList, useEmployeeAllowancesList, useEmployeeDeductionsList,useEmployeeRenumerationsList, usePayslipsList, useEmployeePayslipsList, useGetEmployee,}
