import * as React from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';

export default function LoadButton ({name, loading}) {
    return (
        <LoadingButton
            fullWidth
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            sx={{
                m: "2rem 0",
                p: "1rem",
            }}
        >
            <span>{name}</span>
        </LoadingButton>
    )
}
