
import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";




const PurchaseRequistionDetailForm = (props) => {


    const {index, 
        data,
        handleRemoveProductDetailRow, 
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue
        } = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    
	const [error, setError] = React.useState(false);
    const [errorCurrentStock, setErrorCurrentStock] = React.useState(false);
    const [errorMonthSales, setErrorMonthSales] = React.useState(false);
    const [productList, setProductList] = React.useState(data.product_info ? [data.product_info] : []);
    const business_branch_id = useSelector((state) => state.business_branch_id);
    
    const [unitsForGivenItemList, setUnitsForGivenItemList] = React.useState(data.product_units_list ? data.product_units_list : []);
    const [currentStock, setCurrentStock] = React.useState(data.quantity_remaining_at_requisition ? data.quantity_remaining_at_requisition : 0);
    const [monthSales, setMonthSales] = React.useState(data.quantity_sold_in_30_days ? data.quantity_sold_in_30_days : 0);

    // const fetchUnitsForGivenItemList = async (productId) => {
    //     try {
    //         const response = await requester.get(`/inventory/products/unit_list/${productId}`);
    //         setUnitsForGivenItemList(response.data.data);
    //     } catch (e) {
    //         setError(true);
    //     }
    // };

    // const fetchCurrentStockForGivenItem = async (productId) => {
    //     try {
    //         const response = await requester.get(`/inventory/stock/get/${productId}`);
    //         setCurrentStock(response.data.data.available_stock);
    //     } catch (e) {
    //         setErrorCurrentStock(true);
    //     }
    // };

    const fetchItemStats = async (productId) => {
        try {
            const response = await requester.get(`/inventory/products/stats/${productId}`);
            setMonthSales(response.data.data.quantity_sold_in_last_30_days);
            setCurrentStock(response.data.data.available_stock);
            setUnitsForGivenItemList(response.data.data.product_unit_details);
        } catch (e) {
            setErrorMonthSales(true);
            setErrorCurrentStock(true);
            setError(true);
        }
    };
     
    const fetchItemList = async (search) => {
        try {
            if (search){
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
                );
                setProductList(response.data.data);
            } else {
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
                );
                setProductList(response.data.data);
            }
            
        } catch (e) {
            setError(true);
        }
    };
    
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <p>#{index + 1}</p>
        </Box>
        <Box
          display="grid"
          gap="5px"
          p="0.5rem"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
          }}
        >
          <>
            <Autocomplete
              size="small"
              onOpen={() => {
                fetchItemList(null);
              }}
              name={`${name}.related_product`}
              onChange={(e, value) => {
                if (value) {
                  // fetchUnitsForGivenItemList(value.id);
                  // fetchCurrentStockForGivenItem(value.id);
                  fetchItemStats(value.id);
                }
                setFieldValue(
                  `${name}.related_product`,
                  value ? value.id : ''
                );
                setFieldValue(`${name}.related_product_unit`, '');
              }}
              sx={{ gridColumn: "span 3" }}
              options={productList}
              defaultValue={productList[0]}
              getOptionLabel={(option) =>
                `${option.product_name}  ${option.description}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, productList) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={productList.id}
                >
                  {productList.product_name} {productList.description}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  label="Item Name"
                  value={
                    data.related_product ? data.related_product ?? null : ""
                  }
                  error={
                    Boolean(
                      touched?.products_requisitioned &&
                        touched?.products_requisitioned[index]?.related_product
                    ) &&
                    Boolean(
                      errors?.products_requisitioned &&
                        errors?.products_requisitioned[index]?.related_product
                    )
                  }
                  helperText={
                    touched?.products_requisitioned &&
                    touched?.products_requisitioned[index]?.related_product &&
                    errors?.products_requisitioned &&
                    errors?.products_requisitioned[index]?.related_product
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    fetchItemList(e.currentTarget.value);
                  }}
                />
              )}
            />

            <TextField
              label="Quantity on Hand"
              size="small"
              type="number"
              disabled
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              value={(data.quantity_remaining_at_requisition = currentStock)}
              name={`${name}.quantity_remaining_at_requisition`}
              error={
                Boolean(
                  touched?.products_requisitioned &&
                    touched?.products_requisitioned[index]?.quantity_remaining_at_requisition
                ) &&
                Boolean(
                  errors?.products_requisitioned &&
                    errors?.products_requisitioned[index]?.quantity_remaining_at_requisition
                )
              }
              helperText={
                touched?.products_requisitioned &&
                touched?.products_requisitioned[index]?.quantity_remaining_at_requisition &&
                errors?.products_requisitioned &&
                errors?.products_requisitioned[index]?.quantity_remaining_at_requisition
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Quantity Sold in 1 month"
              size="small"
              type="number"
              disabled
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
              }}
              value={(data.quantity_sold_in_30_days = monthSales)}
              inputProps={{
                min: 0,
              }}
              name={`${name}.quantity_sold_in_30_days`}
              error={
                Boolean(
                  touched?.products_requisitioned &&
                    touched?.products_requisitioned[index]
                      ?.quantity_sold_in_30_days
                ) &&
                Boolean(
                  errors?.products_requisitioned &&
                    errors?.products_requisitioned[index]
                      ?.quantity_sold_in_30_days
                )
              }
              helperText={
                touched?.products_requisitioned &&
                touched?.products_requisitioned[index]
                  ?.quantity_sold_in_30_days &&
                errors?.products_requisitioned &&
                errors?.products_requisitioned[index]
                  ?.quantity_sold_in_30_days
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Quantity Requisitioned"
              size="small"
              type="number"
              inputProps={{
                min: 0,
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              value={data.quantity_requisitioned}
              name={`${name}.quantity_requisitioned`}
              error={
                Boolean(
                  touched?.products_requisitioned &&
                    touched?.products_requisitioned[index]?.quantity_requisitioned
                ) &&
                Boolean(
                  errors?.products_requisitioned &&
                    errors?.products_requisitioned[index]?.quantity_requisitioned
                )
              }
              helperText={
                touched?.products_requisitioned &&
                touched?.products_requisitioned[index]?.quantity_requisitioned &&
                errors?.products_requisitioned &&
                errors?.products_requisitioned[index]?.quantity_requisitioned
              }
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Reorder Item Unit"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={data.related_product_unit}
              name={`${name}.related_product_unit`}
              error={
                Boolean(
                  touched?.products_requisitioned &&
                    touched?.products_requisitioned[index]?.related_product_unit
                ) &&
                Boolean(
                  errors?.products_requisitioned &&
                    errors?.products_requisitioned[index]?.related_product_unit
                )
              }
              helperText={
                touched?.products_requisitioned &&
                touched?.products_requisitioned[index]?.related_product_unit &&
                errors?.products_requisitioned &&
                errors?.products_requisitioned[index]?.related_product_unit
              }
              sx={{ gridColumn: "span 2" }}
            >
              {unitsForGivenItemList.map((oneUnit, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={oneUnit.id}
                    selected={
                      oneUnit.id === data.related_product_unit ? true : false
                    }
                  >
                    {oneUnit.related_business_product_unit.name}
                  </MenuItem>
                );
              })}
            </TextField>

            {index > 0 ? (
              <Button
                value={index}
                onClick={() => {
                  handleRemoveProductDetailRow(index);
                }}
                size="small"
                variant="contained"
                sx={{
                  width: "20%",
                }}
              >
                Remove
              </Button>
            ) : null}
          </>
        </Box>
      </Box>
    );
  };
  
  export default PurchaseRequistionDetailForm;
  