import {
  Box,
  Grid,
  Button,
  Paper,
  InputBase,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStockTransferList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import ResponsiveDialog from "components/responsiveDialog";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import UploadCreateForm from "../forms/createUploadForm";

export default function UploadedFilesTable({ setIsSuccess, setIsFailed }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { stockTransferList, isLoadingStockTransferList, setSearch, setFilter, setNewStockTransferData } = useStockTransferList(1, 'send',business_branch_id);

  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [searchContent, setSearchContent] = useState("");


  const handleClickUploadOpen = async (e) => {
    setOpen(true);
  };

  const handleClickUploadClose = async (e) => {
    setOpen(false);
  };


  const handleClickDeleteOpen = async (e) => {
    const response = await requester.get(
      `/inventory/stock_transfer/get/${e.target.value}`
    );
    if (response.status === 200) {
      setOpenDelete(true);
    }
  };


  const handleClickView = async (e) => {
    const response = await requester.get(
      `/inventory/stock_transfer/get_form/${e.target.value}`
    );
    if (response.status === 200) {
      // navigate("/PayslipViewForm", { state: response.data.data });
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearch(searchContent);
  };

  const handleChangeFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <h4>Uploaded Files List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={3}>
            {/* <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search File Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper> */}
          </Grid>

          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                  }}
                >
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>
              
              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        max: endDate,
                      }}
                    />
                  </Paper>
                  
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        min: startDate,
                      }}
                    />
                  </Paper>
                  
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={3} sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={() => handleClickUploadOpen()}>
              UPLOAD FILE
            </Button>
            <FixedSizeDialog
              dialogHeading="Upload File Form"
              open={open}
              handleClose={handleClickUploadClose}
              negativeResponse="Cancel"
              maxWidth={"md"}
              scroll={"body"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  // backgroundColor={theme.palette.background.alt}
                >
                  <UploadCreateForm setOpen={setOpen}  setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} />
                </Box>
              }
            />
            
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                    <StyledTableCell style={{ minWidth: 50 }}>Date Uploaded</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>File</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 50 }}>Uploaded By</StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {stockTransferList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  stockTransferList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{moment(row.date_added).format("LLL")}</StyledTableCell>
                          <StyledTableCell>{row.id}</StyledTableCell>
                          <StyledTableCell>{row.added_by.first_name} {row.added_by.last_name}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>
                            {row.status === 0 ? (
                              <>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickDeleteOpen(e)}
                                >
                                  Delete
                                </Button>
                              </>
                            ) : null}
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Stock Transfer Form"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={stockTransferList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
