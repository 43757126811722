import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box
        width="100%"
        backgroundColor={theme.palette.background.secondary}
        textAlign="center"
        flexDirection="column"
        display="flex"
      >
        <Box
          sx={{
            marginTop: "16rem"
          }}
        >
          <Typography fontWeight="bold" fontSize="50px" color="#FFF">
            Jolilabs Pharmacy
          </Typography>
          <Typography fontSize="25px" color="#FFF">
            Here to make you feel better
          </Typography>
        </Box>
      </Box>

      <Box
        width={isNonMobileScreens ? "60%" : "100"}
        p="10rem"
        m="2rem auto"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ textAlign: "center", mb: "1.5rem" }}>
          Login
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
