import {Box,useTheme,Grid,Button,Paper,InputBase,MenuItem,TextField,Collapse,Typography,Card, CardActions, CardContent, CardMedia,} from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import FixedSizeDialog from "components/fixedSizeDialog";
import ItemForm from "../forms/itemCreateForms/itemForm";
import ItemImageEditForm from "../forms/itemCreateForms/itemImageEditForm";
import EditItemForm from "../forms/itemCreateForms/editItemForm";
import { useItemList,useItemTypeList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import { requester } from "api/pesabackend";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { URL } from "api/pesabackend";

export default function Items({ setIsSuccess }) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {
    productList,
    isLoadingItemList,
    setNewItemData,
    setSearchItem,
    setItemTypeFilter,
  } = useItemList(1, business_branch_id);
  const { typeList, isLoadingItemTypeList } = useItemTypeList(
    1,
    business_branch_id
  );

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  
  const [openView, setOpenView] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState("");
  const [clickedItem, setClickedItem] = useState("");
  const [searchContent, setSearchContent] = useState("");

  const handleClickOpen = () => {
    setOpenCreate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  

  const handleClickEditOpen = async (e) => {
    setClickedItem(e.target.value);
    const response = await requester.get(`/inventory/products/get_edit/${e.target.value}`);
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    setClickedItem(e.target.value);
    const response = await requester.get(`/inventory/products/get/${e.target.value}`);
    setOpenDelete(true);
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/inventory/products/delete/${clickedItem}`);
    setIsSuccess(true);
    setNewItemData(true);
    setOpenDelete(false);
  };

  

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearchItem(searchContent);
  };

  const handleChangeFilter = async (e) => {
    setItemTypeFilter(e.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function DetailDropDown(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [itemData, setItemData] = useState(null);
    const [openEditImage, setOpenEditImage] = useState(false);

    const handleClickOpenEditImage = () => {
      setOpenEditImage(true);
    };

    const handleCloseEditImage = () => {
      setOpenEditImage(false);
    };

    const handleClickView = async (id) => {
      // const clickedItem = setClickedItem(e.target.value);
      const response = await requester.get(`/inventory/products/get/${id}`);
      setItemData(response.data.data);
    };

    const handleClickViewClose = async (e) => {};


    return (
      <>
        <StyledTableRow key={row.id}>
          <StyledTableCell style={{ padding: "10px 10px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                setOpen(!open);
                handleClickView(row.id);
              }}
            >
              {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>
            <img
              alt="Image"
              src={`${URL}${row.picture}`}
              style={{
                display: "inline-block",
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }}
            />
          </StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.product_name}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.description}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.related_product_category.category_name}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.related_product_sub_category  ? row.related_product_sub_category.sub_category_name  : null}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.reorder_level}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.maximum_stock > 0 ? row.maximum_stock : "N/A"}</StyledTableCell>
          <StyledTableCell style={{ padding: "10px 10px" }}>{row.expiry_limit}</StyledTableCell>
          <StyledTableCell
            key={row.id}
            style={{ textAlign: "center", minWidth: 150 }}
          >
            <Button
              value={row.id}
              size="small"
              onClick={(e) => handleClickEditOpen(e)}
            >
              Edit
            </Button>
            <Button
              value={row.id}
              size="small"
              onClick={(e) => handleClickDeleteOpen(e)}
            >
              Delete
            </Button>
          </StyledTableCell>
          <ResponsiveDialog
            dialogHeading="Edit"
            open={openEdit}
            handleClose={handleClickEditClose}
            negativeResponse="Cancel"
            content={
              <Box
                p="1rem"
                borderRadius="0.5rem"
                // backgroundColor={theme.palette.background.alt}
              >
                <EditItemForm
                  setOpen={setOpenEdit}
                  setNewItemData={setNewItemData}
                  id={clickedItem}
                  editData={editData}
                  setIsSuccess={setIsSuccess}
                />
              </Box>
            }
          />

          <ResponsiveDialog
            dialogHeading="Are you sure you want to delete this item?"
            open={openDelete}
            handleClose={handleClickDeleteClose}
            negativeResponse="Cancel"
            content={
              <Box
                p="1rem"
                borderRadius="0.5rem"
                backgroundColor={theme.palette.background.alt}
              >
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={handleClickDelete}
                  sx={{ m: "1rem 0", p: "1rem" }}
                >
                  Yes, Delete
                </Button>
              </Box>
            }
          />

          <ResponsiveDialog
            dialogHeading="Are you sure you want to delete?"
            open={openView}
            handleClose={handleClickViewClose}
            negativeResponse="Cancel"
            content={
              <Box
                p="1rem"
                borderRadius="0.5rem"
                // backgroundColor={theme.palette.background.alt}
              ></Box>
            }
          />
        </StyledTableRow>
        <StyledTableRow key={row.id}>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: "10px 10px 10px 10px" }}>
                <Typography variant="h6" gutterBottom component="div">
                  Item Details
                </Typography>
                {
                  itemData ? 
                  <Card sx={{ display: "flex" }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200, height: 200 }}
                      image={`${URL+itemData.picture}`}
                      alt={`${itemData.product_name}`}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", width: "80%" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="h5">{itemData.product_name}</Typography>
                          <Typography variant="subtitle1" color="text.secondary" component="div"> {itemData.description}</Typography>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            pl: 1,
                            pb: 1,
                          }}
                        >
                          <CardActions>
                            <Button
                              size="small"
                              onClick={handleClickOpenEditImage}
                            >
                              Edit Picture
                            </Button>
                          </CardActions>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", margin: "15px" }}>
                        <Typography variant="subtitle1" color="text.secondary" component="div"> <b>Smallest Unit: </b>{itemData.smallest_unit.related_business_product_unit.name}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"> <b>Selling Price: </b>{itemData.smallest_unit.unit_selling_price}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"> <b>Category: </b>{itemData.related_product_category.category_name}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"> <b>Sub-category: </b>{itemData.related_product_sub_category ? itemData.related_product_sub_category.sub_category_name: ''}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"><b>Reorder Level: </b>{itemData.reorder_level}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"><b>Expiry Limit: </b>{itemData.expiry_limit}</Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div"><b>Maximum Stock: </b>{itemData.maximum_stock}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "column", margin: "15px"  }}>
                        <Typography component="div" variant="h6"><b>OTHER UNITS</b></Typography>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell style={{ minWidth: 50,padding: "6px 20px" }}>Unit Name</StyledTableCell>
                              <StyledTableCell style={{ minWidth: 150,padding: "6px 20px" }}>Number Of smallest units</StyledTableCell>
                              <StyledTableCell style={{ minWidth: 50,padding: "6px 20px" }}>Selling Price</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {itemData.product_units_list.length === 0 ? (
                              <StyledTableRow>
                                <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No other units</h3>
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              itemData.product_units_list
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                  return (
                                    <StyledTableRow key={row.id}>
                                      <StyledTableCell style={{ padding: "6px 20px" }}>{row.related_business_product_unit.name}</StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 20px", textAlign: "center" }}>{row.number_of_smallest_units}</StyledTableCell>
                                      <StyledTableCell style={{ padding: "6px 20px" }}>{row.unit_selling_price}</StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Card> : null
                }

                <FixedSizeDialog
                  dialogButtonName="Edit Item Image "
                  dialogHeading="Edit Item Image"
                  open={openEditImage}
                  handleClickOpen={handleClickOpenEditImage}
                  handleClose={handleCloseEditImage}
                  negativeResponse="Cancel"
                  maxWidth={"md"}
                  content={
                    <Box
                      p="1rem"
                      borderRadius="0.5rem"
                      backgroundColor={theme.palette.background.alt}
                    >
                      <ItemImageEditForm
                        itemId={row.id}
                        setNewItemData={setNewItemData}
                        setIsSuccess={setIsSuccess}
                        setOpen={setOpen}
                      />
                    </Box>
                  }
                />

              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  return (
    <Box>
      <h4>Item List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Paper
              sx={{
                m: "10px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Item Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m:"8px"
              }}
            >
              <h4>FILTER BY ITEM CATEGORY:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="ITEM CATEGORIES"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {typeList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.category_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Paper>
            </Box>
          </Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Add Item
            </Button>
            <ResponsiveDialog
              dialogButtonName="Add Item "
              dialogHeading="Add a New Item"
              open={openCreate}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <ItemForm
                    setOpen={setOpenCreate}
                    setNewItemData={setNewItemData}
                    setIsSuccess={setIsSuccess}
                  />
                </Box>
              }
            ></ResponsiveDialog>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  {/* <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell> */}
                  <StyledTableCell />
                  <StyledTableCell style={{ padding: "10px 10px" }}>Image</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Item Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 200 }}>Description</StyledTableCell>
                  {/* <StyledTableCell style={{ padding: "10px 10px" }}>Image</StyledTableCell> */}
                  <StyledTableCell style={{ padding: "10px 10px" }}>
                    Item Category
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "10px 10px" }}>
                    Item Sub-Category
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "10px 10px" }}>
                    Reorder Level
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "10px 10px" }}>
                    Maximum Stock
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "10px 10px" }}>
                    Expiry Limit
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {productList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  productList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return <DetailDropDown row={row} />;
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={productList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
