
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme, Alert, Breadcrumbs, Link, Typography, Paper} from "@mui/material";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import StockTransferDetailForm from "./stockTransferDetailForm";
import { useGetBusinessBranchList } from "api/hooks/business";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment/moment";
import { requester } from "api/pesabackend";

const transferSchema = yup.object().shape({
  detail: yup.string().required("required"),
  grand_total: yup.number().required("required"),
  transfer_date: yup.date().required("required"),
  transferred_to: yup.number().required("required"),
  products_transferred: yup
    .array()
    .of(
      yup.object().shape({
        related_product: yup.number().required("required"),
        related_product_unit: yup.string().required("required"),
        available_quantity: yup.number().required("required"),
        batch: yup.string().required("required"),
        expiry_date: yup.date(),
        quantity: yup.number().required("required"),
        amount: yup.number().required("required"),
      })
    )
    .required("Please add a transfer record items")
    .min(1, "At least one transfer must be added"),
});


const StockTransferCreateForm = (props) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { businessBranchList } = useGetBusinessBranchList(1, business_id);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token =  useSelector((state) => state.token);

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesTransfer = {
    detail: "",
    grand_total: 0,
    transfer_date: moment().format('YYYY-MM-DD'),
    transferred_to: "",
    products_transferred:[
      {
        related_product: '', related_product_unit: '', batch: '',available_quantity: 0, expiry_date: moment().format('YYYY-MM-DD'), quantity: 1, amount: 0, smallest_unit:"",  data_id: uuidv4(),
      }
    ]
  };

  const[StockTransferDetailFormData, setStockTransferDetailFormData] = useState([{quantity: 1, amount: '',  data_id: uuidv4(),}]);

  const [grandTotal,setGrandTotal] = useState(initialValuesTransfer.grand_total);
  

  const saveTransfer = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedItemResponse = await requester.post(
      `/inventory/stock_transfer/add`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/transfers");
      }, 1500);
      
    };

    if (savedItemResponse.data.status===400) {
      setIsFailed(true)
      setIsFailedError(savedItemResponse.data.errors)
    };

  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.amount)
    }, 0);
    setGrandTotal(grandTotalCalculation.toFixed(2));
  };
  

  const handleItemAmountChange = (value, index) =>{
    const newArray = StockTransferDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, amount: Number(value)}
        }
        return item
    })
    setStockTransferDetailFormData(newArray);
    handleGrandTotal(newArray);
  };
    

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {quantity: '', amount: '', data_id: uuidv4()};
    setStockTransferDetailFormData([...StockTransferDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = StockTransferDetailFormData
    newFormData.splice(index,1);
    setStockTransferDetailFormData(newFormData);
    handleGrandTotal(newFormData);    
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveTransfer(values, onSubmitProps);
  };
  
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
            paddingX: "40px",
          }}
        >
          {isSuccess ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="success">
              Transfer Invoice recorded successfully!
            </Alert>
          ) : isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => navigate("/transfers")}
            >
              <AssignmentReturnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Stock Transfer
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Record Stock Transfer Form</b>
            </Typography>
          </Breadcrumbs>
          <Paper sx={{ p: "10px" }}>
            <Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValuesTransfer}
                validationSchema={transferSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "40%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label>Transfer Date: </label>
                          <TextField
                            label=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.transfer_date}
                            name="transfer_date"
                            type="date"
                            error={
                              Boolean(touched.transfer_date) &&
                              Boolean(errors.transfer_date)
                            }
                            helperText={
                              touched.transfer_date && errors.transfer_date
                            }
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                        <TextField
                          label="Select Business Branch"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.transferred_to}
                          name="transferred_to"
                          select
                          error={
                            Boolean(touched.transferred_to) &&
                            Boolean(errors.transferred_to)
                          }
                          helperText={
                            touched.transferred_to && errors.transferred_to
                          }
                          sx={{
                            width: "100%",
                            marginBottom: "15px",
                          }}
                        >
                          {businessBranchList.map((row, index) => {
                            return (
                              <MenuItem key={index} value={row.id}>
                                {row.branch_name}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        
                      </Box>

                      <TextField
                        label="Transfer Details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={8}
                        value={values.detail}
                        name="detail"
                        error={
                          Boolean(touched.detail) && Boolean(errors.detail)
                        }
                        helperText={touched.detail && errors.detail}
                        sx={{
                          width: "40%",
                          margin: "15px 0 15px 0",
                        }}
                      />
                    </Box>
                    <Box
                      gridColumn="span 4"
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                    >
                      <h5>Input the products transferred here</h5>

                      <FieldArray
                        name="products_transferred"
                        render={(arrayHelpers) => {
                          const products_transferred = values.products_transferred;

                          return (
                            <>
                              {products_transferred.map((row, index) => {
                                return (
                                  <StockTransferDetailForm
                                    key={row.data_id}
                                    index={index}
                                    data={row}
                                    handleRemoveProductDetailRow={(index) => {
                                      arrayHelpers.remove(index);
                                      handleRemoveProductDetailRow(index);
                                    }}
                                    handleItemAmountChange={
                                      handleItemAmountChange
                                    }
                                    handleChange={handleChange}
                                    errors={arrayHelpers.form.errors}
                                    touched={arrayHelpers.form.touched}
                                    name={`products_transferred[${index}]`}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                  />
                                );
                              })}

                              <Button
                                onClick={() => {
                                  arrayHelpers.push({
                                    related_product: "",
                                    related_product_unit: "",
                                    batch: "",
                                    smallest_unit: "",
                                    available_quantity: 0,
                                    expiry_date: moment().format("YYYY-MM-DD"),
                                    quantity: 1,
                                    amount: 0,
                                    data_id: uuidv4(),
                                  });
                                  handleAddProductDetailSubmitData();
                                }}
                                sx={{
                                  m: "1rem 0",
                                }}
                                size="small"
                                variant="contained"
                              >
                                Add Product
                              </Button>
                            </>
                          );
                        }}
                      />
                    </Box>

                    <Box
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mx: "2px",
                        }}
                      >
                        <label>Grand Total: </label>
                        <TextField
                          label=""
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={(values.grand_total = grandTotal)}
                          name="grand_total"
                          disabled
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          error={
                            Boolean(touched.grand_total) &&
                            Boolean(errors.grand_total)
                          }
                          helperText={touched.grand_total && errors.grand_total}
                          sx={{
                            // width: "100%",
                            marginBottom: "15px",
                          }}
                        />
                      </Box>
                    </Box>

                    {/* BUTTONS */}
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Save Transfer
                      </Button>
                      <Button
                        onClick={() => {
                          resetForm();
                          navigate(`/transfer`);
                        }}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                          m: "1rem",
                          p: "0.5rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default StockTransferCreateForm;
