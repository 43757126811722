import { View, StyleSheet, Image } from "@react-pdf/renderer";
import JolilabsLogo from "../Logo/jolilabs_logo.png";
// import { Style } from "@react-pdf/types";

export const PdfView = ({
  mh = 20,
  p = 7,
  flexDirection = "column",
  style,
  fixed = false,
  children,
}) => {
  return (
    <View
      style={{ ...style, marginHorizontal: mh, padding: p, flexDirection: flexDirection }}
      fixed={fixed}
    >
      {children}
    </View>
  );
};


export const PdfBorderView = ({
  mh = 20,
  mv = 50,
  p = 2,
  borderColor = 'grey',
  bw = 2,
  style,
  fixed = false,
  children,
}) => {
  return (
    <View
      style={{
        ...style,
        marginHorizontal: mh,
        marginVertical: mv,
        padding: p,
        borderColor: borderColor,
        borderWidth: bw
      }}
      fixed={fixed}
    >
      {children}
    </View>
  );
};


export const PdfHeaderView = ({
  bg = "#108ED4",
  p = 10,
  
  alignItems = "center",
  fixed = false,
  children,
}) => {
  return (
    <View style={{  alignItems: alignItems }} fixed={fixed}>
      {/* <View style={{  margin: 0, padding: 0 }}> */}
      <Image
        src={JolilabsLogo}
        style={{
          display: "inline-block",
          width: "150px",
          height: "50px",
          objectFit: "contain",
        }}
      />
      {/* </View> */}
      {/* <View
        style={{
          // flexGrow: 4,
          backgroundColor: bg,
          padding: p,
          // alignItems: alignItems,
        }}
        fixed={fixed}
      >
        {children}
      </View> */}
    </View>
  );
};


export const PdfSubHeaderView = ({
  bg = "#F00B6B",
  p = 10,
  alignItems = "center",
  fixed = false,
  children,
}) => {
  return (
    <View
      style={{ padding: p, alignItems: alignItems }} 
      fixed={fixed}
    >
      {children}
    </View>
  );
};


export const PdfFooterView = ({
  bg = "#3AB9EA",
  p = 10,
  alignItems = "center",
  fixed = false,
  children,
}) => {
  return (
    <View
      style={[
        { position: "absolute", bottom: 0, left: 0, right: 0 },
        {  padding: p, alignItems: alignItems },
      ]}
      fixed={fixed}
    >
      {children}
    </View>
  );
};


export const PdfGrid = ({
  cols = 1,
  bg = 'white',
  children,
}) => {
  return (
    <View
      style={{ width: `${100/cols}%`, backgroundColor: bg}}
    >
      {children}
    </View>
  );
};