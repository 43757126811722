import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import SearchIcon from '@mui/icons-material/Search';
import DummyTable from "components/Tables/DummyTable";
import IconButton from '@mui/material/IconButton';
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import moment from "moment";
import { useGetReceiptsList } from "api/hooks/billing";
import { currencyFormatter } from "utilities/currencyFormatter";

export default function Receipts ({setIsSuccess}) {
  
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {receiptsList, isLoadingReceiptsList, errorReceiptsList} = useGetReceiptsList(1, business_branch_id)

  const invoiceList = []
  
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="UNPAID" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="PAID" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const handleClickEditOpen = async (e) => {
  // const response = await inventoryApi.get(`/product_purchase/get/${e.target.value}`);
  // if (response.status === 200) {
  //   navigate("/editPurchaseForm", { state: response.data.data })
  // } 
  };

  const handleClickEditClose = () => {
  setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
  // const response = await inventoryApi.get(`/product_purchase/get/${e.target.value}`);
  // if (response.status===200){
  //   setOpenDelete(true);
  // }
  };

  const handleClickDeleteClose = () => {
  setOpenDelete(false)
  };


  const handleClickView = async (e) => {
    // const response = await inventoryApi.get(`/product_purchase/get_form/${e.target.value}`);
    // if (response.status === 200) {
    //   // navigate("/purchaseViewForm", { state: response.data.data });
    //   setViewData(response.data.data);
    //   setOpenView(true);
    // }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h4>Receipts List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Receipt Number"
                value={searchContent}
                // onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid xs={4} sx={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              onClick={() => navigate("/purchaseForm", { formType: "create" })}
            >
              CREATE RECEIPT
            </Button> */}
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          {isLoadingReceiptsList ? (
            <DummyTable />
          ) : (
          <>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Date Created</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Receipt No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Invoice No</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Amount</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Customer</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Created By</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {receiptsList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                  receiptsList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date_created).format("LLL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.receipt_number}</StyledTableCell>
                          <StyledTableCell>{row.related_invoice.invoice_number}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(row.amount_paid))}</StyledTableCell>
                          <StyledTableCell>{row.related_invoice.related_customer? row.related_invoice.related_customer.related_user.first_name+' '+row.related_invoice.related_customer.related_user.last_name : null}</StyledTableCell>
                          <StyledTableCell>{`${row.created_by.first_name} ${row.created_by.last_name}`}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              size="small"
                              onClick={(e) => navigate("/invoiceDetails", { state: { id: row.related_invoice.id }})}
                            >
                              View Details
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={receiptsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};