import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert,Breadcrumbs,Link,Typography,} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ShiftDetailForm from "./shiftAssignmentDetail";
import { useState, useEffect } from "react";
import { requester } from "api/pesabackend";
import { useShiftsList, useEmployeesList } from "api/hooks/humanResource";
import moment from "moment";

const shiftAssignmentSchema = yup.object().shape({
  shifts: yup
    .array()
    .of(
      yup.object().shape({
        related_employee: yup.number().required("required"),
        related_shift: yup.number().required("required"),
        assignment_period: yup.string().required("required"),
        period_assigned: yup.string().required("required"),
      })
    )
    .min(1, "At least one shift must be assigned"),
});



const ShiftAssignmentForm = (props) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const {shiftList} = useShiftsList(1, business_branch_id);
  const {employeeList} = useEmployeesList(1, business_branch_id);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValues = {
    shifts: [
      {
        related_employee: "",
        related_shift: "",
        assignment_period: "DAY",
        period_assigned: moment().format("YYYY-MM-DD"),
      },
    ],
  };



  const saveRecord = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post( `/human_resource/employees/assign_shift`, JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setNewEmployeeShiftData(true);
      props.setOpen(false);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveRecord(values, onSubmitProps);
  };

  return (
    <Box>
      
          {isFailed ? (
            <Alert sx={{ m: "15px" }} variant="filled" severity="error">
              Operation was not successful!
            </Alert>
          ) : null}

          <Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={shiftAssignmentSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                 
                  <Box
                    gridColumn="span 4"
                    border={`1px solid ${palette.neutral.medium}`}
                    borderRadius="2px"
                    p="0.5rem"
                    sx={{ width: "100%" }}
                  >
                    <h5>Select an employee to input their shift here:</h5>

                    <FieldArray
                      name="shifts"
                      render={(arrayHelpers) => {
                        const shifts = values.shifts;

                        return (
                          <>
                            {shifts.map((row, index) => {
                              return (
                                <ShiftDetailForm
                                  key={row.index}
                                  index={index}
                                  data={row}
                                  handleRemoveShiftDetailRow={(index) => {
                                    arrayHelpers.remove(index);
                                  }}
                                  handleChange={handleChange}
                                  errors={arrayHelpers.form.errors}
                                  touched={arrayHelpers.form.touched}
                                  name={`shifts[${index}]`}
                                  handleBlur={handleBlur}
                                  shiftList={shiftList}
                                  employeeList={employeeList}
                                />
                              );
                            })}

                            <Button
                              onClick={() => {
                                arrayHelpers.push({
                                  related_employee: "",
                                  related_shift: "",
                                  assignment_period: "DAY",
                                  period_assigned: moment().format("YYYY-MM-DD"),
                                });
                                // handleAddProductDetailSubmitData();
                              }}
                              sx={{
                                m: "1rem 0",
                              }}
                              size="small"
                              variant="contained"
                            >
                              Add Another Assignment
                            </Button>
                          </>
                        );
                      }}
                    />
                  </Box>

                  {/* BUTTONS */}
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        m: "1rem",
                        p: "0.5rem",
                      }}
                    >
                      Save Shift Assignments
                    </Button>
                    
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
    </Box>
  );
};

export default ShiftAssignmentForm;
