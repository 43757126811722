import {Box,Grid,Button,Paper,InputBase,MenuItem,TextField,} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCustomerFeedbackList } from "api/hooks/customer";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import {  requester } from "api/pesabackend";
import CustomerFeedbackForm from "../forms/customerFeedbackForm";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import EditCustomerFeedbackForm from "../forms/editEustomerFeedbackForm";
import ViewCustomerFeedbackForm from "../forms/viewCustomerFeedbackForm";

const labels = {
  0.5: 'Very Disappointing',
  1: 'Disappointing',
  1.5: 'Very Poor',
  2: 'Poor',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};


export default function CustomerFeedbackList({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {customerFeedbackList, isLoadingCustomerFeedbackList, setNewCustomerFeedbackData, setFilter} = useCustomerFeedbackList(1, business_branch_id)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [clickedItem, setClickedItem] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  const handleClickEditOpen = async (e) => {
    const response = await requester.get(
      `/customers/feedback/get/${e.target.value}`
    );
    if (response.status === 200) {
      setViewData(response.data);
      setOpenEdit(true);
    }
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    setClickedItem(e.target.value)
    const response = await requester.get(
      `/customers/feedback/get/${e.target.value}`
    );
    if (response.status === 200) {
      setOpenDelete(true);
    }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const response = await requester.post(`/customers/feedback/delete/${clickedItem}`);
    if (response.status === 201) {
      setIsSuccess(true)
      setNewCustomerFeedbackData(true)
      setOpenDelete(false);
    }
    
  };

  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const handleClickView = async (e) => {
    const response = await requester.get( `/customers/feedback/get_form/${e.target.value}`);
    if (response.status === 200) {
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <h4>Customer Feedback List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            
          </Grid>

          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FEEDBACK PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 100,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS WEEK"
                  size="small"
                  onChange={(e) => {
                    handleChangePeriodFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>

              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 100,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                    />
                  </Paper>

                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 100,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                    />
                  </Paper>
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={2} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{ m: "10px" }}
              onClick={handleClickOpen}
            >
              ADD FEEDBACK
            </Button>
            <FixedSizeDialog
              dialogButtonName="Add Customer Feedback"
              dialogHeading="Leave us feedback on the customer's experience"
              open={open}
              maxWidth={"md"}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box p="1rem" borderRadius="0.5rem">
                  <CustomerFeedbackForm setOpen={setOpen} setNewCustomerFeedbackData={setNewCustomerFeedbackData} setIsSuccess={setIsSuccess} />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Date Added</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Customer Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Customer Contact</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Customer Rating</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Follow-Up Status</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Follow-Up Appointment</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Followed-Up By</StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", minWidth: 150 }}
                  >
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {customerFeedbackList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={12}
                      style={{ textAlign: "center" }}
                    >
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  customerFeedbackList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{moment(row.date_added).format("LL")}</StyledTableCell>
                          <StyledTableCell>{row.related_customer ? row.related_customer.related_user.first_name+' '+row.related_customer.related_user.last_name : "N/A"}</StyledTableCell>
                          <StyledTableCell>{row.phone_number}</StyledTableCell>
                          <StyledTableCell>{labels[row.rating]}</StyledTableCell>
                          <StyledTableCell>{row.status === 0 ? "PENDING" : "COMPLETED"}</StyledTableCell>
                          <StyledTableCell>{moment(row.follow_up_appointed_date).format("LL")}</StyledTableCell>
                          <StyledTableCell>{row.followed_up_by? row.followed_up_by.first_name+' '+row.followed_up_by.last_name: null}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>
                            { row.status === 0 ?
                              <>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickEditOpen(e)}
                                >
                                  EDIT
                                </Button>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) => handleClickDeleteOpen(e)}
                                >
                                  DELETE
                                </Button>
                              </>
                              : null
                            }
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Customer Fedback"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={<Box p="1rem" borderRadius="0.5rem"><ViewCustomerFeedbackForm viewData={viewData} setNewCustomerFeedbackData={setNewCustomerFeedbackData} setIsSuccess={setIsSuccess} setOpen={setOpenView}/></Box>}
                          />

                          <FixedSizeDialog
                            dialogHeading="Edit Customer Fedback"
                            open={openEdit}
                            handleClose={handleClickEditClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={<Box p="1rem" borderRadius="0.5rem"><EditCustomerFeedbackForm editData={viewData.data} setNewCustomerFeedbackData={setNewCustomerFeedbackData} setIsSuccess={setIsSuccess} setOpen={setOpenEdit}/></Box>}
                          />

                          <FixedSizeDialog
                            dialogHeading="Delete Customer Fedback"
                            open={openDelete}
                            handleClose={handleClickDeleteClose}
                            negativeResponse="Cancel"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box p="1rem" borderRadius="0.5rem" sx={{ justifyContent:"center" }}>
                                <h4>Are you sure you want to delete?</h4>
                                <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  onClick={handleClickDelete}
                                  sx={{ m: "1rem 0", p: "1rem" }}
                                >
                                  Yes, Delete
                                </Button>
                              </Box>}
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={customerFeedbackList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
