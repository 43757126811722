import * as React from "react";
import { Box, Paper, MenuItem, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import BasicCard from "components/Cards/Card";
import LineChart from "components/Graphs/LineChart";
import { useState } from "react";
import moment from "moment";
import { useGetBusinessBranchList } from "api/hooks/business";
import { useYearsList } from "api/hooks/inventory";
import { useBusinessBranchDashboard } from "api/hooks/dashboards";

export default function BusinessDashboard() {
  const business_id = useSelector((state) => state.business_id);
  const { businessBranchList, isLoadingBusinessBranchList, errorBusinessBranchList} = useGetBusinessBranchList(1, business_id)
  const {businessBranchDashboard, isLoadingBusinessBranchDashboard, error, setBusinessBranch, setPeriod} = useBusinessBranchDashboard();
  const {yearList} = useYearsList();
  const [periodSelect, setPeriodSelect] = useState(moment().format("YYYY"));

  const handlePeriodSelect = (e) => {
    setPeriod(e.target.value);
    setPeriodSelect(e.target.value);
  };

  


  const outOfStockCount = businessBranchDashboard ? businessBranchDashboard.out_of_stock_count : 0;
  const expiredStockCount = businessBranchDashboard ? businessBranchDashboard.expired_stock_count: 0;
  const totalNumberOfItems = businessBranchDashboard ? businessBranchDashboard.item_count : 0;
  const purchasesInYear = businessBranchDashboard ? businessBranchDashboard.year_purchase_record: [];
  const salesInYear = businessBranchDashboard ? businessBranchDashboard.year_sales_record : [];

  return (
    <Box>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs={4}>
              <h1>Dashboard</h1>
            </Grid>

            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  m: "4px",
                }}
              >
                <h4>SELECT BUSINESS BRANCH:</h4>
                <Paper
                  sx={{
                    m: "10px",
                    display: "flex",
                    alignItems: "center",
                    minWidth: 170,
                    maxWidth: 200,
                  }}
                >
                  <TextField
                    label="BUSINESS BRANCH"
                    select
                    size="small"
                    onChange={(e) => setBusinessBranch(e.target.value)}
                    sx={{
                      minWidth: 50,
                      width: "100%",
                      // m: "10px",
                      // backgroundColor: "white",
                    }}
                  >
                    {businessBranchList.map((row, index) => {
                      return (
                        <MenuItem value={row.id}> {row.branch_name}</MenuItem>
                      );
                    })}
                  </TextField>
                </Paper>
              </Box>
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  m: "4px",
                }}
              >
                <h4>YEAR:</h4>
                <Paper
                  sx={{
                    m: "10px",
                    display: "flex",
                    alignItems: "center",
                    minWidth: 100,
                    maxWidth: 200,
                  }}
                >
                  <TextField
                    label="Year"
                    size="small"
                    select
                    defaultValue={moment().format("YYYY")}
                    onChange={(e) => {
                      handlePeriodSelect(e);
                    }}
                    sx={{
                      minWidth: 50,
                      width: '100%',
                      // m: "10px",
                      // backgroundColor: "white",
                    }}
                  >
                    {yearList.map((year, index)=>{
                        return(
                          <MenuItem value={year.year}>{year.year}</MenuItem>
                        )
                      })}
                  </TextField>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <BasicCard name={"SALES"} value={""} />
          <BasicCard name={"PAYMENTS DUE"} value={""} />
          <BasicCard name={"CREDIT"} value={""} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <BasicCard name={"STOCK ITEMS"} value={totalNumberOfItems} />
          <BasicCard name={"OUT OF STOCK"} value={outOfStockCount} />
          <BasicCard name={"EXPIRED"} value={expiredStockCount} />
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Paper
            sx={{
              padding: 3,
              marginY: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 8, marginX: 6 }}>
              <h2>Monthly Sales & Purchases</h2>
              <LineChart
                label={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "Septmeber",
                  "October",
                  "November",
                  "December",
                ]}
                data={[
                  {
                    label: `Sales for ${periodSelect}`,
                    data: salesInYear,
                    fill: false,
                    borderColor: "rgb(75, 192, 192)",
                    tension: 0.1,
                  },
                  {
                    label: `Purchases for ${periodSelect}`,
                    data: purchasesInYear,
                    fill: false,
                    borderColor: "rgb(45, 100, 170)",
                    tension: 0.1,
                  },
                ]}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
