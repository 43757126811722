import { Box, Paper, Button } from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import TableFooter from "@mui/material/TableFooter";
// import ReactToPrint from "react-to-print";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PayslipPdf from "./payslipPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

const PayslipViewForm = (props) => {
  // const [isSidebar, setIsSidebar] = useState(true);
  const user = useSelector((state) => state.user);
  const { viewData } = props;
  // const location = useLocation();
  const componentRef = useRef();

  const id = viewData.id;
  const payslip_for = viewData.payslip_for;
  const renumeration_detail = viewData.renumeration_detail;
  const allowance_detail = viewData.allowance_detail;
  const deduction_detail = viewData.deduction_detail;
  const total_renumeration = currencyFormatter(parseFloat(viewData.total_renumeration));
  const total_deduction = currencyFormatter(parseFloat(viewData.total_deduction));
  const total_allowance = currencyFormatter(parseFloat(viewData.total_allowance));
  const net_earnings = currencyFormatter(parseFloat(Number(viewData.total_renumeration) + Number(viewData.total_allowance) - Number(viewData.total_deduction)));
  const payment_date = viewData.payment_date;
  const related_employee = viewData.related_employee;

  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>PAYSLIP</h1>
            <h2># {id}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                EMPLOYEE INFORMATION
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {related_employee.first_name} {related_employee.last_name}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address: {related_employee.address}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {related_employee.phone_number}
              </p>

              <br />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                Date of Payment
              </h4>
              <p style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                {moment(payment_date).format("LL")}
              </p>
            </Box>
          </Box>
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Payslip For
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              
              {payslip_for}
            </p>
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Earnings
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                      width: "35%",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!renumeration_detail ? (
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                ) : (
                  renumeration_detail.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {row.related_renumeration_type.renumeration_name}
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                            textAlign: "right",
                          }}
                        >
                          {withoutSymbolFormatter(parseFloat(row.amount))}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL EARNINGS</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    <b>{total_renumeration}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL EARNINGS
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    {total_renumeration}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Allowances
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                      width: "35%",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!allowance_detail ? (
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                ) : (
                  allowance_detail.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {row.related_allowance_type.allowance_name}
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                            textAlign: "right",
                          }}
                        >
                          {withoutSymbolFormatter(parseFloat(row.amount))}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL ALLOWANCES</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    <b>{total_allowance}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL ALLOWANCES
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    {total_allowance}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Deductions
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                      width: "35%",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!deduction_detail ? (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ padding: "6px 6px", border: "1px solid" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{ padding: "6px 6px", border: "1px solid" }}
                    ></StyledTableCell>
                  </StyledTableRow>
                ) : (
                  deduction_detail.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {row.related_deduction_type.deduction_name}
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                            textAlign: "right",
                          }}
                        >
                          {withoutSymbolFormatter(parseFloat(row.amount))}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL DEDUCTIONS</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    <b>{total_deduction}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL DEDUCTIONS
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    {total_deduction}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <b>NET PAY</b>
                  </TableCell>

                  <TableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      backgroundColor: "lightgrey",
                      textAlign: "right",
                      width: "35%",
                    }}
                  >
                    <b>{net_earnings}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Box>
          <br />
        </Box>
      </Paper>
      {/* <ReactToPrint
        documentTitle={`Purchase Order #${purchase_order_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              my: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<PayslipPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Payslip #${id}`}>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: "20%",
            my: "15px",
            mx: "5px",
          }}
        >
          Download
        </Button>
      </PDFDownloadLink>
    </>
  );
};

export default PayslipViewForm;
