import { Box, Grid, Button, Paper, Fab, useTheme, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";

const ReportsForm = () => {
  const theme = useTheme();
  const [isSidebar, setIsSidebar] = React.useState(true);
  const navigate = useNavigate();

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        container
        spacing={2}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Sidebar isSidebar={isSidebar} />
        </Box>
        <Box
          sx={{
            flexGrow: 999,
          }}
        >
          <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">         
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <b>Reports</b>
            </Typography>
          </Breadcrumbs>
          <Box
            sx={{
              paddingX: "20px",
              marginX: "20px",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <h3 style={{ margin: "0px", padding: "0px" }}>SALES</h3>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/itemSalesReport")}>Item Sales Detail Report </Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/itemSalesSummativeReport")}>Item Sales Summative Report </Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}> <li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/customerReturnReport")}>Customer Refunds Report</Link></li></ul>
                </Paper>
              </Grid>
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <h2 style={{ margin: "0px", padding: "0px" }}>INVENTORY</h2>

                  <ul style={{ margin: "0px", padding: "0px" }}> <li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/itemTrends")}>Item Trends</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/batchwiseItemStockReport")}>Batchwise Stock Report</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/deadStockReport")}>Dead Stock Report</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/stockAdjustmentReport")}>Stock Adjustment Report</Link></li></ul>
                </Paper>
              </Grid>
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <h2 style={{ margin: "0px", padding: "0px" }}>PURCHASE</h2>

                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/itemPurchaseDetailReport")}>Item Purchase Detail Report</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/itemPurchaseSummativeReport")}>Item Purchase Summative Report</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}> <li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/supplierReturnReport")}>Supplier Returns Detail Report</Link></li></ul>
                  {/* <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/supplierPurchase")}>Supplier Purchase Report</Link></li></ul> */}
                </Paper>
              </Grid>
            </Grid>

            <hr />

            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <h3 style={{ margin: "0px", padding: "0px" }}>ACCOUNTING</h3>
                  <ul style={{ margin: "0px", padding: "0px" }}> <li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/profit&Loss")}>Profit & Loss</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/balanceSheet")}>Balanace Sheet</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/")}>Cost of Goods</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/recievables")}>Recievables</Link></li></ul>
                  <ul style={{ margin: "0px", padding: "0px" }}><li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("/payables")}>Payables</Link></li></ul>
                </Paper>
              </Grid>
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                
              </Grid>
              <Grid xs sx={{ mx: "8px", my: "15px" }}>
                {/* <Paper
                  sx={{ width: "100%", padding: "40px", margin: "40px" }}
                  elevation={3}
                >
                  <h2 style={{ margin: "0px", padding: "0px" }}>PURCHASE</h2>

                  <ul style={{ margin: "0px", padding: "0px" }}> <li><Link underline="hover" sx={{ display: "flex", alignItems: "center" }} color="inherit" onClick={() => navigate("")}></Link></li></ul>
                </Paper> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsForm;
